import styled from "styled-components";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FaIcon } from "./FaIcon";
import { space, SpaceProps } from "styled-system";

interface Props extends SpaceProps {active?: boolean}

export const IconHeart = styled(FaIcon).attrs({
  icon: faHeart
})<Props>`
  ${({ active }) => `
    color: ${active ? '#2BA76A' : '#c0c5cd'}
  `}
  ${space}
`;
