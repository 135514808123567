import { Box } from "components/Box";
import { Button } from "components/Button";
import { Checkbox } from "components/Checkbox";
import { Flex } from "components/Flex";
import { InputGroup } from "components/InputGroup";
import { IOption, Tags } from "components/Tags/Tags";
import { Typography } from "components/Typography";
import { RegisterFormStatus } from "containers/RegisterFormStatus";
import { useRegisterFormDispatchContext, useRegisterFormStateContext } from "contexts/RegisterFormContext";
import { LoginRegisterLayout } from "layouts/LoginRegisterLayout";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { fetchTaxon } from "services/taxon";
import { Controller, useForm } from "react-hook-form";
import { fetchSubscriptions, updateUser } from "services/user";
import { toast } from "react-toastify";
import { format } from "date-fns";

export interface ITaxonsForm {
  ingredients: ITaxon[];
  allergens: ITaxon[];
  kitchens: ITaxon[];
  diets: ITaxon[];
}

export interface ITaxon {
  value: string;
  label: string;
  checked: boolean;
}

export const getExclusionsPrepared = (tagsData: ITaxon[]) =>
  tagsData.map(({ value, label }) => ({ value, label }));

export const RegisterPreferencesView: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [diets, setDiets] = useState<ITaxon[]>([]);
  const [kitchens, setKitchens] = useState<ITaxon[]>([]);
  const [ingredients, setIngredients] = useState<ITaxon[]>([]);
  // const [allergens, setAllergens] = useState<ITaxon[]>([]);
  const history = useHistory();
  const { setActiveStep, setFormsData } = useRegisterFormDispatchContext();
  const { formsData } = useRegisterFormStateContext();

  const { register, handleSubmit, watch, control } = useForm<ITaxonsForm>({
    defaultValues: {
      ingredients: formsData?.ingredients ?? [],
      // allergens: formsData?.allergens ?? [],
      kitchens: formsData?.kitchens ?? [],
      diets: formsData?.diets ?? []
    }
  });
  const watcher = watch();

  const buildArrayRequestPayload = (data: ITaxonsForm) => {
    const dietsIds = diets.map((el, index) => {
      return { ...el, checked: data.diets[index].checked };
    }).filter(el => el.checked).map(el => parseInt(el.value));

    const ingredientsIds = data.ingredients.map(ingredient => parseInt(ingredient.value));
    // const allergensIds = data.allergens.map(allergen => parseInt(allergen.value));

    const kitchensIds = kitchens.map((el, index) => {
      return { ...el, checked: data.kitchens[index].checked };
    }).filter(el => el.checked).map(el => parseInt(el.value));

    const ids = [...dietsIds, ...ingredientsIds, ...kitchensIds];
    // const ids = [...dietsIds, ...ingredientsIds, ...allergensIds, ...kitchensIds];
    return ids.map((id: number) => ({ id }));
  };

  const buildArrayFormStateContext = (data: ITaxonsForm) => {
    const selectedDietsIndexes = data.diets.map((el, index) => el.checked ? index : null).filter(el => Number.isInteger(el));
    const selectedDiets = diets.map((el, index) => selectedDietsIndexes.includes(index) ? {
      ...el,
      checked: true
    } : el);
    const selectedKitchensIndexes = data.kitchens.map((el, index) => el.checked ? index : null).filter(el => Number.isInteger(el));
    const selectedKitchens = kitchens.map((el, index) => selectedKitchensIndexes.includes(index) ? {
      ...el,
      checked: true
    } : el);
    return { ...data, diets: selectedDiets, kitchens: selectedKitchens };
  };

  const onSubmit = handleSubmit(async (data) => {
    const payload = buildArrayRequestPayload(data);
    const contextData = buildArrayFormStateContext(data);

    try {
      setIsLoading(true)
      setFormsData(contextData);
      await updateUser({ ...formsData }, payload);
      history.push("/register/subscription");
    } catch (err) {
      toast.error("Coś poszło nie tak.");
    } finally {
      setIsLoading(false)
    }
  });

  useEffect(() => {
    setActiveStep(2);

    fetchTaxon("diets").then((response) => {
      const diets = response.included.map((taxon) => ({
        value: taxon.id,
        label: taxon.attributes.name,
        checked: false
      }));
      setDiets(diets);
    });

    fetchTaxon("ingredients").then((response) => {
      const ingredients = response.included.map((taxon) => ({
        value: taxon.id,
        label: taxon.attributes.name,
        checked: false
      }));
      setIngredients(ingredients);
    });

    // fetchTaxon("allergens").then((response) => {
    //   const allergens = response.included.map((taxon) => ({
    //     value: taxon.id,
    //     label: taxon.attributes.name,
    //     checked: false
    //   }));
    //   setAllergens(allergens);
    // });

    fetchTaxon("kitchens").then((response) => {
      const kitchens = response.included.map((taxon) => ({
        value: taxon.id,
        label: taxon.attributes.name,
        checked: false
      }));
      setKitchens(kitchens);
    });

    return () => {
      setDiets([]);
      setIngredients([]);
      // setAllergens([]);
      setKitchens([]);
    };
  }, []);

  return (
    <LoginRegisterLayout
      left={
        <>
          <form onSubmit={onSubmit}>
            <Typography variant="h1" mb={6}>
              Preferencje żywieniowe
            </Typography>
            <Box mb={6}>
              <InputGroup label="Dieta">
                {diets.map((diet, index) => (
                  <Checkbox
                    capitalizeLabel columnHalfSpace
                    key={diet.value}
                    label={diet.label}
                    {...register(`diets.${index}.checked` as const)}
                  />
                ))}
              </InputGroup>
            </Box>

            <Box mb={6}>
              <>
                <Typography variant="label" mb={2} ml={3}>
                  Wyklucz składniki
                </Typography>
                <Controller
                  control={control}
                  name="ingredients"
                  render={({ field: { onChange } }) => (
                    <Tags
                      options={getExclusionsPrepared(ingredients)}
                      selectedOptions={getExclusionsPrepared(watcher.ingredients)}
                      onChange={(selectedTags: IOption[]) => {
                        onChange(selectedTags.filter(tag => {
                          return ingredients.find(ingredient => ingredient.value === tag.value);
                        }));
                      }}
                    />
                  )}
                />
              </>

              {/*{allergens.length > 0 && (*/}
              {/*  <>*/}
              {/*    <Typography variant="label" mb={2} ml={3}>*/}
              {/*      Wykluczenia alergeny*/}
              {/*    </Typography>*/}
              {/*    <Controller*/}
              {/*      control={control}*/}
              {/*      name="allergens"*/}
              {/*      render={({ field: { onChange } }) => (*/}
              {/*        <Tags*/}
              {/*          options={getExclusionsPrepared(allergens)}*/}
              {/*          selectedOptions={getExclusionsPrepared(watcher.allergens)}*/}
              {/*          onChange={(selectedTags: IOption[]) => {*/}
              {/*            onChange(selectedTags.filter(tag => {*/}
              {/*              return allergens.find(allergen => allergen.value === tag.value);*/}
              {/*            }));*/}
              {/*          }}*/}
              {/*        />*/}
              {/*      )}*/}
              {/*    />*/}
              {/*  </>*/}
              {/*)}*/}
            </Box>

            <Box mb={6}>
              <InputGroup label="Ulubiona kuchnia">
                {kitchens.map((kitchen, index) => (
                  <Checkbox
                    columnHalfSpace
                    capitalizeLabel
                    key={kitchen.value}
                    label={kitchen.label}
                    {...register(`kitchens.${index}.checked` as const)}
                  />
                ))}
              </InputGroup>
            </Box>

            <Flex justifyContent="space-between">
              <Button onClick={() => history.push("/register/diet")} disabled={isLoading}>
                Wróć
              </Button>
              <Button type="submit" variant="primary" disabled={isLoading}>
                Dalej
              </Button>
            </Flex>
          </form>
        </>
      }
      right={<RegisterFormStatus />}
    />
  );
};
