import React from "react";
import styled from "styled-components";

import {Typography} from "components/Typography";
import {Flex} from "components/Flex";
import {system} from "styled-system";
import DatePicker, {DatePickerProps} from "react-date-picker";
import {includesDate} from "../../utilities/date";

type TileDisabledType = { activeStartDate: Date, date: Date, view: 'month' | 'year' | 'decade' | 'century' }

interface IDatePicker {
  label?: string;
  placeholderText?: string;
  disablePastDays?: boolean;
  disabledDays?: Date[];
  width?: string;
  transparent?: boolean;
  required?: boolean;
  day: Date | undefined;
  minDate?: Date | string;
  maxDate?: Date | string;

  onChange(day: Date | undefined | string): void
}

interface IStyledDayPicker {
  width?: string;
  transparent?: boolean;
}

const StyledDayPicker = styled(DatePicker)<IStyledDayPicker & DatePickerProps>`
  box-sizing: border-box;
  height: 40px;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  ${({theme, width}) => `
    padding-left: ${theme.space[3]}px;
    color: ${theme.colors.text.lighter};
    border-radius: ${theme.radii[2]}px;
    border: ${theme.inputBorder};
    .react-calendar__tile--active {
      background: ${theme.colors.primary}
    }
    .react-date-picker__inputGroup__input {
      margin-top: ${theme.space[1]}px;
      height: 80%;
    }
    .react-date-picker__button {
      & svg {
        width: 14px;
      }
    }
    ${width && `width:${width};`}
  `};

  .react-date-picker__wrapper {
    border: unset;
  }

  .react-date-picker__inputGroup__input:invalid {
    background: unset;
  }

  .react-calendar__tile--now {
    background: #e2e2e2;
  }

  ${system({
    borderColor: {
      property: "borderColor",
      scale: "colors"
    }
  })}
`;

const StyledLabel = styled(Typography).attrs({variant: "label"})`
  ${({theme}) => `
    margin-left: ${theme.space[3]}px;
  `};
`;

export const DatePickerInput: React.FC<IDatePicker> = ({
                                                         maxDate,
                                                         minDate,
                                                         width,
                                                         transparent,
                                                         required,
                                                         disabledDays,
                                                         label,
                                                         placeholderText,
                                                         day,
                                                         disablePastDays,
                                                         ...props
                                                       }) => {
  const minDateCalculated = minDate ? (minDate instanceof Date ? minDate : new Date(minDate)) : (disablePastDays ? new Date() : undefined);
  const handleDisabledDays = ({activeStartDate, date, view}: TileDisabledType) => disabledDays && includesDate(disabledDays, date) || false

  return (
    <Flex flexDirection="column">
      {label &&
        <StyledLabel variant="label">
          {label}
        </StyledLabel>
      }
      <StyledDayPicker
        width={width}
        format="dd.MM.yyyy"
        onChange={props.onChange}
        value={day}
        maxDate={maxDate ? new Date(maxDate) : undefined}
        minDate={minDateCalculated}
        locale="pl-PL"
        tileDisabled={disabledDays && disabledDays.length > 0 ? handleDisabledDays : undefined}
        dayPlaceholder="dd"
        monthPlaceholder="mm"
        yearPlaceholder="yyyy"
        required={required}
      />
    </Flex>
  );
};
