import {fetchAPI} from "utilities/fetchAPI";
import {formatDate} from "utilities/date";
import {PaginationType} from "../types/common";

export type UserAddress = {
  id: string
  type: string
  attributes: {
    firstname: string
    lastname: string
    address1: string
    address2: string
    city: string
    zipcode: string
    phone: string
    state_name: string
    state_code: string
    country_name: string
    country_iso3: string
    company: string
  }
}

export type UserWallet = {
  id: string
  type: string
  attributes: {
    amount: number
    recharge_date: string
    user_id: number
  }
}

export interface IFetchUser {
  data: {
    id: string;
    type: string;
    attributes: {
      email: string;
      is_company: boolean;
      gender: string;
      birthday: string;
      weight: number;
      target_weight: number;
      height: number;
      target_days: number;
      energy: number;
      physical_activity: number;
      i_eat_everything: boolean;

      max_carbohydrates: number;
      max_fats: number;
      max_proteins: number;
      preferred_energy: number;

      subscription_days_left: number;

      taxons?: {
        id: number;
      }[];
      store_credits: number;
      completed_orders: number;
      roles?: string[]
    };
    relationships: {
      taxons: {
        data: {
          id: string,
        }[]
      }
      subscriptions: {
        data: {
          id: string,
        }[]
      }
      meal_types: {
        data: {
          id: string,
        }[]
      }
      default_billing_address: {
        data: {
          id: string;
          type: string;
        };
      };
      default_shipping_address: {
        data: {
          id: string;
          type: string;
        };
      };
      wallet: {
        data: {
          id: number,
          type: string
        }
      }
    };
  };
  included: (UserAddress | UserWallet)[]
}

export interface IUpdateUser {
  email?: string
  is_company?: boolean
  gender?: string
  birthday?: string
  weight?: number
  target_weight?: number
  height?: number
  target_days?: number
  energy?: number
  physical_activity?: number
  i_eat_everything?: boolean
  password?: string
  password_confirmation?: string
}

export interface IPostSubscription {
  subscription: {
    price_per_day: number
    days: string[]
  }
}

export interface IFetchSubscriptions {
  data: {
    id: string,
    type: string,
    attributes: {
      price: null | string,
      price_per_day: number,
      cancelled?: boolean,
      active: boolean,
      user_id: number
    },
    relationships: {
      subscription_days: {
        data: {
          id: string,
          type: string
        }[]
      },
      payments: {
        data: {
          id: string
          type: string
        }[]
      }
    }
  }[],
  included: {
    id: string,
    type: string,
    attributes: Partial<{
      date: string,
      subscription_id: number
      //
      amount: string
      order_id: string
      payable_id: number
      payable_type: string
      status: string
      url: string
    }>
  }[],
  meta: {
    count: number,
    total_count: number,
    total_pages: number
  },
}

export interface IFetchSubscription {
  subscription: {
    data: {
      id: string
      type: string
      attributes: {
        price: number
        price_per_day: number
        active: boolean
        user_id: string
      }
      relationships: {
        subscription_days: {
          data: {
            id: string
            type: string
          }[]
        }
      }
    }[]
  }
}

export interface IFetchSubscriptionDays {
  data: {
    attributes: {
      date: string, subscription_id: boolean, rated: boolean
    }
    id: string
    type: "subscription_day"
  }[]
  meta: PaginationType
}

export interface ICancelSubscription {
  data: {
    id: string
    type: string
    attributes: {
      price: number
      price_per_day: number
      active: boolean
      user_id: string
    }
  }
}

interface IUpdateTaxon {
  id: number
}

interface IUpdateMealType {
  name: string
}

interface IMoveSubscription {
  subscription: {
    id: number
    old_date: string
    new_date: string
  }
}

interface IMoveSubscriptionResponse {
  subscription_day: {
    data: {
      id: string
      type: string,
      attributes: {
        date: string,
        subscription_id: number
      }
    }
  }
}

export interface IFetchWalletEvents {
  data: {
    id: string
    type: string
    attributes: {
      amount: number
      created_at: string
      name: string
      key: string
    }
  }[]
}

export const fetchUser = async () =>
  await fetchAPI<IFetchUser>(
    `${process.env.REACT_APP_API_URL}api/v2/storefront/account`);

export const updateUser = (user?: IUpdateUser, taxons?: IUpdateTaxon[], mealTypes?: IUpdateMealType[]) => {
  const payload: {
    user?: IUpdateUser
    taxons?: IUpdateTaxon[]
    meal_types?: IUpdateMealType[]
  } = {}
  payload.user = user ? user : {}
  if (taxons) payload.taxons = taxons
  if (mealTypes) payload.meal_types = mealTypes

  return fetchAPI<IFetchUser>(`${process.env.REACT_APP_API_URL}api/v2/storefront/account`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    body: JSON.stringify({...payload})
  });
};

export const postSubscription = (data: IPostSubscription) =>
  fetchAPI<{ data: { id: string } }>(`${process.env.REACT_APP_API_URL}api/v2/storefront/subscriptions`, {
    method: "POST",
    body: JSON.stringify(data)
  });

export const fetchSubscriptions = async (page?: string) =>
  fetchAPI<IFetchSubscriptions>(`${process.env.REACT_APP_API_URL}api/v2/storefront/subscriptions${page ? '?page=' + page : ''}`, {
    method: "GET"
  });

export const cancelSubscription = async (id: string | number) =>
  fetchAPI<ICancelSubscription>(`${process.env.REACT_APP_API_URL}api/v2/storefront/subscriptions/${id}/cancel`, {
    method: "PATCH"
  });

export const fetchSubscription = async (date: Date) =>
  fetchAPI<IFetchSubscription>(`${process.env.REACT_APP_API_URL}api/v2/storefront/subscriptions/${formatDate(date)}`, {
    method: "GET"
  });

export const fetchSubscriptionDays = async (dayFrom: string, dayTo: string, rated: boolean = false) =>
  fetchAPI<IFetchSubscriptionDays>(`${process.env.REACT_APP_API_URL}api/v2/subscription_days?day_from=${dayFrom}&day_to=${dayTo}&rated=${rated}`, {
    method: "GET"
  });

export const moveSubscription = async (payload: IMoveSubscription) =>
  fetchAPI<IMoveSubscriptionResponse>(`${process.env.REACT_APP_API_URL}api/v2/subscription_days/move_day`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    body: JSON.stringify({...payload})
  });

export const fetchWalletEvents = async (page?: string) =>
  fetchAPI<IFetchWalletEvents>(`${process.env.REACT_APP_API_URL}api/v2/storefront/wallet_events${page ? '?page=' + page : ''}`, {
    method: "GET"
  });

export const resetPassword = async (email: string) =>
  fetchAPI(`${process.env.REACT_APP_API_URL}api/v2/storefront/password/recover`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({user: {email}}),
  });

export const setNewPassword = async (data: { password: string, password_confirmation: string }, token: string) =>
  fetchAPI(`${process.env.REACT_APP_API_URL}api/v2/storefront/password/change?id=${token}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
        user: data
      }
    ),
  });

export const confirmEmail = async (token: string) =>
  fetchAPI(`${process.env.REACT_APP_API_URL}api/v2/storefront/confirm?id=${token}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
