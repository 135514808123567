import { MainLayout } from "layouts/MainLayout";
import { Flex } from "components/Flex";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Col, Row } from "react-grid-system";
import { Card } from "../components/Card";
import { Link } from "../components/Link";
import { Typography } from "../components/Typography";
import { fetchVendor, IFetchVendor } from "../services/vendor";
import { toast } from "react-toastify";
import styled from "styled-components";
import { DishOffer } from "../components/DishOffer/DishOffer";
import { fetchProduct } from "../services/product";
import { IProduct } from "../types/product";
import { Box } from "../components/Box";
import { theme } from "../config/theme";

const StyledImage = styled.img`
  border-radius: 8px;
  max-height: 90%;
  width: 100%;
  object-fit: contain;
  ${({ theme }) => `
    border-radius: ${theme.radii[2]}px;
  `}
`;

export const VendorView = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [vendor, setVendor] = useState<IFetchVendor | null>(null);
  const [products, setProducts] = useState<IProduct[]>([]);

  const retrieveVendor = async () => {
    try {
      const response = await fetchVendor(id);

      const promises = response.data.relationships.products.data.slice(0, 4)
        .map(async el => await fetchProduct(Number(el.id)));
      const productsData = await Promise.all(promises);

      setVendor(response);
      setProducts(productsData);
    } catch (e) {
      toast.error("Nie udało się pobrać vendora.");
    }
  };

  useEffect(() => {
    retrieveVendor();
  }, []);

  return (
    <div>
      {vendor &&
      <MainLayout>
        <Row>
          <Col xs={4}>
            <Card p={10}>
              {/* todo image */}
              {vendor.included.length > 0 &&
              <StyledImage src={"https://boofet-api.herokuapp.com/" + vendor.included[0].attributes.styles[7].url} />
              }
            </Card>
          </Col>
          <Col>
            <Card px={10} py={6}>
              <Typography variant="h3" color={theme.colors.text.lighter}>Katering dietetyczny</Typography>
              <Typography variant="h1">{vendor.data.attributes.name}</Typography>
              <Box mt={4}>
                <Typography variant="common">{vendor.data.attributes.street} {vendor.data.attributes.street_number}, {vendor.data.attributes.flat_number}</Typography>
                <Typography variant="common">{vendor.data.attributes.zip_code} {vendor.data.attributes.city}</Typography>
                <Typography variant="common">tel. {vendor.data.attributes.phone}</Typography>
                <Typography variant="common">{vendor.data.attributes.contact_description}</Typography>

                <Typography mt={4} variant="common">{vendor.data.attributes.about_us}</Typography>
              </Box>
            </Card>
          </Col>
        </Row>
        <Flex mt={6} justifyContent="space-between" alignItems="center">
          <Typography variant="h2">Dania od tego producenta</Typography>
          <Link to="#">Zobacz więcej</Link>
        </Flex>
        <Flex mt={3} justifyContent="space-between">
          {products.map(product => (
            // @ts-ignore
            <DishOffer vendor={vendor?.data} key={product.data.id} horizontal dish={product.data} />
          ))}
        </Flex>
      </MainLayout>
      }
    </div>
  );
};
