import styled from "styled-components";
import { Typography } from "components/Typography";
import { MainLayout } from "layouts/MainLayout";
import { Col, Row } from "react-grid-system";
import { Box } from "components/Box";
import { Coin } from "components/Coin";
import { Checkbox } from "components/Checkbox";
import { Flex } from "components/Flex";
import { Card } from "components/Card";
import { Button } from "components/Button";
import { Table } from "components/Table/Table";
import { useSubscriptionDispatchContext, useSubscriptionStateContext } from "contexts/SubscriptionContext";
import { useEffect, useMemo, useState } from "react";
import { useGlobalStateContext, useGlobalStateDispatchContext } from "../contexts/GlobalStateContext";
import { theme } from "config/theme";
import { fetchPayment, postPayment } from "../services/payment";
import { fetchUser, postSubscription } from "../services/user";
import { findMinAndMaxDate, formatDate } from "../utilities/date";
import { toast } from "react-toastify";
import { useHistory } from "react-router";

interface IRow {
  rowData: string[]
}

interface ISummaryData {
  finalPrice: number;
  clientBudget: number;
}

export const ShoppingCartView: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const subscriptionContext = useSubscriptionStateContext();
  const subscriptionDispatchContext = useSubscriptionDispatchContext();
  const delimiters = ["25%", "25%", "25%", "25%"];
  const columnNames = ["Okres", "Cena", "Ilość", "Cena łącznie"];
  const [rows, setRows] = useState<IRow[]>([]);
  const [summaryData, setSummaryData] = useState<ISummaryData | null>(null);
  const { wallet } = useGlobalStateContext();
  const [payWithCoins, setPayWithCoins] = useState<boolean>(false);
  const { setProfile } = useGlobalStateDispatchContext();

  const priceToPay = useMemo(() => {
    if (!summaryData) return 0;
    return !payWithCoins
      ? summaryData.finalPrice
      : summaryData.finalPrice - summaryData.clientBudget < 0
        ? 0
        : summaryData.finalPrice - summaryData.clientBudget;

  }, [summaryData, payWithCoins]);

  useEffect(() => {
    if (undefined === subscriptionContext.subscription) {
      setRows([]);
      setSummaryData(null);
      return;
    }
    const [minDate, maxDate] = findMinAndMaxDate(subscriptionContext.subscription.days);

    setRows([{
      rowData: [
        `${formatDate(minDate)} - ${formatDate(maxDate)}`,
        `${subscriptionContext.subscription.price} zł`,
        `${subscriptionContext.subscription.days.length} szt`,
        `${subscriptionContext.subscription.price * subscriptionContext.subscription.days.length} zł`
      ]
    }]);
    setSummaryData({
      finalPrice: subscriptionContext.subscription.price * subscriptionContext.subscription.days.length,
      clientBudget: wallet?.attributes.amount ?? 0
    });
  }, [subscriptionContext.subscription]);

  const handlePay = () => {
    if (!subscriptionContext.subscription) return;

    if (subscriptionContext.subscription.paymentId) {
      fetchPayment(Number(subscriptionContext.subscription.paymentId))
        .then(res => {
          // @ts-ignore
          window.location.href = res.data.attributes.url;
        })
        .catch(e => toast.error("Płatność nie powiodła się."));
      return;
    }

    setIsLoading(true);
    postPayment({
      payment: {
        payable_object_id: Number(subscriptionContext.subscription.id),
        payable_object_type: "subscription",
        continue_path: "/history-order/",
        pay_with_wallet: payWithCoins
      }
    })
      .then(res => {
        // todo in future (multiple subscriptions) delete by index
        subscriptionDispatchContext.setSubscription(undefined);
        if (res.data.attributes.url) {
          window.location.href = res.data.attributes.url;
          return;
        }

        fetchUser().then(response => {
          setProfile(response);
          history.push("/history-order");
        });
      })
      .catch(e => toast.error("Płatność nie powiodła się."))
      .finally(() => setIsLoading(false));
  };

  return (
    <MainLayout>
      <Box mb={4}>
        <Table
          deleteRows
          onDeleteRow={(index) => {
            // todo in future (multiple subscriptions) delete by index
            subscriptionDispatchContext.setSubscription(undefined);
          }}
          delimiters={delimiters}
          columnNames={columnNames}
          rows={rows}
        />
      </Box>
      <Row>
        {summaryData &&
        <Col xs={4} offset={{ xs: 8 }}>
          <Card p={2}>
            <Box px={8} pt={2}>
              <Flex mb={3} justifyContent="space-between">
                <Typography color="text.lighter">
                  Wartość zamówienia:
                </Typography>
                <Typography fontWeight="bold">
                  {summaryData.finalPrice} zł
                </Typography>
              </Flex>
              <Flex mb={3} justifyContent="space-between">
                <Typography color="text.lighter">
                  Zawartość skarbonki:
                </Typography>
                <Flex alignItems="center">
                  <Typography fontWeight="bold">
                    {summaryData.clientBudget ? summaryData.clientBudget.toFixed(2) : 0}
                  </Typography>
                  <Coin>C</Coin>
                </Flex>
              </Flex>
              <Flex mb={3} justifyContent="space-between">
                <Typography color="text.lighter">
                  <Checkbox disabled={summaryData.clientBudget < 1} label="Zapłać skarbonką"
                            onChange={() => setPayWithCoins(!payWithCoins)} />
                </Typography>
                {payWithCoins &&
                <Flex alignItems="center">
                  <Typography fontWeight="bold" color="orange">
                    {summaryData.clientBudget > summaryData.finalPrice
                      ? -summaryData.finalPrice
                      : -summaryData.clientBudget
                    }
                  </Typography>
                  <Coin>C</Coin>
                </Flex>
                }
              </Flex>
              <Box borderTop="1px solid" borderColor={theme.colors.neutral.medium}>
                <Flex pt={3} mb={5} justifyContent="space-between">
                  <Typography color="text.lighter">
                    Łącznie do zapłaty:
                  </Typography>
                  <Typography color="primary" fontWeight="bold">
                    {priceToPay}
                    zł
                  </Typography>
                </Flex>
              </Box>
            </Box>
            <Button onClick={handlePay} variant="primary" fullWidth disabled={isLoading}>
              Zapłać
            </Button>
          </Card>
        </Col>
        }
      </Row>
    </MainLayout>
  );
};
