// @ts-nocheck
import {Button} from "components/Button";
import {Box} from "components/Box";
import {Checkbox} from "components/Checkbox";
import {Flex} from "components/Flex";
import {Typography} from "components/Typography";
import {LoginRegisterLayout} from "layouts/LoginRegisterLayout";
import {InputRange} from "components/InputRange/InputRange";
import {Controller, useForm} from "react-hook-form";
import {useHistory} from "react-router";
import {Row, Col} from "react-grid-system";
import {RegisterFormStatus} from "containers/RegisterFormStatus";
import {formatDate} from "utilities/date";
// import { LineChart } from "components/LineChart/LineChart";
import {Graph} from "components/Graph/Graph";
import {toast} from "react-toastify";
import {
  useRegisterFormDispatchContext,
  useRegisterFormStateContext
} from "contexts/RegisterFormContext";
import {fetchSubscriptions, updateUser} from "services/user";
import React, {useEffect, useMemo, useState} from "react";
import {fetchMealTypes} from "services/product";
import {theme} from "../config/theme";
import {getDisabledDays} from "../utilities/calendar";
import {SpeechBubble} from "../components/SpeechBubble";
import {useCalculateDiet} from "../hooks/calculateDiet";
import {useFirstRender} from "../hooks/firstRender";
import {number} from "yup";

interface IMealType {
  name: string;
  value: string;
  checked: boolean;
}

export interface IRegisterDietForm {
  target_weight: number;
  target_days: number;
  energy: number;
  physical_activity: number;
  protein?: number;
  fats?: number;
  carbohydrates?: number;
  meal_types: IMealType[];
}

export interface IRangeFields {
  name: string;
  label: string;
  min: number;
  max: number;
  step?: number;
  disabled?: boolean;
  addSpaces?: boolean;
}

export const rangeFileds = (energy?: [number, number]) => {
  return [
    {
      name: "target_days",
      label: "Ilość dni",
      min: 1,
      max: 300
    },
    {
      name: "physical_activity",
      label: "Śr. ilość kroków dziennie",
      min: 1600,
      max: 20000,
      step: 200,
      speechBubble: true,
      addSpaces: true
    },
    {
      name: "energy",
      label: "Energia (kcal)",
      min: energy[0] || 300,
      max: energy[1] || 5000,
      step: 50
    },
    {
      name: "protein",
      label: "Białka (15-25%)",
      min: 0,
      max: 100,
      disabled: true
    },
    {
      name: "carbohydrates",
      label: "Węglowodany (50-60%)",
      min: 0,
      max: 100,
      disabled: true
    },
    {
      name: "fats",
      label: "Tłuszcze (25-35%)",
      min: 0,
      max: 100,
      disabled: true
    }
  ];
}

export const RegisterDietView: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [mealTypes, setMealTypes] = useState<IMealType[]>([]);
  // calculated nex 2-3 days from today
  const disabledDays: Date[] = getDisabledDays();
  const lastDisabledDay = new Date(new Date().setDate(disabledDays[disabledDays.length - 1].getDate() + 1));
  const today = new Date();
  const [lastDay, setLastDay] = useState<number>();
  const history = useHistory();
  const {setActiveStep, setFormsData} = useRegisterFormDispatchContext();
  const {formsData} = useRegisterFormStateContext();
  const mealTypesCheckedCount = formsData?.meal_types?.filter(el => !!el.checked).length || 1

  const firstRender = useFirstRender();
  const [kcalRange, setKcalRange] = useState<[number, number]>([
    mealTypesCheckedCount * 300, mealTypesCheckedCount * 700
  ])

  const {register, handleSubmit, control, watch, setValue} = useForm<IRegisterDietForm>(
    {
      defaultValues: {
        target_weight: formsData?.target_weight ?? 1,
        target_days: formsData?.target_days ?? 1,
        energy: formsData?.energy ?? 900,
        protein: 20,
        fats: 25,
        carbohydrates: 55,
        meal_types: formsData?.meal_types ?? mealTypes,
        physical_activity: formsData?.physical_activity ?? 10000
      }
    }
  );
  const watcher = watch();

  const calculatedWeight = useCalculateDiet(
    formsData.height,
    formsData.weight,
    formsData.target_weight,
    watcher.target_days,
    watcher.energy,
    watcher.physical_activity,
    formsData.gender,
    formsData.birthday,
    firstRender,
    formsData.energy
  )

  useEffect(() => {
    if ("object" === typeof calculatedWeight) {
      setValue("target_days", calculatedWeight.prefDays);
      setValue("energy", calculatedWeight.CPM);
    }
    if ("number" === typeof calculatedWeight) {
      setValue("target_weight", calculatedWeight);
    }
  }, [calculatedWeight]);

  useEffect(() => {
    if (mealTypes.length > 1) {
      setValue("meal_types", formsData.meal_types
        ? formsData.meal_types
        : mealTypes.map(el => {
          return {...el, checked: !el.name.includes('posiłek')};
        })
      );
    }
    return () => setValue("meal_types", []);
  }, [mealTypes, formsData]);

  const getCheckedMealsNames = () =>
    watch("meal_types")
      .filter(({checked}: { checked: boolean }) => checked)
      .map(({name}: { name: string }) => ({name: name.toLowerCase()}));

  const onSubmit = (data: IRegisterDietForm) => {
    const parsedMeal = getCheckedMealsNames();
    const {protein, fats, carbohydrates, meal_types, ...restData} = data;
    const payload = {...restData};
    if (parsedMeal.length) {
      try {
        setIsLoading(true)
        setFormsData(data);
        updateUser(payload, undefined, parsedMeal);
        history.push("/register/preferences");
      } catch (err) {
        toast.error("Coś poszło nie tak.");
      } finally {
        setIsLoading(false)
      }
    } else {
      toast.error("Wybierz conajmniej jeden posiłek.");
    }
  };

  useEffect(() => {
    setActiveStep(1);

    fetchMealTypes().then(res => {
      const mealTypes = res.data.map(mealType => {
          return {
            value: mealType.id,
            name: mealType.attributes.name,
            checked: false
          };
        }
      );
      setMealTypes(mealTypes);
    });

    return () => setMealTypes([])
  }, [])

  useEffect(() => {
    setLastDay(lastDisabledDay.setDate(lastDisabledDay.getDate() + watcher?.target_days));
  }, [watcher?.target_days]);

  useEffect(() => {
    const count = watcher.meal_types.filter(el => !!el.checked).length
    if (count < 1) return
    const kcalMax = count * 700
    const kcalMin = count * 200
    if (kcalMin !== kcalRange[0]) setKcalRange([kcalMin, kcalMax])
  }, [watcher])

  const handleRangeValue = (name: string, value: number, min: number, max: number) => {
    if (value >= min && value <= max) return value
    return min
  }

  return (
    <LoginRegisterLayout
      left={
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h1" mb={6}>
            Wartości odżywcze/dieta
          </Typography>
          <Typography color={theme.colors.text.light} fontSize={12}>Prognoza Twoich postępów</Typography>
          {formsData.weight && watcher.target_weight && lastDisabledDay && lastDay && today &&
            <Graph
              weight={formsData?.weight}
              target_weight={watcher.target_weight}
              start_date={formatDate(lastDisabledDay, ".")}
              end_date={lastDay ? formatDate(new Date(lastDay), ".") : formatDate(today, ".")}
            />
          }
          {/*<LineChart dataset={dataset} />*/}

          {/*<Box mb={6}>*/}
          {/*  <Typography variant="label">*/}
          {/*    Ilość dni do osiągnięcia celu*/}
          {/*  </Typography>*/}
          {/*  <InputRange min={30} max={200} defaultValue="98" />*/}
          {/*</Box>*/}

          {/*<Box mb={6}>*/}
          {/*  <Typography variant="label">Energia (kcal)</Typography>*/}
          {/*  <InputRange min={1200} max={2500} defaultValue="1800" />*/}
          {/*</Box>*/}

          {rangeFileds(kcalRange).map((field, index) => (
            <Box mb={6} key={field.name}>
              {index === 3 &&
                <Typography my={3} variant="label">Makroskładnik</Typography>
              }
              {field.speechBubble ?
                <Flex justifyContent="flex-start" alignItems="flex-end">
                  <Typography ml={3} variant="label">{field.label}</Typography>
                  <Box mb={3}>
                    <SpeechBubble html={
                      "<p>Podaj wartość uśrednioną. Jeśli nie posiadasz krokomierza to użyj poniższej ściągawki</p>" +
                      "<p>1.    Brak aktywności fizycznej (3000 - 6000 kroków)</p>" +
                      "<p>2.    Praca siedząca z uwzględnieniem 1-2 treningów w tygodniu (6000 – 10 000 kroków)</p>" +
                      "<p>3.    Praca siedząca z uwzględnieniem 3-4 treningów w tygodniu (10 000 – 14 000 kroków)</p>" +
                      "<p>4.    Praca fizyczna z uwzględnieniem 3-4 treningów w tygodniu (14 000 – 18 000 kroków)</p>" +
                      "<p>5.    Osoby zawodowo uprawiających sport (powyżej 18 000 kroków)</p>"
                    }>i</SpeechBubble>
                  </Box>
                </Flex>
                :
                <Typography ml={3} variant="label">{field.label}</Typography>
              }
              <Controller
                control={control}
                name={field.name as any}
                render={({field: {value, onChange}}) => (
                  <InputRange
                    addSpaces={field.addSpaces}
                    disabled={field.disabled}
                    min={field.min}
                    max={field.max}
                    value={handleRangeValue(field.name, value, field.min, field.max)}
                    step={field.step ? field.step : 1}
                    onChange={onChange}
                  />
                )}
              />
            </Box>
          ))}

          <Row>
            <Col sm={12}>
              <Typography m={3} variant="label">Posiłki w ciągu dnia</Typography>
              {mealTypes && mealTypes.map((el, index) => (
                <Box key={el.value} mb={4}>
                  <Checkbox
                    capitalizeLabel
                    label={el.name}
                    {...register(`meal_types.${index}.checked` as const)}
                  />
                </Box>
              ))}
            </Col>
          </Row>

          <Flex justifyContent="space-between">
            <Button onClick={() => history.push("/register/health-interview")}>
              Wróć
            </Button>
            <Button variant="primary" disabled={isLoading}>Dalej</Button>
          </Flex>
        </form>
      }
      right={<RegisterFormStatus/>}
    />
  );
};
