import * as yup from "yup";

export const passwordRegExp = /^(?=.*[A-Z])(?=.*[!@#\$%\^&\*])/
export const phoneRegExp = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{5})$/

export const isValidNip = (nip?: string) => {
  if (!nip) return false
  nip = nip.replace(/[\ \-]/gi, '');

  let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  let sum = 0;
  let controlNumber = parseInt(nip.substring(9, 10));
  let weightCount = weight.length;
  for (let i = 0; i < weightCount; i++) {
    sum += (parseInt(nip.substr(i, 1)) * weight[i]);
  }

  return sum % 11 === controlNumber;
}

yup.setLocale({
  mixed: {
    required: "Pole jest wymagane.",
  },
  string: {
    min: "Pole musi zawierać min ${min} znaków.",
    max: "Pole musi zawierać max ${max} znaków.",
    email: "Nieprawidłowy format email.",
  }
});

export default yup;
