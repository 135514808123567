import styled from "styled-components";
import { space, SpaceProps, flexbox, FlexboxProps, layout, LayoutProps, size, SizeProps } from "styled-system";

interface Props extends SpaceProps, FlexboxProps, LayoutProps, SizeProps {
}

export const Flex = styled.div<Props>`
  ${space}
  ${layout}
  ${flexbox}
  ${size}
  display: flex;
`;
