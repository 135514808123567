import { fetchAPI } from "utilities/fetchAPI";
import { number } from "yup";

export interface IFetchAddresses {
  data: {
    id: string
    type: string
    attributes: {
      additional_info: string
      address1: string
      address2: string
      address_type: string
      city: string
      company: string | null
      company_name: string | null
      country_iso: string
      country_iso3: string
      country_name: string
      door_code: 1
      firstname: string
      floor: string
      i_want_to_receive_an_invoice: boolean | null
      leave_at_the_door: boolean | null
      label: string | null
      lastname: string
      local: string
      nip: string | null
      number: string
      phone: string
      stairway: string
      state_code: null | string
      state_name: null | string
      street: string
      zipcode: string
    }
  }[]
}

export interface ICreateAddress {
  address_type: "bill_address" | "ship_address"
  address: {
    firstname: string
    lastname: string
    city: string
    phone: string
    zipcode: string
    company_name?: string
    nip?: string
    street?: string
    number?: string
    local?: string
    floor?: string
    stairway?: string
    door_code?: number
    additional_info?: string
    i_want_to_receive_an_invoice?: boolean
  }
}

export interface ICreateAddressResponse {
  data: {
    attributes: {
      deliverable?: boolean
      additional_info: string
      address1: string
      address2: string
      address_type: string
      city: string
      company: string
      company_name: string
      country_iso: string
      country_iso3: string
      country_name: string
      door_code: string | null | number
      firstname: string
      floor: string
      i_want_to_receive_an_invoice?: boolean
      label: string
      lastname: string
      local: string
      nip: string
      number: string
      phone: string
      stairway: string
      state_code: string
      state_name: string
      street: string
      zipcode: string
    }
    id: string
    type: string
  }
}

export const fetchAddresses = async () => {
  return await fetchAPI<IFetchAddresses>(`${process.env.REACT_APP_API_URL}api/v2/storefront/account/addresses`, {
    method: "GET"
  });
};

export const createAddress = async (data: ICreateAddress) => {
  return await fetchAPI<ICreateAddressResponse>(`${process.env.REACT_APP_API_URL}api/v2/storefront/account/addresses`, {
    method: "POST",
    body: JSON.stringify(data)
  });
};

export const updateAddress = async (id: number | string, address: ICreateAddress["address"]) => {
  return await fetchAPI<ICreateAddressResponse>(`${process.env.REACT_APP_API_URL}api/v2/storefront/account/addresses/${id}`, {
    method: "PATCH",
    body: JSON.stringify(address)
  });
};

export const deleteAddress = async (id: number | string) => {
  return await fetchAPI(`${process.env.REACT_APP_API_URL}api/v2/storefront/account/addresses/${id}`, {
    method: "DELETE"
  });
};
