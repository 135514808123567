import React, { useState } from "react";
import { Modal } from "components/Modal/Modal";
import styled from "styled-components";
import { Typography } from "components/Typography";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Box } from "components/Box";
import { TextField } from "../TextField";

export interface IPaymentModal {
  isOpen: boolean;
  amount: number;
  setAmount: (val: number) => void;
  onCancelClick: () => void;
  onSubmit: () => void;
}

const Wrapper = styled.div`
  ${({ theme }) => `
    padding: ${theme.space[8]}px ${theme.space[9]}px ${theme.space[6]}px ${theme.space[10]}px;
  `};
`;

export const PaymentModal: React.FC<IPaymentModal> = ({
                                                        isOpen,
                                                        onSubmit,
                                                        amount,
                                                        setAmount,
                                                        onCancelClick
                                                      }) => {

  return (
    <Modal isOpen={isOpen} onCancelClick={onCancelClick}>
      <Wrapper>
        <Typography variant="h3">Doładuj skarbonkę</Typography>
        {/*<Box my={3}>*/}
          {/*<Typography variant="caption">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut arcu ac*/}
          {/*  magna rutrum fringilla. Morbi egestas sollicitudin tortor dignissim suscipit.</Typography>*/}
        {/*</Box>*/}
        <Flex alignItems="center" justifyContent="flex-start" mt={4}>
          <TextField value={amount} onChange={(e) => {
            const val = +e.target.value
            if (!Number.isNaN(val)) setAmount(Math.abs(val))
          }} label="Kwota doładowania" />
          <Box mt={3} ml={3}>
            <Typography variant="h4">PLN</Typography>
          </Box>
        </Flex>
        <Flex justifyContent="flex-end" mt={4}>
          <Box mx={4}>
            <Button onClick={onCancelClick} variant="cancel">Anuluj</Button>
          </Box>
          <Button onClick={onSubmit} variant="primary">Doładuj skarbonkę</Button>
        </Flex>
      </Wrapper>
    </Modal>
  );
};
