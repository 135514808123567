import { useAuthDispatchContext } from "contexts/AuthContext";
import { useForm } from "react-hook-form";
import { AuthUserService } from "services/auth";
import { Box } from "components/Box";
import { Button } from "components/Button";
import { Typography } from "components/Typography";
import { TextField } from "components/TextField";
import { Flex } from "components/Flex";
import { Link } from "components/Link";
import { LoginRegisterLayout } from "layouts/LoginRegisterLayout";
import React, { useEffect } from "react";
import { useSettingsFormDispatchContext } from "contexts/SettingsFormContext";
import { checkRegistrationStatus } from "utilities/checkRegistrationStatus";
import { fetchUser } from "services/user";
import { useHistory } from "react-router-dom";

interface ILoginFormData {
  username: string;
  password: string;
}

export const LoginView = () => {
  const history = useHistory();
  const [error, setError] = React.useState(false);
  const { setFormsData } = useSettingsFormDispatchContext();
  const { setToken } = useAuthDispatchContext();
  const { register, handleSubmit, formState } = useForm<ILoginFormData>({
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    if (data.username.length && data.password.length) {
      try {
        const response = await AuthUserService(data);
        setToken(response.access_token, response.refresh_token);
        setFormsData({}, true)
        setError(false);
        fetchUser()
          .then(response => {
            const role = (response?.data.attributes?.roles && response?.data.attributes?.roles[0] === 'courier') ? "courier" : 'user'
            if (role === 'courier') {
              history.push('/delivery')
              return
            }
            if (!response.data.attributes.birthday) {
              setFormsData(undefined, true);
              history.push("/register/health-interview");
              return;
            }
            history.push(checkRegistrationStatus("/nutrition-plans", response.data.relationships.subscriptions.data.length))
          })
          .catch(e => {
            console.log(e);
            history.push("/");
          });
      } catch (error: unknown) {
        setError(true);
      }
    }
  });

  useEffect(() => {
    localStorage.removeItem("onboarding-form-value")
    localStorage.removeItem("settings-form-value")
    localStorage.removeItem("onboarding-alert")
    setToken(null, null)
  }, [])

  return (
    <LoginRegisterLayout
      left={
        <form onSubmit={onSubmit}>
          <Typography variant="h1" mb={6}>
            Logowanie
          </Typography>
          <Box mb={6}>
            <TextField
              label="Adres e-mail"
              type="text"
              placeholder="Email"
              error={formState.errors.username && "Adres email jest wymagany."}
              {...register("username", { required: true })}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Hasło"
              type="password"
              placeholder="Hasło"
              error={formState.errors.password && "Hasło jest wymagane."}
              {...register("password", { required: true })}
            />
            {error && (
              <Flex justifyContent="flex-end">
                <Typography variant="error">
                  Niepoprawny email lub hasło.
                </Typography>
              </Flex>
            )}
          </Box>
          <Flex justifyContent="flex-end" mb={3}>
            <span>
              Nie pamiętasz hasłą? <Link to="/reset-password">Resetuj hasło</Link>
            </span>
          </Flex>
          <Box mb={6}>
            <Button variant="primary" fullWidth>
              Zaloguj
            </Button>
          </Box>
          <Flex justifyContent="flex-end">
            <span>
              Nie masz konta? <Link to="/register">Zarejestruj się</Link>
            </span>
          </Flex>
        </form>
      }
      right="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
    pulvinar sapien id leo fringilla, nec posuere tortor viverra.
    Morbi laoreet ligula ut ex vestibulum, quis viverra nulla egestas.
    Orci varius natoque penatibus et magnis dis parturient montes,
    nascetur ridiculus mus. Fusce eu quam vehicula lacus pellentesque
    tincidunt ac id ex."
    />
  );
};
