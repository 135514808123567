import { Card } from "components/Card";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Row, Col } from "react-grid-system";
import styled from "styled-components";
import { rgba } from "utilities/rgba";
import logo from "images/logo.png";
import { Link } from "react-router-dom";
import { Stepper } from "components/Stepper/Stepper";

interface Props {
  left?: React.ReactNode;
  right?: React.ReactNode;
}

const Logo = styled.img`
  width: 145px;
`;

const Wrapper = styled.div`
  ${({ theme }) => `
    max-width: 995px;
    margin: 0 auto;
    padding: ${theme.space[6]}px 0;
  `}
`;

const Left = styled.div`
  max-width: 380px;
  margin: 0 auto;
`;
const StyledCol = styled(Col)`
  padding-left: 0 !important;
`;

const steps = [
  {
    active: false,
  },
  {
    active: true,
  },
  {
    disable: true,
  },
  {
    disable: true,
  },
  {
    disable: true,
  },
  {
    disable: true,
  },
];

export const LoginRegisterLayout: React.FC<Props> = ({ left, right }) => (
  <Wrapper>
    <Box mb={6}>
      <Link to="/">
        <Logo src={logo} />
      </Link>
    </Box>
    <Card p={4}>
      <Row>
        <Col xs={7}>
          <Box p={6}>
            <Left>{left}</Left>
          </Box>
        </Col>
        <StyledCol>
          <Box
            height="100%"
            bg={rgba("#f3f4f6", 0.38)}
            py={8}
            px={10}
            borderRadius={1}
          >
            {right}
          </Box>
        </StyledCol>
      </Row>
    </Card>
  </Wrapper>
);
