import React, { useContext, useState } from "react";
import { Props as StepperProps, Step } from "components/Stepper/Stepper";
import { IRegisterHealthInterviewFormData } from "views/RegisterHealthInterviewView";
import { IRegisterDietForm } from "views/RegisterDietView";
import { ITaxonsForm } from "views/RegisterPreferencesView";
import { IUpdateUser } from "services/user";
import { IRegisterSubscriptionForm } from "views/RegisterSubscriptionView";
import { IDeliveryForm } from "../types/delivery";

export type IOnboardingForms = IRegisterHealthInterviewFormData &
  IRegisterDietForm & ITaxonsForm & IDeliveryForm & IUpdateUser &
  { subscription: IRegisterSubscriptionForm, paymentId?: number };

export interface IRegisterFormContext {
  steps: StepperProps["steps"];
  formsData: Partial<IOnboardingForms>;
}

export interface IRegisterFormDispatchContext {
  setActiveStep: (step: number) => void;
  setFormsData: (value: any, clearData?: boolean) => void;
}

const RegisterFormContext = React.createContext<IRegisterFormContext | undefined>(undefined);

const RegisterFormDispatchContext = React.createContext<IRegisterFormDispatchContext | undefined>(undefined);

const onboardingFormValue = localStorage.getItem("onboarding-form-value");

export const RegisterFormContextProvider: React.FC = ({ children }) => {
  const [formsData, setFormData] = useState<Partial<IOnboardingForms>>(
    onboardingFormValue ? JSON.parse(onboardingFormValue) : undefined
  );
  const [steps, setSteps] = useState<Array<Step>>([
    {
      active: true
    },
    {
      disable: true
    },
    {
      disable: true
    },
    {
      disable: true
    },
    {
      disable: true
    },
    {
      disable: true
    }
  ]);

  const setActiveStep = (activeStep: number) => {
    // add to localStorage
    setSteps((prevState) =>
      prevState.map((step, index) => ({
        active: index === activeStep,
        disable: index > activeStep
      }))
    );
  };

  const setFormsData = (data: Partial<IOnboardingForms>, clearData?: boolean) => {
    const onboardingData = clearData ? {...data} : { ...formsData, ...data };
    localStorage.setItem(
      "onboarding-form-value",
      JSON.stringify(onboardingData)
    );
    setFormData((prevState) => {
      if (prevState && !clearData) return { ...prevState, ...data };
      return data;
    });
  };

  return (
    <RegisterFormContext.Provider value={{ steps, formsData }}>
      <RegisterFormDispatchContext.Provider
        value={{
          setActiveStep,
          setFormsData
        }}
      >
        {children}
      </RegisterFormDispatchContext.Provider>
    </RegisterFormContext.Provider>
  );
};

export const useRegisterFormStateContext = () => {
  const context = useContext(RegisterFormContext);
  if (!context) {
    throw new Error(
      "useRegisterFormContext must be inside a RegisterFormContextProvider with a value"
    );
  }
  return context;
};

export const useRegisterFormDispatchContext = () => {
  const context = useContext(RegisterFormDispatchContext);
  if (!context) {
    throw new Error(
      "useRegisterFormDispatchContext must be inside a RegisterFormStateDispatchProvider with a value"
    );
  }
  return context;
};
