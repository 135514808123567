import yup, { isValidNip, phoneRegExp } from "config/validation";

const validateInvoice = yup.object().when("i_want_to_receive_an_invoice", {
  is: true,
  then: yup.object().shape({
    company_name: yup.string().required(),
    nip: yup.string().min(10).max(10).test(
      "isNip",
      "Numer nip nie jest poprawny",
      (value) => isValidNip(value)
    ),
    street: yup.string().required(),
    number: yup.string().required(),
    zipcode: yup.string().min(6).max(6),
    city: yup.string().required(),
  }),
})

export const addressSchema = yup.object().shape({
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  phone: yup.string().required().matches(
    phoneRegExp,
    "Numer ma nieprawidłowy format."
  ),
  ship_address: yup.object().shape({
    street: yup.string().required(),
    number: yup.string().required(),
    local: yup.string(),
    zipcode: yup.string().required().min(6).max(6).matches(
      /^\d{2}[-](\d{3})?$/,
      "Kod pocztowy ma nieprawidłowy format."
    ),
    city: yup.string().required()
  }),
  i_want_to_receive_an_invoice: yup.boolean(),
  bill_address: validateInvoice
});
