import styled from "styled-components";

const Icon = styled.span`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.orange};
  color: #fff;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  font-weight: bold;
`;

export const Coin: React.FC = ({ children }) => <Icon>{children}</Icon>;
