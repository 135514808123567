import { useRouteMatch } from "react-router-dom";

const links: Array<{ to: string; label: string; isPublic?: boolean }> = [
  {
    to: "/nutrition-plans",
    label: "Menu",
  },
  {
    to: "/products",
    label: "Produkty",
  },
  {
    to: "/history-order",
    label: "Abonamenty",
  },
  // {
  //   to: "/subscriptions",
  //   label: "Historia",
  // },
];

const courierLinks: Array<{ to: string; label: string; isPublic?: boolean }> = [
  {
    to: "/delivery",
    label: "Dostawa",
  }
];

export const useNavbar = (role: string) => {
  const navbarLinks: Array<{
    to: string;
    match: boolean;
    label: string;
  }> = (role === 'user' ? links : courierLinks)
    .map(({ label, to }) => {
      const match = !!useRouteMatch({
        path: to
      });

      return {
        label,
        to,
        match,
      };
    });

  return { navbarLinks };
};
