import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

interface Props extends SpaceProps {}

export const DishTag = styled.div<Props>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  opacity: 0.5;
  ${({ theme }) => `
    border-radius: ${theme.radii[3]}px;
    background-color: ${theme.colors.neutral.light};
  `};
  ${space}
`;
