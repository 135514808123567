import {fetchAPI} from "utilities/fetchAPI";
import qs from "qs";
import {PaginationType} from "types/common";

export interface IFetchVendor {
  data: {
    id: string,
    type: string,
    attributes: {
      name: string
      about_us: string
      slug: string
      city: string
      zip_code: string
      street: string
      street_number: string
      flat_number: string
      phone: string
      contact_description: string
    },
    relationships: {
      image: {
        data: {
          id: string
          type: string
        }
      },
      products: {
        data: {
          id: string
          type: string
        }[]
      }
    },
  }
  included: {
    id: string
    type: string
    attributes: {
      position: number
      styles: {
        url: string
        width: number
        height: number
      }[]
    }
  }[]
}

export interface IFetchVendors {
  data: IFetchVendor[],
  meta: PaginationType
}

export interface IRoutePoint {
  "id": string | number
  "type": "route_point",
  "attributes": {
    "arrive_time": string
    "depart_time": string
    "sort_order": number,
    "address": {
      "data": {
        "id": string | number
        "type": "address",
        "attributes": {
          "firstname": string
          "lastname": string
          "address1": string
          "address2": string
          "city": string
          "zipcode": string
          "phone": string
          "state_name": null | string,
          "company": null | string,
          "country_name": string
          "country_iso3": string
          "country_iso": string
          "label": null | string,
          "state_code": null | string,
          "street": string
          "number": string
          "local": string
          "floor": string
          "stairway": string
          "door_code": string | number,
          "additional_info": string
          "i_want_to_receive_an_invoice": null | boolean,
          "company_name": null | string,
          "nip": null | string | number,
          "address_type": string
          "leave_at_the_door": boolean
        }
      }
    }
    simplified_address?: string
    common_shipment_state: string
  },
  "relationships": {
    "orders": {
      "data": {
        "id": string | number
        "type": "order"
      }[]
    }
  }
}

export interface IOrder {
  id: string | number,
  type: "order",
  attributes: {
    user_id: number,
    shipment_state: string
  },
  relationships: {
    scheduled_meal: {
      data: {
        id: string | number
        type: "scheduled_meal"
      }
    }
  }
}

export interface IScheduledMeal {
  id: string | number,
  type: "scheduled_meal",
  attributes: {
    product_id: number,
    meal_type_name: string,
    meal_type_id: number
  }
}

export interface IRoute {
  "id": string | number,
  "type": "route",
  "attributes": {
    "spree_user_id": null | string | number
  },
  "relationships": {
    "route_points": {
      "data": {
        "id": string | number
        "type": "route_point"
      }[]
    }
  }
}

export interface IDelivery {
  data: {
    "id": string
    "type": "route_request",
    "attributes": {
      "request_type": "receipt" | "delivery"
      "date": string
    },
    "relationships": {
      "routes": {
        "data": {
          "id": string | number
          "type": "route"
        }[]
      }
    }
  }[],
  included: (IRoute | IScheduledMeal | IOrder | IRoutePoint)[]
}

export const fetchVendors = async (filters?: Object) => {
  const query = filters ? `?${qs.stringify(filters)}` : "";
  return await fetchAPI<IFetchVendors>(
    `${process.env.REACT_APP_API_URL}api/v2/storefront/vendors${query}`);
};

export const fetchVendor = async (id: string) =>
  await fetchAPI<IFetchVendor>(
    `${process.env.REACT_APP_API_URL}api/v2/storefront/vendors/${id}`);

// delivery routes
export const fetchRoutes = async (date: string, type: string) => {
  return await fetchAPI<IDelivery>(
    `${process.env.REACT_APP_API_URL}api/v2/storefront/route_requests?date=${date}&request_type=${type}`
  );
}

export const changeRouteStatus = async (id: string | number, state: 'ready' | 'shipped' | 'pending') =>
  await fetchAPI(
    `${process.env.REACT_APP_API_URL}api/v2/storefront/route_requests/${id}/update_point_delivery`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({state}),
    });
