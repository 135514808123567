import React, { useState } from "react";
import { Flex } from "components/Flex";
import styled from "styled-components";
import { Link, useRouteMatch } from "react-router-dom";

export interface IMenuItem {
  label: string;
  path?: string;
  onClick?: () => any;
  current?: boolean;
}

interface IMenu {
  items: IMenuItem[]
}

interface IMenuItemProps {
  active?: boolean;
}

const MenuItem = styled.p<IMenuItemProps>`
  height: 40px;
  cursor: pointer;
  line-height: 40px;
  & > * {
    color: inherit; 
    text-decoration: unset;
  }
  ${({ theme, active }) => `
    margin: ${theme.space[1]}px 0px;
    padding-left: ${theme.space[4]}px;
    color: ${active ? theme.colors.primary : theme.colors.text.lighter};
    border-radius: ${theme.radii[4]}px;
    ${active && `background-color: rgba(192,197,205,0.25);`}
    ${active && `font-weight: 600;`}
  `}
`;

export const Menu: React.FC<IMenu> = ({ items }) => {
  return (
    <>
      <Flex flexDirection="column">
        {items.map((item, index) =>
          <MenuItem active={!!useRouteMatch(String(item.path))} key={index}>
            {item.path ? (
              <Link to={item.path}>{item.label}</Link>
            ) : (
              <span onClick={item.onClick}>{item.label}</span>
            )}
          </MenuItem>
        )}
      </Flex>
    </>
  );
};
