import React, {useEffect, useState} from "react";
import {MainLayout} from "layouts/MainLayout";
import {Col, Row} from "react-grid-system";
import {Tabs} from "components/Tabs/Tabs";
import {IRow, Table} from "components/Table/Table";
import {Person} from "components/Person/Person";
import {Button} from "components/Button";
import {Card} from "components/Card";
import {Typography} from "components/Typography";
import {Box} from "components/Box";
import {fetchSubscriptions, IFetchSubscriptions} from "services/user";
import {compareDates, findMinAndMaxDate, formatDate} from "utilities/date";
import {useHistory} from "react-router-dom";
import {useSubscriptionDispatchContext} from "contexts/SubscriptionContext";
import styled from "styled-components";
import {getPreparedSubscriptions} from "utilities/subscription";
import {fetchPayment, IFetchPayment} from "services/payment";
import {useGlobalStateContext} from "contexts/GlobalStateContext";
import {Calendar} from "components/Calendar/Calendar";
import {DataGrid, IDataGridProps} from "components/DataGrid";
import {fetchTaxonById} from "services/taxon";
import {fetchMealTypes, IFetchMealTypes} from "services/product";
import {fetchAddresses} from "../services/address";

export interface ISubscription {
  id: string
  price: string | null
  price_per_day: number
  user_id: number
  active: boolean
  payment?: IFetchPayment
  subscription_days: {
    id: string
    date: Date
  }[]
}

const ExpandedDataContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;

  ${Box} {
    width: 44%;
  }
`;

export const SubscriptionsView = () => {
  const history = useHistory();
  const [rows, setRows] = useState<IRow[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
  const [mealTypes, setMealTypes] = useState<IFetchMealTypes["data"]>([]);
  const [fullName, setFullName] = useState('');
  const [expandedData, setExpandedData] = useState<IDataGridProps>({
    data:
      [
        [
          {
            label: "Zapotrzebowanie energetyczne",
            value: "2200 kcal (B: 15%-20%, T: 30%-40%, W: 40%-50%)"
          }
        ],
        [
          {
            label: "Dieta",
            value: ""
          }
        ],
        [
          {
            label: "Posiłki w ciągu dnia",
            value: "Śniadanie (20%), II śniadanie (10%), Obiad (30%), " +
              "Podwieczorek (25%), Kolacja (15%)"
          }
        ],
        [
          {
            label: "Wykluczenia",
            value: ""
          }
        ],
        [
          {
            label: "Dzienny budżet",
            value: ""
          },
          {
            label: "Łączna wartość abonamentu",
            value: ""
          }
        ]
      ]
  });

  const subscriptionContext = useSubscriptionDispatchContext();
  const {wallet} = useGlobalStateContext();
  const {profile} = useGlobalStateContext();

  const delimiters = ["50%", "50%"];
  const columnNames = [null, null];

  const parseSubscriptions = (data: IFetchSubscriptions) => {
    if (!data || !data.hasOwnProperty("data")) return;
    const preparedSubscriptions = getPreparedSubscriptions(data);
    setSubscriptions(preparedSubscriptions);
  };

  const getExpandedData = (days: Date[], pricePerDay: number) => {
    return (
      <ExpandedDataContainer>
        <Box>
          <Calendar
            initDays={days}
            initialMonth={days[0]}
            preventDayClick
          />
        </Box>
        <Box>
          <DataGrid data={[...expandedData.data].map((el, index) => {
            return index === 4
              ? [
                {
                  label: "Dzienny budżet",
                  value: pricePerDay + " zł"
                },
                {
                  label: "Łączna wartość abonamentu",
                  value: (pricePerDay * days.length) + " zł"
                }
              ]
              : el
          })}/>
        </Box>
      </ExpandedDataContainer>
    );
  };

  const prepareRows = (subscriptions: ISubscription[]) => {
    const preparedRows = subscriptions
      .filter(subscription => subscription.price_per_day !== null)
      .map(subscription => {
        const dates = subscription.subscription_days.map(el => new Date(el.date));
        const [minDate, maxDate] = findMinAndMaxDate(dates)
        return {
          additionalData: {
            minDate, maxDate
          },
          rowData: [
            <Person key={1} fullName={fullName}
                    imgPath="#"/>,
            `${formatDate(minDate)} - ${formatDate(maxDate)} (${dates.length} ${dates.length === 1 ? " dzień" : " dni"})`
          ],
          expandedData: getExpandedData(dates, subscription.price_per_day)
        };
      }).filter(el => compareDates(el.additionalData.maxDate, new Date(), '<='));
    if (preparedRows.length > 0 && preparedRows[0] !== undefined) { // @ts-ignore
      setRows(preparedRows);
    }
  };

  const retrieveTaxons = async () => {
    if (!profile) return;
    const diets: any[] = [];
    const ingredients: any[] = [];
    for (const taxon of profile.data.relationships.taxons.data) {
      const response = await fetchTaxonById(taxon.id);
      if (response.data.attributes.permalink.includes("diet")) diets.push(response.data.attributes.name);
      if (response.data.attributes.permalink.includes("ingredient")) ingredients.push(response.data.attributes.name);
    }

    setExpandedData(prevState => {
      const copiedPrevState = [...prevState.data];
      // @ts-ignore
      copiedPrevState[0][0].value = profile.data.attributes.energy + " kcal (B: 15% - 25%, T: 25% - 35%, W: 50% - 60%)";
      // @ts-ignore
      copiedPrevState[1][0].value = diets.join(", ");
      // @ts-ignore
      copiedPrevState[3][0].value = ingredients.join(", ");
      return {data: copiedPrevState};
    });
  };

  useEffect(() => {
    if (fullName) prepareRows(subscriptions);
  }, [subscriptions, fullName]);

  useEffect(() => {
    if (mealTypes.length > 0 && profile && profile.data.relationships.taxons.data.length > 0)
      retrieveTaxons();
  }, [profile?.data.relationships.taxons.data, mealTypes]);

  useEffect(() => {
    setSubscriptions([]);
    fetchMealTypes().then(response => setMealTypes(response.data));
    fetchSubscriptions().then(response => parseSubscriptions(response));

    fetchAddresses()
      .then(response => {
        const shipAddress = response.data.find(el => el.attributes.address_type === "ship address");
        if (shipAddress) {
          setFullName(`${shipAddress.attributes.firstname} ${shipAddress.attributes.lastname}`)
        }
      })
      .catch(err => console.log(err));
  }, []);

  return (
    // <MainLayout>
    //   <Row>
    //     <Col xs={12}>
    <Table
      expandedRows
      key={rows.length}
      delimiters={delimiters}
      columnNames={columnNames}
      rows={rows}
    />
    // </Col>
    // </Row>
    // </MainLayout>
  );
};
