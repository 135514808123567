import React, { useContext, useState } from "react";
import { AuthRefreshToken } from "../services/auth";

export interface IAuthContext {
  token: string | null;
  refreshToken: string | null;
  expiresIn?: number;
}

export interface IAuthDispatchContext {
  setToken: (token: string | null, refreshToken: string | null, expiresIn?: number) => void;
}

const AuthContext = React.createContext<IAuthContext | undefined>(undefined);

const AuthDispatchContext = React.createContext<IAuthDispatchContext | undefined>(undefined);

export const AuthContextProvider: React.FC = ({ children }) => {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem("boofet-app-token") ?? null
  );
  const [refreshToken, setRefreshToken] = useState<string | null>(
    localStorage.getItem("boofet-app-refresh-token") ?? null
  );

  const handleSetToken = (newToken: string | null, refreshToken: string | null, expiresIn?: number) => {
    if (newToken === null || refreshToken === null) {
      localStorage.removeItem("boofet-app-token");
      localStorage.removeItem("boofet-app-refresh-token");
    } else {
      localStorage.setItem("boofet-app-token", newToken);
      localStorage.setItem("boofet-app-refresh-token", refreshToken);
    }
    setToken(token);
    setRefreshToken(refreshToken);

    setTimeout(() => {
      AuthRefreshToken()
        .then(response => {
          handleSetToken(response.access_token, response.refresh_token, response.expires_in);
        })
        .catch(err => console.log(err));
    }, (expiresIn ? (expiresIn - 200) : 7000) * 1000);
  };

  return (
    <AuthContext.Provider value={{ token, refreshToken }}>
      <AuthDispatchContext.Provider
        value={{ setToken: handleSetToken }}
      >
        {children}
      </AuthDispatchContext.Provider>
    </AuthContext.Provider>
  );
};

export const useAuthStateContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(
      "useAuthContext must be inside a AuthContextProvider with a value"
    );
  }
  return context;
};

export const useAuthDispatchContext = () => {
  const context = useContext(AuthDispatchContext);
  if (!context) {
    throw new Error(
      "useAuthDispatchContext must be inside a AuthStateDispatchProvider with a value"
    );
  }
  return context;
};
