import yup, { passwordRegExp } from "config/validation";

export const registerSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required().min(6, 'Pole musi zawierać min 6 znaków, w tym znak specjalny i dużą literę.').max(128)
    .matches(
      passwordRegExp,
      "Hasło ma zawierać znak specjalny i duża litera."
    ),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password")], "Hasła nie są takie same."),
  agreements: yup.bool().oneOf([true], "Zaakceptuj regulamin."),
  agreements_marketing: yup.bool()
});

export const updateProfileSchema = yup.object().shape({
  email: yup.string().email(), //.required(),
  password: yup.string().min(6, 'Pole musi zawierać min 6 znaków, w tym znak specjalny i dużą literę.').max(128),
  password_confirmation: yup.string()
    .oneOf([yup.ref("password")], "Hasła nie są takie same.")
});

export const changePasswordSchema = yup.object().shape({
  password: yup.string().required().min(6, 'Pole musi zawierać min 6 znaków, w tym znak specjalny i dużą literę.').max(128)
    .matches(
      passwordRegExp,
      "Hasło ma zawierać znak specjalny i duża litera."
    ),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password")], "Hasła nie są takie same."),
  reset_password_token: yup.string().required()
});
