import { differenceInCalendarYears } from "date-fns";

const BMRData = [
  [
    655.1, 9.563, 1.85, 4.676
  ],
  [
    66.5, 13.75, 5.003, 6.775
  ]
];

export const useCalculateDiet = (
  height: number,
  weight: number,
  target_weight: number,
  target_days: number,
  energy: number,
  physical_activity: number,
  gender: "male" | "female",
  birthday: string,
  isFirstRender: boolean,
  formsDataEnergy?: number
) => {
  const age = differenceInCalendarYears(new Date(), new Date(birthday));
  const BMRGender = gender === "male" ? BMRData[1] : BMRData[0];

  const calculateCMP = (weight: number) => {
    // Harris Benedict method
    const BMR = BMRGender[0] + (BMRGender[1] * weight) + (BMRGender[2] * height) - (BMRGender[3] * age);
    // CPM - preferredCalories for same weight (per day)
    return BMR + (physical_activity / 2000) * 100;
  }

  const calculateInitResult = () => {
    const CPM = calculateCMP(weight)
    const weightDiff = target_weight - weight
    if (weightDiff === 0) return { prefDays: 30, CPM }

    const kcalDiff = weightDiff / 0.000142
    const prefDays = Math.abs(kcalDiff / 500)

    return { prefDays, CPM: weightDiff < 0 ? CPM - 500 : CPM + 500 }
  }
  if (isFirstRender && formsDataEnergy) return
  if (isFirstRender) return calculateInitResult()

  let finalWeight = weight
  for (let i = 1; i <= target_days; i++) {
    const kgDiffPerDay = (energy - calculateCMP(weight)) * 0.00014
    finalWeight += kgDiffPerDay
  }

  return Math.round(finalWeight * 2) / 2;
};
