import {Typography} from "components/Typography";
import {MainLayout} from "layouts/MainLayout";
import {Col, Row} from "react-grid-system";
import {Box} from "components/Box";
import {Flex} from "components/Flex";
import {Person} from "components/Person/Person";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faPlus} from "@fortawesome/free-solid-svg-icons";
import {DishOffer} from "components/DishOffer/DishOffer";
import {DataGrid, IDataGridProps} from "components/DataGrid";
import {Card} from "components/Card";
import {Calendar} from "components/Calendar/Calendar";
import React, {useEffect, useMemo, useState} from "react";
import {compareDates, formatDate, formatDateConstructable, includesDate, monthNames} from "utilities/date";
import {DailyChart} from "components/DailyChart/DailyChart";
import {
  deleteScheduledMeal,
  fetchScheduledMeals,
  IFetchScheduledMeal, updateScheduledMeal
} from "services/nutritionPlans";
import {fetchProduct, fetchProducts, IProductsVendor, likeProduct, rateProduct, unlikeProduct} from "services/product";
import {IProduct} from "types/product";
import {useHistory} from "react-router";
import qs from "qs";
import {
  fetchSubscription, fetchSubscriptionDays,
  fetchSubscriptions,
  fetchUser,
  IFetchSubscription, IFetchSubscriptionDays,
  IFetchSubscriptions
} from "services/user";
import {getPreparedSubscriptions} from "utilities/subscription";
import {toast} from "react-toastify";
import {ChangeDayModal} from "components/ChangeDayModal/ChangeDayModal";
import {getDisabledDays} from "../utilities/calendar";
import {ConfirmDeleteModal} from "../components/ConfirmDeleteModal/ConfirmDeleteModal";
import {useGlobalStateContext, useGlobalStateDispatchContext} from "../contexts/GlobalStateContext";
import {SettingsModal} from "../components/SettingsModal";
import {Button} from "../components/Button";
import {fetchAddresses} from "../services/address";
import {fetchTaxons} from "../services/taxon";
import {ISubscription} from "./SubscriptionsView";
import {orderStatuses} from "./DeliveryView";
import {add, isEqual} from 'date-fns'
import {fetchVendors, IFetchVendor} from "../services/vendor";

export interface ISelectedDate {
  day: number;
  month: number;
  date: Date;
}

interface IScheduledMeal {
  id: number;
  attributes: {
    meal_type_name: string;
    meal_type_id: number,
    product_id: number
    shipment_state: string
  }
  product: undefined | IProduct | false;
}

interface IDailyValue {
  productId: number;
  calories: number;
  carbohydrates: number;
  proteins: number;
  fats: number;
  price: number;
}

interface IMaxDailyValue {
  maxCalories: number;
  maxCarbohydrates: number;
  maxProteins: number;
  maxFats: number;
}

const FaIcon = styled(FontAwesomeIcon)`
  align-self: center;
  cursor: pointer;
`;
const IconDown = styled(FaIcon).attrs({icon: faChevronDown})`
  color: #c0c5cd;
`;
const IconPlus = styled(FaIcon).attrs({icon: faPlus})`
  ${({theme}) => `
    color: ${theme.colors.primary};
  `}
`;

const StyledDate = styled(Typography)`
  color: #000000;
  font-family: "Open Sans";
  font-size: 24px;
  letter-spacing: 0;
  line-height: 33px;
`;

const StyledDay = styled(Typography).attrs({variant: "h2", color: "primary"})`
  & > span {
    font-weight: 400;
  }
`;

const data = [
  [
    {
      label: "Numer zamówienia:",
      value: "12345"
    },
    {
      label: "Status zamówienia",
      value: <Typography color="#2CCF36">Aktywny</Typography>
    }
  ],
  {
    label: "Wartości odżywcze",
    value: "2200 kcal (B: 15%-20%, T: 30%-40%, W: 40%-50%)"
  },
  {
    label: "Preferencje",
    value: "Wegańska, bez laktozy"
  },
  [
    {
      label: "Ilość posiłków:",
      value: "5"
    },
    {
      label: "Ilość dni:",
      value: "6"
    }
  ],
  {
    label: "Okres:",
    value: (
      <>
        06.02.2021 – 11.02.2021{" "}
        <Typography color="#EE5A24">2 dostawy do końca</Typography>
      </>
    )
  },
  [
    {
      label: "Dzienny budżet",
      value: "60,00 zł"
    },
    {
      label: "Wartość abonamentu",
      value: "318,00 zł"
    }
  ]
];

const mealTypes: { label: string; time: string }[] = [
  {
    label: "Śniadanie",
    time: "7:00"
  },
  {
    label: "II śniadanie",
    time: "10:00"
  },
  {
    label: "Obiad",
    time: "13:00"
  },
  {
    label: "Podwieczorek",
    time: "16:00"
  },
  {
    label: "Kolacja",
    time: "19:00"
  },
  {
    label: "Kolacja2",
    time: "20:00"
  },
  {
    label: "Kolacja3",
    time: "21:00"
  },
  {
    label: "Kolacja4",
    time: "21:00"
  },
  {
    label: "Kolacja5",
    time: "21:00"
  },
  {
    label: "Kolacja6",
    time: "21:00"
  },
  {
    label: "Kolacja7",
    time: "21:00"
  }
];

export const NutritionPlansView: React.FC = () => {
  const history = useHistory();
  const filtersFromUrl = qs.parse(location.search, {
    ignoreQueryPrefix: true
  });

  const [fullName, setFullName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  // for delete
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [mealTypeToReplace, setMealTypeToReplace] = useState<string>("");
  const [scheduledMealIdToDelete, setScheduledMealIdToDelete] = useState<number | null>(null);
  const [vendors, setVendors] = useState<IFetchVendor[]>([]);

  const [subscriptionDaysRating, setSubscriptionDaysRating] = useState<Date[]>([]);
  const [scheduledMeals, setScheduledMeals] = useState<IScheduledMeal[]>([]);
  const [subscription, setSubscription] = useState<IFetchSubscription | null>(null);
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
  const [dayToMove, setDayToMove] = useState<Date | null>(null);
  const [dailyValue, setDailyValue] = useState<IDailyValue[]>([]);
  const [maxDailyValue, setMaxDailyValue] = useState<IMaxDailyValue | null>(null);
  const {paidSubscriptionDays, subscriptionDays, role, wallet} = useGlobalStateContext();
  const {setSubscriptionDays, setPaidSubscriptionDays, setReFetchSubscriptions} = useGlobalStateDispatchContext();
  const subscriptionExists = (subscription?.subscription && subscription.subscription.data.length > 0);

  const ratingDays = useMemo(() => {
    const ratingDays = paidSubscriptionDays.filter(el => {
      return !getDisabledDays(new Date()).filter(day => compareDates(day, el)).length && compareDates(el, new Date(), "<=");
    })
    const ratingDaysCompleted: Date[] = ratingDays.filter(el => includesDate(subscriptionDaysRating, el))
    return {ratingDays, ratingDaysCompleted}
  }, [paidSubscriptionDays, subscriptionDaysRating]);
  const changeDays = useMemo(() => {
    const finalDay = add(new Date(), {
      days: 11,
    })
    return paidSubscriptionDays.filter(el => {
      return !getDisabledDays(new Date()).filter(day => compareDates(day, el)).length && compareDates(el, new Date(), ">") && compareDates(el, finalDay, "<");
    });
  }, [paidSubscriptionDays]);

  const prepareSelectedDate = (date: Date) => {
    return {
      day: date.getDate(),
      month: date.getMonth(),
      date: date
    };
  };

  const [selectedDate, setSelectedDate] = useState<ISelectedDate>(
    prepareSelectedDate(filtersFromUrl.selected_day_time
      ? new Date(`${filtersFromUrl.selected_day_time}`)
      : new Date()
    )
  );
  const [isPaidDay, setIsPaidDay] = useState(false);
  const handleDayChanged = (day: Date) => {
    setIsPaidDay(includesDate(paidSubscriptionDays, day));
    setSelectedDate(prepareSelectedDate(day));
  };

  const handleDayChangeClicked = (day: Date) => {
    setDayToMove(day);
    setIsModalOpen(true);
  };

  const getDayStatus = () => {
    const state = scheduledMeals?.[0]?.attributes?.shipment_state
    if (!state) return ''

    return <Typography mt={2} variant="common">Status: {orderStatuses[state]}</Typography>
  }

  const handleProductClicked = (mealType: string, meal: IScheduledMeal) => {
    history.push(
      "/products?" +
      qs.stringify({
        selected_meal_id: meal.id,
        meal_types_names: [meal.attributes.meal_type_name],
        selected_day_time: formatDateConstructable(selectedDate.date),
        // @ts-ignore
        product_price: meal.product?.data.attributes.price || 0
      }, {
        arrayFormat: "brackets",
        encode: false
      })
    );
  };

  const dataForDailyChart = useMemo(() => {
    if (!dailyValue.length) return {...maxDailyValue};
    const summaryValue = dailyValue.reduce((total, value) => {
      return {
        productId: 1,
        calories: total.calories + value.calories,
        carbohydrates: total.carbohydrates + value.carbohydrates,
        proteins: total.proteins + value.proteins,
        fats: total.fats + value.fats,
        price: total.price + value.price
      };
    });
    return {...summaryValue, ...maxDailyValue};
  }, [dailyValue])

  const retrieveProduct = async (meal: IFetchScheduledMeal) => {
    return await fetchProduct(meal.attributes.product_id);
  };

  const retrieveScheduledMeals = async (date?: Date) => {
    try {
      const meals = await fetchScheduledMeals(date);
      if (undefined === meals.scheduled_meals) return;

      const scheduledMealsFetched: IScheduledMeal[] = [];
      const productsValues: IDailyValue[] = [];

      for (const meal of meals.scheduled_meals.data) {
        try {
          const product = await retrieveProduct(meal);
          scheduledMealsFetched.push({
            ...meal,
            product
          });
          productsValues.push({
            productId: Number(product.data.id),
            calories: product.data.attributes.calories,
            carbohydrates: product.data.attributes.carbohydrates,
            proteins: product.data.attributes.proteins,
            fats: product.data.attributes.fats,
            // todo ask BackEnd & add logic for discounts
            price: product.data.attributes.price ? Number(product.data.attributes.price) : 0
            // price: product.data.attributes.display_price ? Number(product.data.attributes.display_price.substring(1).replace(",", ".")) : 0
          });
        } catch (e) {
          scheduledMealsFetched.push({
            ...meal,
            product: false
          });
        }
      }

      setScheduledMeals(scheduledMealsFetched.sort((a, b) =>
        a.attributes.meal_type_id > b.attributes.meal_type_id
          ? 1
          : b.attributes.meal_type_id > a.attributes.meal_type_id
            ? -1
            : 0
      ));
      setDailyValue(productsValues);
    } catch (e) {
      console.log(e)
    }
  };
  const retrieveSubscription = async (date: Date) => {
    try {
      const res = await fetchSubscription(date)
      setSubscription(res);
    } catch (e) {
      console.log(e, e?.message)
    }
  };

  useEffect(() => {
    if (isPaidDay) {
      retrieveScheduledMeals(selectedDate.date);
      retrieveSubscription(selectedDate.date);
    } else {
      setSubscription(null);
    }
  }, [selectedDate, isPaidDay]);

  useEffect(() => {
    if (paidSubscriptionDays.length < 1) return;
    setIsPaidDay(includesDate(paidSubscriptionDays, selectedDate.date));
  }, [paidSubscriptionDays, selectedDate]);

  useEffect(() => {
    fetchVendors().then(res => {
      console.log(res)
      setVendors(res.data)
    })
      .catch(e => console.log(e))
    setReFetchSubscriptions(prevState => !prevState)
  }, [])

  const clearData = () => {
    // setSubscriptionDays([]);
    setDailyValue([]);
    setScheduledMeals([]);
  };

  const handleFetchSubscriptionDays = (dayFrom: Date, dayTo: Date, rated: boolean) => {
    fetchSubscriptionDays(formatDateConstructable(dayFrom), formatDateConstructable(dayTo), rated)
      .then(res => setSubscriptionDaysRating(res?.data?.filter(el => el.attributes.rated)?.map(el => new Date(el.attributes.date))))
      .catch(e => console.log(e))
  }

  const handleRateScheduledMeal = (id: number, rating: number) => {
    const scheduledMealToRate = scheduledMeals.find(el => el.attributes.product_id === id);
    const subDayId = subscription?.subscription.data[0].relationships.subscription_days.data[0].id
    if (!scheduledMealToRate || !subDayId) return;
    rateProduct(scheduledMealToRate.attributes.product_id, rating, subDayId)
      .then(() => {
        setScheduledMeals(prevState => {
          return prevState.map(el => {
            if (!el.product) return el;
            if (el.product && Number(el.product.data.id) !== id) return el;
            el.product.data.attributes.current_user_product_rating = rating;
            return el;
          });
        });
        const subDays = paidSubscriptionDays.sort((a, b) => a.getTime() - b.getTime())
        if (subDays.length > 0) handleFetchSubscriptionDays(subDays[0], new Date(), true)
        toast.success("Twoja ocena została dodana.");
      })
      .catch(() => toast.error("Nie udało się ocenić posiłku."));
  };

  const handleLikeProduct = (id: number) => {
    const scheduledMealToRate = scheduledMeals.find(el => el.attributes.product_id === id);
    if (!scheduledMealToRate) return;
    // @ts-ignore
    const method = scheduledMealToRate?.product?.data?.attributes?.current_user_favourite_product
      ? unlikeProduct
      : likeProduct

    method(id)
      .then((res) => {
        setScheduledMeals(prevState => {
          return prevState.map(el => {
            if (!el.product) return el;
            if (el.product && Number(el.product.data.id) !== id) return el;
            el.product.data.attributes.current_user_favourite_product = res.data.attributes.current_user_favourite_product;
            return el;
          });
        });
      })
      .catch(() => toast.error("Wystąpił błąd."));
  };

  const replaceProductWithPlaceholder = async (selectedMealId: number, product: IProduct) => {
    return await updateScheduledMeal(selectedMealId, Number(product.data.id))
      .then(() => {
        setScheduledMeals(prevState => prevState.map(el => el.id === selectedMealId
          ? {...el, product}
          : el
        ));
        history.push("/nutrition-plans?" + qs.stringify({
          selected_day_time: formatDateConstructable(selectedDate.date)
        }));
      })
      .catch(err => {
        toast.error(err.message === "New product price is too high"
          ? "Cena produktu jest za wysoka."
          : "wymiana posilku nie jest mozliwa"
        );
      })
      .finally(() => {
        setIsDeleteModalOpen(false);
      });
  };

  const handleDeleteScheduledMealClick = (mealType: string) => {
    return function (id: number) {
      const scheduledMeal = scheduledMeals.find(el => el.attributes.product_id === id);
      if (!scheduledMeal) return;
      setScheduledMealIdToDelete(scheduledMeal.id);
      setMealTypeToReplace(mealType);
      setIsDeleteModalOpen(true);
    };
  };

  const handleDeleteScheduledMeal = () => {
    fetchTaxons(["placeholder"])
      .then(res => {
        if (Array.isArray(res?.placeholder?.data)) {
          const placeholderTaxonId = res?.placeholder?.data[0]?.id;
          fetchProducts({helpers_ids: placeholderTaxonId, meal_types_names: [mealTypeToReplace]})
            .then(products => {
              if (!scheduledMealIdToDelete) return;
              replaceProductWithPlaceholder(scheduledMealIdToDelete, {data: products.data[0], included: []})
                .finally(() => {
                  setIsDeleteModalOpen(false);
                  setScheduledMealIdToDelete(null);
                  setMealTypeToReplace("");
                });
            });
        }
      }).catch(e => console.log(e));

    // if (!scheduledMealIdToDelete) return;
    // deleteScheduledMeal(scheduledMealIdToDelete)
    //   .then(() => {
    //     const productId = scheduledMeals.find(el => el.id === scheduledMealIdToDelete)?.attributes.product_id ?? 0;
    //     setDailyValue(prevState => prevState.filter(el => el.productId !== productId));
    //     setScheduledMeals(prevState => prevState.filter(el => el.id !== scheduledMealIdToDelete));
    //     toast.success("Plan żywieniowy został usunięty.");
    //   })
    //   .catch(() => toast.error("Nie udało się usunąć planu żywieniowego."));
    // setIsDeleteModalOpen(false);
    // setScheduledMealIdToDelete(null);
  };

  const parseSubscriptions = (data: IFetchSubscriptions) => {
    if (!data || !data.hasOwnProperty("data")) return;
    const preparedSubscriptions = getPreparedSubscriptions(data);
    setSubscriptions(preparedSubscriptions);
  };

  useEffect(() => {
    if (filtersFromUrl.hasOwnProperty("selected_day_time")) clearData();
    fetchSubscriptions()
      .then(response => parseSubscriptions(response))
      .catch(e => console.log(e))

    fetchUser()
      .then(user => {
        setMaxDailyValue({
          maxCalories: user.data.attributes.preferred_energy,
          maxCarbohydrates: user.data.attributes.max_carbohydrates,
          maxProteins: user.data.attributes.max_proteins,
          maxFats: user.data.attributes.max_fats
        });
      })
      .catch(() => toast.error("Nie powiodła się próba pobrania danych użytkownika."));

    return () => clearData();
  }, []);

  const handleDayMoved = (oldDate: Date, newDate: Date) => {
    setSubscriptionDays(prevState => {
      const subIndex = prevState.findIndex(el => compareDates(el, oldDate));
      const newState = [...prevState];
      newState[subIndex] = newDate;
      return newState;
    });
    setPaidSubscriptionDays(prevState => {
      const subIndex = prevState.findIndex(el => compareDates(el, oldDate));
      const newState = [...prevState];
      newState[subIndex] = newDate;
      return newState;
    })
    setSelectedDate(prepareSelectedDate(newDate));
    // handleDayChanged(newDate);
  };

  useEffect(() => {
    fetchAddresses()
      .then(response => {
        const shipAddress = response.data.find(el => el.attributes.address_type === "ship address");
        if (shipAddress) {
          setFullName(`${shipAddress.attributes.firstname} ${shipAddress.attributes.lastname}`);
        }
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    const isAlert = localStorage.getItem("onboarding-alert");
    const val = isAlert !== 'false' && role !== 'courier'
    isAlert && setIsSettingsModalOpen(val)
  }, [role])

  useEffect(() => {
    const subDays = paidSubscriptionDays.sort((a, b) => a.getTime() - b.getTime())
    if (subDays.length > 0) handleFetchSubscriptionDays(subDays[0], new Date(), true)
  }, [paidSubscriptionDays])

  const getMessageForPricesDisbalance = (pricePerDay: number, actualPrice?: number) => {
    if (!pricePerDay || !actualPrice) return ''
    let val = ''
    val += actualPrice?.toFixed(2) + ' zł'
    const walletVal = wallet?.attributes?.amount ? Math.floor(wallet?.attributes?.amount) : 0
    const difference = actualPrice - pricePerDay
    if (difference > 0 && walletVal - difference > 0) val += '. Wartość zestawu przekracza twój dzienny budżet, różnica zostanie pobrana z twojej skarbonki'
    if (difference > 0 && walletVal - difference < 0) val += '. Nie masz wystarczających środków w skarbonce żeby pokryć różnicę. Doładuj swoją skarbonkę'
    return val
  }
  const prepareGridData: IDataGridProps["data"] = useMemo(() => {
    if (!subscription) return []
    const fullSub = subscriptions.find(el => el?.id === subscription?.subscription?.data?.[0]?.id)
    const dateFromTo = fullSub?.subscription_days?.reduce((acc, curr, index, arr) => {
      if (index === 0) return acc += `${formatDate(curr.date)} - `
      if (index === arr.length - 1) return acc += `${formatDate(curr.date)}`
      return acc
    }, '') || ''

    return [
      [
        {
          label: 'Numer zamówienia:',
          value: subscription?.subscription?.data?.[0]?.id
        },
        {
          label: 'Status zamówienia',
          value: subscription?.subscription?.data?.[0]?.attributes?.active
            ? <Typography color="#2CCF36">Aktywny</Typography>
            : <Typography color="#ee5a24">Niekatywny</Typography>
        }
      ],
      // {
      //   label: 'Wartości odżywcze',
      //   value: ''
      // },
      // {
      //   label: 'Preferencje',
      //   value: ''
      // },
      [
        {
          label: 'Ilość posiłków:',
          value: scheduledMeals.length
        },
        {
          label: 'Ilość dni:',
          value: fullSub ? fullSub.subscription_days.length : ''
        },
      ],
      {
        label: 'Okres:',
        value: dateFromTo
      },
      [
        {
          label: 'Dzienny budżet',
          value: subscription?.subscription?.data?.[0]?.attributes?.price_per_day
        },
        {
          label: 'Wartość abonamentu',
          value: subscription?.subscription?.data?.[0]?.attributes?.price,
          // @ts-ignore
          info: dataForDailyChart?.price < 54 ? undefined : "<p>Dostawa - gratis</p>"
          // info: dataForDailyChart?.price > 54 ? undefined : "<p>Zawiera koszt dostawy 8 PLN na dzień</p>"
        },
      ],
      [
        {
          label: 'Wartość zestawu',
          isBold: true,
          value: ""
        },
      ],
      [
        {
          label: 'Wartość posiłków',
          // @ts-ignore
          value: getMessageForPricesDisbalance(subscription?.subscription?.data?.[0]?.attributes?.price_per_day || 0, dataForDailyChart?.price)
        },
      ],
      [
        {
          label: 'Koszt dostawy',
          // @ts-ignore
          value: dataForDailyChart?.price > 54 ? "0 zł" : "8 zł",
          // @ts-ignore
          info: dataForDailyChart?.price > 54 ? undefined : "<p>Doplata naliczona przy wartości poniżej 55zł</p>"
        },
      ],
      [
        {
          label: 'Koszt całkowity',
          // @ts-ignore
          value: dataForDailyChart?.price > 54 ? dataForDailyChart?.price?.toFixed(2) : ((dataForDailyChart?.price || 0) + 8)?.toFixed(2),
        },
      ],
    ]
  }, [scheduledMeals, subscription, subscriptions, dataForDailyChart])

  return (
    <MainLayout>
      <SettingsModal
        isOpen={isSettingsModalOpen}
        onCancelClick={() => {
          setIsSettingsModalOpen(false);
          localStorage.setItem("onboarding-alert", "false");
        }}
      />
      <Flex alignItems="center" mb={5}>
        <Person
          fullName={fullName}
          hugeFullName
          imgPath="#"
        />
        <Flex ml={5} alignItems="center" justifyContent="start">
          <StyledDate>
            {selectedDate.day} {monthNames[selectedDate.month]}
          </StyledDate>
        </Flex>
      </Flex>
      <Row>
        <Col xs={8}>
          {subscriptionExists ?
            fullName.length < 1
              ? <Flex justifyContent="center" mt={6} alignContent="center">
                <Typography variant="h2">Wypełnij dane w ustawieniach</Typography>
              </Flex>
              : scheduledMeals.map((meal) => {
                const label = meal.attributes.meal_type_name;
                const time = mealTypes.find(el => el.label === meal.attributes.meal_type_name)?.time;
                return (
                  <React.Fragment key={meal.id}>
                    <Box key={label} mb={5}>
                      <Flex alignItems="center" justifyContent="space-between">
                        <StyledDay mb={3} style={{textTransform: "capitalize"}}>
                          {label}
                          <span>{time}</span>
                        </StyledDay>
                        {meal.product === undefined && <IconPlus/>}
                      </Flex>
                      {meal.product === false &&
                        <Flex>
                          <Col>
                            <Typography variant="caption">
                              Dane nie są dostępne
                            </Typography>
                          </Col>
                        </Flex>
                      }
                      {meal.product && (
                        meal.product.data.attributes.name.includes("Placeholder")
                          ? <Flex>
                            <Col>
                              <Typography variant="caption">
                                System nie był w stanie zaproponować posiłku.
                                {changeDays.find(el => compareDates(el, selectedDate.date))
                                  ? " Możesz to zrobić samodzielnie wybierając go z Naszej oferty."
                                  : null
                                }
                              </Typography>
                            </Col>
                            <Col>
                              {changeDays.find(el => compareDates(el, selectedDate.date))
                                ? <Box width="120px" ml="auto">
                                  <Button
                                    onClick={() => handleProductClicked(label, meal)}
                                    fullWidth
                                    variant="primary"
                                  >
                                    Wybierz
                                  </Button>
                                </Box>
                                : null
                              }
                            </Col>
                          </Flex>
                          : <DishOffer
                            // @ts-ignore
                            vendor={vendors.find(el => el.id === meal?.product?.data?.relationships?.vendor?.data?.id)}
                            onDelete={
                              changeDays.find(el => compareDates(el, selectedDate.date))
                                ? handleDeleteScheduledMealClick(label)
                                : undefined
                            }
                            deleteIcon
                            dish={meal.product.data}
                            userRating={
                              ratingDays.ratingDays.find(el => compareDates(el, selectedDate.date))
                                ? true
                                : compareDates(new Date(), selectedDate.date)
                                  ? true
                                  : false
                            }
                            onRate={handleRateScheduledMeal}
                            onLike={handleLikeProduct}
                            currentUserRating={meal.product.data.attributes.current_user_product_rating}
                            optionsButton={
                              changeDays.find(el => compareDates(el, selectedDate.date))
                                ? {
                                  label: "Zmień",
                                  onClick: () => handleProductClicked(label, meal)
                                }
                                : undefined
                            }
                          />
                      )}
                    </Box>
                  </React.Fragment>
                );
              })
            : <Flex justifyContent="center" mt={6} alignContent="center">
              <Typography variant="h2">
                {fullName.length < 1
                  ? "Wypełnij dane w ustawieniach"
                  : compareDates(selectedDate.date, new Date()) ? "Brak dostawy" : "Brak abonamentu"}
              </Typography>
            </Flex>
          }
        </Col>
        <Col xs={4}>
          <Card mb={6} py={6} px={8}>
            <Calendar
              key={selectedDate.date.getTime()}
              showIcons
              initDays={[selectedDate.date]}
              initialMonth={selectedDate.date}
              rating
              selectOneDay
              onDayChanged={handleDayChanged}
              onChangeDayClick={handleDayChangeClicked}
              highlightedDays={paidSubscriptionDays}
              ratingDays={ratingDays.ratingDays}
              ratingDaysGreen={ratingDays.ratingDaysCompleted}
              changeDays={changeDays}
              onDisabledDayClick={() => toast.error("Pogląd menu jeszcze nie dostępny")}
            />
            {getDayStatus()}
          </Card>
          {subscriptionExists &&
            <>
              <DailyChart key={dailyValue.length} {...dataForDailyChart}
                          budget={subscription?.subscription.data[0].attributes.price_per_day}/>
              <Card mt={6} py={6} px={8}>
                <Typography variant="h3" mb={3}>
                  {fullName}
                </Typography>
                <DataGrid data={prepareGridData}/>
              </Card>
            </>
          }
        </Col>
      </Row>
      <ChangeDayModal
        subscriptions={subscriptionDays}
        handleDayMoved={handleDayMoved}
        subscription={subscription}
        dayToMove={dayToMove}
        isOpen={isModalOpen}
        onCancelClick={() => {
          setIsModalOpen(false);
          setDayToMove(null);
        }}
      />
      <ConfirmDeleteModal
        title="Czy na pewno chcesz usunąć ten posiłek?"
        isOpen={isDeleteModalOpen}
        onSubmit={handleDeleteScheduledMeal}
        onCancelClick={() => {
          setIsDeleteModalOpen(false);
          setScheduledMealIdToDelete(null);
          setMealTypeToReplace("");
        }}
      />
    </MainLayout>
  )
    ;
};
