import React from "react";
import { MainLayout } from "layouts/MainLayout";
import { Col, Row } from "react-grid-system";
import { Tabs } from "components/Tabs/Tabs";
import { IRow, Table } from "components/Table/Table";
import { Button } from "components/Button";
import { Card } from "components/Card";
import { Coin } from "components/Coin"
import { Flex } from "components/Flex"
import { Typography } from "components/Typography";
import { Box } from "components/Box";
import { PaymentModal } from "components/PaymentModal/PaymentModal";
import { fetchPayment, postPayment } from "services/payment";
import { fetchWalletEvents } from "services/user";
import { toast } from "react-toastify";
import { useGlobalStateContext } from "../contexts/GlobalStateContext";
import { useInterval } from "../hooks/interval";
import { useParams } from "react-router";

interface IWalletEvent {
  id: string
  type: string
  attributes: {
    amount: number
    created_at: string
    name: string
    key: string
  }
}

export const WalletView = () => {
  const { paymentId } = useParams<{ paymentId: string }>();
  const [requestStatus, setRequestStatus] = React.useState<string | null>(null);
  const [walletEvents, setWalletEvents] = React.useState<IWalletEvent[]>([]);
  const [rows, setRows] = React.useState<IRow[]>([]);
  // for modal
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [amount, setAmount] = React.useState(150);
  const { wallet } = useGlobalStateContext();
  const delimiters = ["25%", "25%", "15%", "15%", "15%"];
  const columnNames = ["Zdarzenie", "Dzień", "Wartość"];

  const tabs = [
    {
      label: "Historia skarbonki", node:
        <Table
          key={rows.length}
          delimiters={delimiters}
          columnNames={columnNames}
          rows={rows}
        />
    }
  ];

  const getWalletEvents = async () => {
    const response = await fetchWalletEvents();
    if (!response) {
      setWalletEvents([]);
    } else {
      setWalletEvents(response.data);
    }
  };

  const parseDate = (date: string) => {
    return (
      date.slice(8, 10) +
      "." +
      date.slice(5, 7) +
      "." +
      date.slice(0, 4)
    );
  };

  const prepareRows = (walletEvents: IWalletEvent[]) => {
    const preparedRows = walletEvents
      .map(event => {
        return {
          rowData: [
            <Typography
              key={event.id}
              color={
                event.attributes.name === "Zasilenie skarbonki" ||
                  event.attributes.name === "Oszczędności"
                  ? "text.lighter"
                  : "orange"
              }
            >
              {event.attributes.name}
            </Typography>,
            <Typography key={event.id} color="text.lighter">
              {parseDate(event.attributes.created_at)}
            </Typography>,
            <Flex key={event.id} alignItems="center">
              <Typography color="text.lighter">
                {
                  event.attributes.name === "Zasilenie skarbonki" ||
                    event.attributes.name === "Oszczędności"
                    ? `+ ${event.attributes.amount}`
                    : `- ${event.attributes.amount}`
                }
              </Typography>
              <Coin>C</Coin>
            </Flex>
          ],
        };
      });
    if (preparedRows.length > 0 && preparedRows[0] !== undefined) { // @ts-ignore
      setRows(preparedRows);
    }
  };

  const getPayment = (id: number) => {
    if (id === 0) return;
    fetchPayment(id)
      .then(payment => {
        setRequestStatus(payment.data.attributes.status);
      })
      .catch(() => toast.error("Nie udało się pobrać płatność."));
  };

  if (paymentId) useInterval(() => getPayment(Number(paymentId)), (requestStatus === null || requestStatus === "pending") ? 1000 : null);

  const handlePaymentSubmit = () => {
    if (amount < 1) {
      toast.error("Nie udało się doładować skarbonki.");
      return;
    }
    if (wallet && wallet.id)
      postPayment({
        payment: {
          payable_object_id: Number(wallet.id),
          payable_object_type: "wallet",
          amount,
          continue_path: "/wallet/"
        }
      }
      )
        .then(res => {
          // @ts-ignore
          window.location.href = res.data.attributes.url;
        })
        .catch(e => toast.error("Płatność nie powiodła się."));
  };

  React.useEffect(() => {
    getWalletEvents();
  }, []);

  React.useEffect(() => {
    prepareRows(walletEvents);
  }, [walletEvents]);

  return (
    <MainLayout>
      <Row>
        <Col xs={3}>
          <Card p={6}>
            <Typography variant="h1">
              Skarbonka
            </Typography>
            {requestStatus &&
              <Box mt={3}>
                <hr />
                <Typography variant="h4">
                  {requestStatus === "pending" && "Płatność w trakcie realizacji."}
                  {requestStatus === "completed" && "Dziękujemy, płatność została zrealizowana."}
                  {requestStatus === "failed" && "Twoja płatność nie powiodła się."}
                </Typography>
                <hr />
              </Box>
            }
            {wallet &&
              <Box mt={3} mb={2}>
                <Typography variant="caption">Zawartość skarbonki</Typography>
                <Flex alignItems="center">
                  <Typography color="text.lighter" fontSize="14">
                    {wallet?.attributes?.amount && Math.floor(wallet?.attributes?.amount)}
                  </Typography>
                  <Coin>C</Coin>
                </Flex>
              </Box>
            }
            <Button variant="primary" fullWidth onClick={() => setIsModalOpen(true)}>
              Doładuj skarbonkę
            </Button>
          </Card>
        </Col>
        <Col xs={9}>
          <Tabs tabs={tabs} />
        </Col>
      </Row>
      <PaymentModal
        isOpen={isModalOpen}
        amount={amount}
        setAmount={setAmount}
        onCancelClick={() => {
          setIsModalOpen(false);
        }}
        onSubmit={handlePaymentSubmit}
      />
    </MainLayout>
  );
};
