import styled from "styled-components";

export const AdditionalLabel = styled.div<{ variant: 'new' | 'promotion' }>`
  height: 24px;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.83px;
  line-height: 24px;
  ${({ theme, variant }) => `
    color: ${theme.colors.neutral.white};
    padding: 0 ${theme.space[2]}px;
    border-radius: ${theme.radii[1]}px;
    ${variant === "new" ? ` background-color: #3AC5D6 ` : ` background-color: #FFC107; `}
  `}
`;
