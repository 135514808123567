import { forwardRef, InputHTMLAttributes } from "react";
import { Input } from "components/Input";
import { Typography } from "./Typography";
import styled from "styled-components";
import { Flex } from "./Flex";

const WrapperInput = styled.div<{ width?: string }>`
  position: relative;
  & > input {
    font-family: "Open Sans", sans-serif;
  }
  ${({ width }) => `
    ${width && `width: ${width};`}
  `}
`;

const Icon = styled.div`
  ${({ theme }) => `
    display: flex;
    position: absolute;
    top: calc(50% + 6px);
    right: ${theme.space[2]}px;
    transform: translateY(-50%);
    color: #c0c5cd;
  `}
`;

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  width?: string;
  label: string;
  icon?: React.ReactNode;
  transparent?: boolean;
  error?: string;
}

export const TextField: React.FC<Props> = forwardRef<HTMLInputElement, Props>(
  ({
     width,
     label,
     icon,
     transparent,
     error,
     ...inputProps
   }, ref) => (
    <>
      <WrapperInput width={width}>
        <Typography variant="label" ml={3}>
          {label}
        </Typography>
        <Input max={inputProps.type === "date" ? "9999-12-31" : undefined} ref={ref} fullWidth
               transparent={transparent} {...inputProps} />
        {icon && <Icon>{icon}</Icon>}
      </WrapperInput>
      {error ? (
        <Flex justifyContent="flex-end">
          <Typography variant="error">{error}</Typography>
        </Flex>
      ) : null}
    </>
  )
);
