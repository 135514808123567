import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const FaIcon = styled(FontAwesomeIcon)`
  align-self: center;
  color: #c0c5cd;
  cursor: pointer;
`;


