import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import { theme } from "../config/theme";
import sanitizeHtml from "sanitize-html";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 16px;
  height: 16px;
  color: white;
  font-size: 10px;
  border-radius: 50%;
  line-height: 14px;
  cursor: pointer;
  ${({ theme }) => `
    background-color: ${theme.colors.neutral.light};
  `};
`;

export const SpeechBubble: React.FC<{ html: string }> = ({
                                                           children,
                                                           html
                                                         }) => {

  return <>
    <Root>
      <p className="tooltip-icon" data-tip={sanitizeHtml(html)}>{children}</p>
    </Root>
    <ReactTooltip
      html
      className="tooltip"
      textColor={theme.colors.text.light}
      backgroundColor={theme.colors.neutral.light}
      effect="solid"
    />
  </>;
};
