import styled from "styled-components";
import { Typography } from "./Typography";
import { Flex } from "./Flex";

export const PriceContainer = styled(Flex)`
  ${({ theme }) => `
    margin-top: ${theme.space[4]}px;
    margin-bottm: ${theme.space[1]}px;
  `}
  min-width: 120px;
  align-items: center;
  justify-content: flex-end;
`;
export const OldPrice = styled(Typography)`
  ${({ theme }) => `
    margin-right: ${theme.space[2]}px;
  `}
  color: #c0c5cd;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  text-decoration: line-through;
`;
export const Price = styled(Typography)`
  color: #2ba76a;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: right;
`;
