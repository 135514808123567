import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {createAddress, deleteAddress, fetchAddresses, updateAddress} from "../services/address";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {addressSchema} from "../schemas/delivery";
import {IDeliveryForm} from "../types/delivery";

export const useDelivery = (
  isLoading: boolean,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  displayFirmForm: boolean,
  setDisplayFirmForm: React.Dispatch<React.SetStateAction<boolean>>,
  formsData: IDeliveryForm,
  setFormsData: (currentFormsData: IDeliveryForm) => void,
  redirect?: string,
) => {
  // [ship & bill]
  const [addressesIds, setAddressesIds] = useState<(null | undefined | string | number)[]>([null, null]);
  const history = useHistory();

  const {register, handleSubmit, formState, control, reset, setValue} = useForm<IDeliveryForm>({
    defaultValues: {
      firstname: formsData?.firstname ?? "",
      lastname: formsData?.lastname ?? "",
      phone: formsData?.phone ?? "",
      // email: formsData?.email ?? '',
      i_want_to_receive_an_invoice: !!formsData?.bill_address?.company_name,
      leave_at_the_door: !!formsData?.leave_at_the_door,
      ship_address: {
        street: formsData?.ship_address?.street ?? "",
        number: formsData?.ship_address?.number ?? "",
        local: formsData?.ship_address?.local ?? "",
        zipcode: formsData?.ship_address?.zipcode ?? "",
        city: formsData?.ship_address?.city ?? "",
        floor: formsData?.ship_address?.floor ?? "",
        stairway: formsData?.ship_address?.stairway ?? "",
        door_code: formsData?.ship_address?.door_code,
        additional_info: formsData?.ship_address?.additional_info ?? ""
      },
      bill_address: {
        company_name: formsData?.bill_address?.company_name ?? "",
        nip: formsData?.bill_address?.nip ?? "",
        street: formsData?.bill_address?.street ?? "",
        number: formsData?.bill_address?.number ?? "",
        local: formsData?.bill_address?.local ?? "",
        zipcode: formsData?.bill_address?.zipcode ?? "",
        city: formsData?.bill_address?.city ?? ""
      }
    }, resolver: yupResolver(addressSchema)
  });

  useEffect(() => {
    fetchAddresses().then(response => {
      const billAddress = response.data.find(el => el.attributes.address_type === "bill address");
      const shipAddress = response.data.find(el => el.attributes.address_type === "ship address");
      setAddressesIds([shipAddress?.id, billAddress?.id]);

      let data = {
        firstname: "",
        lastname: "",
        phone: "",
        // email: "",
        i_want_to_receive_an_invoice: false,
        leave_at_the_door: false,
        ship_address: {
          street: "",
          number: "",
          local: "",
          zipcode: "",
          city: "",
          floor: "",
          stairway: "",
          door_code: 0,
          additional_info: "",
        },
        bill_address: {
          company_name: "",
          nip: "",
          street: "",
          number: "",
          local: "",
          zipcode: "",
          city: "",
        }
      }

      if (shipAddress) {
        data = {
          ...data,
          firstname: shipAddress.attributes.firstname,
          lastname: shipAddress.attributes.lastname,
          phone: shipAddress.attributes.phone,
          i_want_to_receive_an_invoice: false,
          leave_at_the_door: shipAddress.attributes.leave_at_the_door || false,
          ship_address: {
            street: shipAddress.attributes.street,
            number: shipAddress.attributes.number,
            local: shipAddress.attributes.local,
            zipcode: shipAddress.attributes.zipcode,
            city: shipAddress.attributes.city,
            floor: shipAddress.attributes.floor,
            stairway: shipAddress.attributes.stairway,
            door_code: shipAddress.attributes.door_code,
            additional_info: shipAddress.attributes.additional_info,
          }
        };
      }

      if (billAddress) {
        data = {
          ...data,
          i_want_to_receive_an_invoice: true,
          bill_address: {
            company_name: billAddress.attributes.company_name ?? "",
            nip: billAddress.attributes.nip ?? "",
            street: billAddress.attributes.street,
            number: billAddress.attributes.number,
            local: billAddress.attributes.local,
            zipcode: billAddress.attributes.zipcode,
            city: billAddress.attributes.city,
          }
        }
      }

      // show or hide bill address form
      setDisplayFirmForm(data.i_want_to_receive_an_invoice)
      // fill form with data
      reset(data)
      // fill context with data
      setFormsData(data)
    })
      .catch(err => {
        console.log(err)
      })
  }, []);

  const handleBillAddress = async (data: IDeliveryForm, currentFormsData: IDeliveryForm) => {
    if (displayFirmForm) {
      const address = {
        ...data.bill_address,
        firstname: data.firstname,
        lastname: data.lastname,
        phone: data.phone,
        i_want_to_receive_an_invoice: true,
        leave_at_the_door: data.leave_at_the_door
      };
      try {
        if (addressesIds[1]) {
          await updateAddress(addressesIds[1], address);
          toast.success("Adres rachunku został zmieniony.");
        } else {
          const response = await createAddress({
            address_type: "bill_address",
            address
          });
          // reset bill address id
          setAddressesIds([addressesIds[0], response.data.id])

          toast.success("Adres rachunku został dodany.");
        }
      } catch (e) {
        toast.error("Nie udało się zapisać adres rachunku");
        return false;
      }
    } else {
      if (addressesIds[1]) {
        try {
          await deleteAddress(addressesIds[1]);
          const bill_address = {
            company_name: "",
            nip: "",
            street: "",
            number: "",
            local: "",
            zipcode: "",
            city: ""
          }
          // clean formsData
          currentFormsData.bill_address = bill_address
          currentFormsData.i_want_to_receive_an_invoice = false;
          // clean form
          setValue("bill_address", bill_address)
          setValue("i_want_to_receive_an_invoice", false)
          // reset bill address id
          setAddressesIds([addressesIds[0], null])

          toast.success("Adres rachunku został usunięty.");
        } catch (e) {
          toast.error("Nie udało się usunąć adres rachunku");
          return false;
        }
      }
    }
    return true;
  };

  const handleShipAddress = async (data: IDeliveryForm) => {
    const address = {
      ...data.ship_address,
      firstname: data.firstname,
      lastname: data.lastname,
      phone: data.phone,
      leave_at_the_door: data.leave_at_the_door,
    };
    try {
      if (addressesIds[0]) {
        const result = await updateAddress(addressesIds[0], address);
        if (!result?.data?.attributes?.deliverable) {
          toast.error("Przykro nam ale jeszcze dostarczamy do twojej miejscowości.");
          return "bad_address"
        } else {
          toast.success("Adres wysyłki został zmieniony.");
        }
      } else {
        const result = await createAddress({
          address_type: "ship_address",
          address
        });
        if (!result?.data?.attributes?.deliverable) {
          toast.error("Przykro nam ale jeszcze dostarczamy do twojej miejscowości.");
          await deleteAddress(result.data.id)
          return "bad_address"
        } else {
          toast.success("Adres wysyłki został dodany.");
        }
      }
    } catch (e) {
      toast.error("Nie udało się zapisać adresu wysyłki.");
      return false;
    }
    return true;
  };

  const onSubmit = async (data: IDeliveryForm) => {

    const currentFormsData = {...data};
    setIsLoading(true)
    const billRes = await handleBillAddress(data, currentFormsData);
    const shipRes = await handleShipAddress(data);
    setIsLoading(false)

    if (billRes && shipRes) {
      setFormsData(currentFormsData);
      if (redirect && shipRes !== 'bad_address')
        history.push(redirect);
    }
  };

  return {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    onSubmit
  };
};
