import { fetchAPI } from "utilities/fetchAPI";
import { formatDate } from "utilities/date";

export interface IFetchScheduledMeal {
  id: number,
  type: string,
  attributes: {
    date: string,
    meal_type_name: string;
    meal_type_id: number,
    product_id: number
    shipment_state: string

  }
}

export interface IFetchScheduledMeals {
  scheduled_meals: {
    data: IFetchScheduledMeal[]
  }
}

export const fetchScheduledMeals = async (date?: Date) =>
  await fetchAPI<IFetchScheduledMeals>(
    `${process.env.REACT_APP_API_URL}scheduled_meals${date && '?date=' + formatDate(date)}`);

export const updateScheduledMeal = async (mealId: number, product_id: number) =>
  await fetchAPI<IFetchScheduledMeals>(
    `${process.env.REACT_APP_API_URL}scheduled_meals/${mealId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({product_id}),
    });

export const deleteScheduledMeal = async (mealId: number) =>
  await fetchAPI<IFetchScheduledMeals>(
    `${process.env.REACT_APP_API_URL}scheduled_meals/${mealId}`, {
      method: "DELETE",
    });
