import styled from "styled-components";
import {Container} from "react-grid-system";
import {useHistory, useRouteMatch} from "react-router-dom";
import {Dropdown} from "components/Dropdown";
import logo from "images/logo.png";
import {Link} from "react-router-dom";
import {Box} from "./Box";
import {Button} from "./Button";
import {Coin} from "components/Coin";
import {Flex} from "./Flex";
import {Typography} from "./Typography";
import {useAuthDispatchContext} from "contexts/AuthContext";
import {PurchaseSubscriptionModal} from "./PurchaseSubscriptionModal/PurchaseSubscriptionModal";
import {useState} from "react";
import {useNavbar} from "hooks/navbar";
import {IconShoppingCart} from "./Icons/IconShop";
import {IconPig} from "./Icons/IconPigg";
import {useGlobalStateContext, useGlobalStateDispatchContext} from "../contexts/GlobalStateContext";
import {ReactComponent as AvatarIcon} from "icons/avatar.svg";


const Sizer = styled.div`
  ${({theme}) => `
    height: 72px;
  `};
`;

const Root = styled.div`
  ${({theme}) => `
    width: 100%;
    height: 72px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #f3f4f6;
    font-size: 12px;
    line-height: 18px;
  `};
`;

const StyledContainer = styled(Container)`
  height: 100%;
`;

const Logo = styled(Link)`
  display: flex;

  img {
    width: 145px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const NavbarLink = styled(Link) <{ active?: boolean }>`
  ${({theme, active}) => `
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 0 ${theme.space[4]}px;
    color: ${active ? theme.colors.primary : theme.colors.text.lighter};
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    
    &:not(:last-child) {
      margin-right: ${theme.space[6]}px;
    }
  `};
`;

const SubscriptionInfo = styled.div`
  display: flex;
  grid-template-columns: 1fr auto;
  color: #fff;
  align-items: center;
  background-color: #ee5a24;
  height: 40px;
  padding: 0 16px;
  font-size: 12px;
  line-height: 16px;
  border-radius: 8px;
  font-weight: bold;

  & span {
    font-weight: 400;
    justify-self: flex-end;
  }
`;

const Boxprice = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #c1e3d5;
  color: #36ab71;
  border-radius: 8px;
  padding: 5px;
  height: 30px;
  font-weight: 600;
`;

const Avatar = styled(AvatarIcon)`
  width: 40px;
  height: 40px;
`;

export const Navbar = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const history = useHistory();
  const {setToken} = useAuthDispatchContext();
  const {wallet, profile, role} = useGlobalStateContext();
  const {setProfile} = useGlobalStateDispatchContext();
  const subDaysLeft = profile?.data.attributes.subscription_days_left;
  const subDaysLeftStr = subDaysLeft ? subDaysLeft + (subDaysLeft === 1 ? " dzień" : " dni") : "";
  const {navbarLinks} = useNavbar(role);

  const handleLogout = () => {
    setToken(null, null);
    setProfile(null);
    history.push("/login");
  };

  const items = role === 'user' ? [
    {
      label: "Ustawienia",
      onClick: () => history.push("/settings/profile")
    },
    // {
    //   label: "Abonamenty",
    //   onClick: () => history.push("/subscriptions")
    // },
    {
      label: "Wyloguj",
      onClick: handleLogout
    }
  ] : [
    {
      label: "Wyloguj",
      onClick: handleLogout
    }
  ];

  return (
    <>
      <Sizer/>
      <Root>
        <StyledContainer>
          <Wrapper>
            <Logo to={role === 'user' ? '/' : '/delivery'}>
              <img src={logo} alt="Boofet"/>
            </Logo>
            <Flex width="100%" justifyContent="flex-end" alignItems="center">
              <Flex justifyContent="center" m="auto" alignContent="center">
                {navbarLinks && navbarLinks.map(link => <NavbarLink
                    active={link.match}
                    key={link.label}
                    to={link.to}>
                    {link.label}
                  </NavbarLink>
                )}
              </Flex>
              {role === 'user' && (!!subDaysLeft && subDaysLeft > 0) &&
                <SubscriptionInfo>
                  <Typography>{subDaysLeftStr}&nbsp;<span>do końca</span></Typography>
                </SubscriptionInfo>
              }
              {role === 'user' && <>
                <Box ml={3} mr={3}>
                  <Button disabled={!!useRouteMatch({
                    path: "/shopping-cart"
                  })} onClick={() => setIsModalOpen(true)} variant="primary">Kup abonament</Button>
                </Box>
                <Box display="flex" mr={3}>
                  <IconPig onClick={() => history.push("/wallet")} active={!!useRouteMatch({
                    path: "/wallet"
                  })}/>
                </Box>
                <Boxprice onClick={() => history.push("/wallet")}>
                  {wallet?.attributes?.amount && Math.floor(wallet?.attributes?.amount)}
                  <Coin>C</Coin>
                </Boxprice>
                <Box display="flex" mx={3}>
                  <IconShoppingCart onClick={() => history.push("/shopping-cart")} active={!!useRouteMatch({
                    path: "/shopping-cart"
                  })}/>
                </Box>
              </>
              }

              <Dropdown items={items}>
                <Avatar/>
              </Dropdown>
            </Flex>
          </Wrapper>
        </StyledContainer>
      </Root>
      {isModalOpen &&
        <PurchaseSubscriptionModal
          isOpen={isModalOpen}
          onCancelClick={() => setIsModalOpen(false)}
        />
      }
    </>
  );
};
