import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { TextColorProps, system } from "styled-system";

export interface Props extends TextColorProps {}

export const Link = styled(RouterLink)<Props>`
  ${system({
    color: {
      property: "color",
      scale: "colors",
    },
  })}
`;

Link.defaultProps = {
  color: "primary",
};
