/*eslint-disable */
import {Button} from "components/Button";
import {Box} from "components/Box";
import {Flex} from "components/Flex";
import {Typography} from "components/Typography";
import {Controller, useForm} from "react-hook-form";
import React, {useEffect, useRef, useState} from "react";
import {rangeFileds} from "views/RegisterDietView";
import {InputRange} from "components/InputRange/InputRange";
// import { LineChart, Props } from "components/LineChart/LineChart";
import {formatDate} from "utilities/date";
import {updateUser} from "services/user";
import {toast} from "react-toastify";
import {useSettingsFormDispatchContext, useSettingsFormStateContext} from "contexts/SettingsFormContext";
import {Graph} from "components/Graph/Graph";
import {Col, Row} from "react-grid-system";
import {Checkbox} from "components/Checkbox";
import {fetchMealTypes} from "services/product";
import {getDisabledDays} from "utilities/calendar";
import {useFirstRender} from "../../hooks/firstRender";
import {useCalculateDiet} from "../../hooks/calculateDiet";
import {number} from "yup";
import {useGlobalStateContext, useGlobalStateDispatchContext} from "../../contexts/GlobalStateContext";

export interface ISettingsDietForm {
  target_days: number;
  physical_activity: number;
  energy: number;
  protein?: number;
  fats?: number;
  carbohydrates?: number;
  height: number;
  weight: number;
  target_weight: number;
  meal_types_ids: number[] | null;
  meal_types: IMealType[];
}

interface IMealType {
  name: string;
  value: string;
  checked: boolean;
}

export const SettingsDietView: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {formsData} = useSettingsFormStateContext();
  const {setProfile} = useGlobalStateDispatchContext();
  const { profile } = useGlobalStateContext();
  const {setFormsData} = useSettingsFormDispatchContext();
  const ref = useRef(null);
  const [mealTypes, setMealTypes] = useState<IMealType[]>([]);
  const today = new Date();
  const [lastDay, setLastDay] = useState<number>();
  const [kcalRange, setKcalRange] = useState<[number, number]>([
    ((formsData?.meal_types_ids?.length || 1) * 300), ((formsData?.meal_types_ids?.length || 1) * 700)
  ])
  const disabledDays: Date[] = getDisabledDays();
  const lastDisabledDay = new Date(new Date().setDate(disabledDays[disabledDays.length - 1].getDate() + 1));
  const firstRender = useFirstRender();

  const {register, handleSubmit, control, watch, setValue} = useForm<ISettingsDietForm>(
    {
      defaultValues: {
        target_days: formsData.target_days ?? 1,
        physical_activity: formsData.physical_activity || 5000,
        energy: formsData?.energy || 700,
        protein: 0,
        fats: 0,
        carbohydrates: 0,
        height: formsData.height,
        weight: formsData.weight,
        target_weight: formsData?.target_weight,
        meal_types_ids: formsData.meal_types_ids,
        meal_types: []
      }
    }
  );
  const watcher = watch();

  const calculatedWeight = useCalculateDiet(
    formsData.height || 170,
    formsData.weight || 70,
    formsData.target_weight || 70,
    watcher.target_days,
    watcher.energy,
    watcher.physical_activity,
    formsData.gender || "male",
    formsData.birthday || "1990-11-11",
    firstRender,
    formsData.energy || 2200
  );

  useEffect(() => {
    if ("object" === typeof calculatedWeight) {
      setValue("target_days", calculatedWeight.prefDays);
      setValue("energy", calculatedWeight.CPM);
    }
    if ("number" === typeof calculatedWeight) {
      setValue("target_weight", calculatedWeight);
    }
  }, [calculatedWeight]);

  const onSubmit = (data: ISettingsDietForm) => {
    const selectedMealTypeIds = data.meal_types.filter(el => el.checked).map(el => Number(el.value));
    setFormsData({...data, meal_types_ids: selectedMealTypeIds});
    if (profile) {
      const profileCopy = {...profile}
      const profileCopyAttrs = {...profileCopy.data.attributes}
      profileCopyAttrs.energy = data.energy
      profileCopy.data.attributes = profileCopyAttrs
      setProfile(profileCopy)
    }
    const mealTypesPayload = data.meal_types.filter(el => el?.checked).map(el => {
      return {name: el.name.toLowerCase()};
    });
    const payload = {
      physical_activity: data.physical_activity,
      target_days: data.target_days,
      energy: data.energy
    };
    setIsLoading(true);
    updateUser(payload, undefined, mealTypesPayload)
      .then(() => {
        toast.success("Zmiany zostały zapisane.");
      })
      .catch(() => toast.error("Coś poszło nie tak."))
      .finally(() => setIsLoading(false));
  };

  // const updateSize = () => {
  //   // @ts-ignore
  //   if (ref.current instanceof HTMLElement) setChartWidth(ref.current.getBoundingClientRect().width);
  // };
  // window.addEventListener("resize", updateSize);

  useEffect(() => {
    fetchMealTypes().then(res => {
      const mealTypes = res.data.map(mealType => {
          return {
            value: mealType.id,
            name: mealType.attributes.name,
            checked: false
          };
        }
      );
      setMealTypes(mealTypes);
    });
  }, []);

  useEffect(() => {
    const count = watcher.meal_types.filter(el => !!el.checked).length
    if (count < 1) return
    const kcalMax = count * 700
    const kcalMin = count * 200
    if (kcalMin !== kcalRange[0]) setKcalRange([kcalMin, kcalMax])
  }, [watcher])

  useEffect(() => {
    if (mealTypes.length > 1 && formsData.meal_types_ids) {
      setValue("meal_types", mealTypes.map(el => {
        return {...el, checked: !!formsData.meal_types_ids?.includes(Number(el.value))};
      }));
    }
  }, [mealTypes, formsData]);

  // useEffect(() => {
  //   setDimensions(prevState => prevState ? { ...prevState, width: chartWidth } : undefined);
  // }, [chartWidth]);

  // useEffect(() => {
  //   if (ref && ref.current) { // @ts-ignore
  //     setChartWidth(ref.current.getBoundingClientRect().width);
  //   }
  // }, [ref]);

  // useEffect(() => {
  //   const today = new Date();
  //   const newDataset: { date: string, value: number, text?: string }[] = [{
  //     date: formatDate(today, "/"),
  //     value: datasetValuesTemp[0],
  //     text: `${watcher.weight} kg`
  //   }];
  //   for (let i = 1; i <= watcher.target_days; i++) {
  //     const nextDay = new Date();
  //     nextDay.setDate(today.getDate() + i);
  //     newDataset.push({
  //       date: formatDate(nextDay, "/"),
  //       value: datasetValuesTemp[i],
  //       text: i === watcher.target_days ? `${watcher.target_weight} kg` : undefined
  //     });
  //   }
  //   const lastDay = new Date();
  //   lastDay.setDate(lastDay.getDate() + watcher.target_days);

  //   setDataset(newDataset);
  // }, [watcher.target_days]);

  useEffect(() => {
    setLastDay(lastDisabledDay.setDate(lastDisabledDay.getDate() + watcher?.target_days));
  }, [watcher?.target_days]);

  // useEffect(() => {
  //   if (firstRender) return
  //   const timeout = setTimeout(() => {
  //     updateUser({ physical_activity: watcher.physical_activity })
  //       .then(res => setValue("energy", Math.round(res.data.attributes.preferred_energy)))
  //   }, 500);
  //
  //   return () => clearTimeout(timeout);
  // }, [watcher.physical_activity]);

  const handleRangeValue = (name: string, value: number, min: number, max: number) => {
    if (value >= min && value <= max) return value
    return min
  }

  return (
    <div ref={ref}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h2">Przewidywany czas do osiągnięcia celu</Typography>
        <Graph
          settings
          weight={watcher.weight}
          target_weight={watcher.target_weight}
          start_date={formatDate(lastDisabledDay, ".")}
          end_date={lastDay ? formatDate(new Date(lastDay), ".") : formatDate(today, ".")}
        />
        <Box mb={6} mt={2}>
          <Typography variant="label">Ilość dni do osiągnięcia celu</Typography>
          <Controller
            control={control}
            name="target_days"
            render={({field: {value, onChange}}) => (
              <InputRange
                min={1}
                max={300}
                value={value || 1}
                onChange={onChange}
              />
            )}
          />
        </Box>

        <Typography variant="h2">Wartości odżywcze i posiłki</Typography>
        <Box mb={6} mt={3}>
          {rangeFileds(kcalRange).map((field, index) => [1, 2].includes(index) && (
            <Box mb={6} key={field.name}>
              <Typography variant="label">{field.label}</Typography>
              <Controller
                control={control}
                name={field.name as any}
                render={({field: {value, onChange}}) => (
                  <InputRange
                    addSpaces={field.addSpaces}
                    disabled={field.disabled}
                    min={field.min}
                    max={field.max}
                    value={handleRangeValue(field.name, value, field.min, field.max)}
                    step={field.step ? field.step : 1}
                    onChange={onChange}
                  />
                )}
              />
            </Box>
          ))}
        </Box>

        <Row>
          <Col sm={12}>
            <Typography m={3} variant="label">Posiłki w ciągu dnia</Typography>
            {mealTypes && mealTypes.map((el, index) => (
              <Box key={el.value} mb={4}>
                <Checkbox
                  capitalizeLabel
                  label={el.name}
                  {...register(`meal_types.${index}.checked` as const)}
                />
              </Box>
            ))}
          </Col>
        </Row>

        <Flex justifyContent="flex-end">
          <Button variant="primary" disabled={isLoading}>Zapisz zmiany</Button>
        </Flex>
      </form>
    </div>
  );
};
