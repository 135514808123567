import React, { useState } from "react";
import styled from "styled-components";
import { Typography } from "components/Typography";
import { Flex } from "components/Flex";

interface ITab {
  label?: string,
  node: React.ReactNode
}

interface ITabs {
  tabs: ITab[]
}

interface ITabStyle {
  active?: boolean
}

const Tab = styled(Typography).attrs({ variant: "h2" })<ITabStyle>`
  ${({ theme, active }) => `
    opacity: ${active ? "1" : "0.3"};
    height: 27px;
    color: ${theme.colors.neutral.black};
    letter-spacing: -0.2px;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: ${theme.space[6]}px;
    }
  `}
`;

export const Tabs: React.FC<ITabs> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <Flex>
        {tabs.map((tab, index) => tab.label &&
          <Tab active={index === activeTab} onClick={() => setActiveTab(index)} key={tab.label}>{tab.label}</Tab>)
        }
      </Flex>
      <Flex mt={2} key={'tab'+activeTab}>
        {tabs[activeTab].node}
      </Flex>
    </>
  );
};
