import React, { useContext, useState } from "react";

export interface ISubscriptionData {
  id: number;
  paymentId?: string;
  price: number;
  days: Date[];
}

export interface ISubscriptionContext {
  subscription: ISubscriptionData | undefined;
}

export interface ISubscriptionDispatchContext {
  setSubscription: (data: ISubscriptionData | undefined) => void;
}

const SubscriptionContext = React.createContext<ISubscriptionContext | undefined>(undefined);

const SubscriptionDispatchContext = React.createContext<
  ISubscriptionDispatchContext | undefined
>(undefined);

export const SubscriptionContextProvider: React.FC = ({ children }) => {
  const [subscription, setSubscription] = useState<ISubscriptionData>();

  return (
    <SubscriptionContext.Provider value={{ subscription }}>
      <SubscriptionDispatchContext.Provider
        value={{
          setSubscription: (data: ISubscriptionData | undefined) => {
            setSubscription(data);
          },
        }}
      >
        {children}
      </SubscriptionDispatchContext.Provider>
    </SubscriptionContext.Provider>
  );
};

export const useSubscriptionStateContext = () => {
  const context = useContext(SubscriptionContext);
  if (!context) {
    throw new Error(
      "useSubscriptionContext must be inside a SubscriptionContextProvider with a value"
    );
  }
  return context;
};

export const useSubscriptionDispatchContext = () => {
  const context = useContext(SubscriptionDispatchContext);
  if (!context) {
    throw new Error(
      "useSubscriptionDispatchContext must be inside a SubscriptionStateDispatchProvider with a value"
    );
  }
  return context;
};
