import { IFetchSubscriptions } from "services/user";

export const getPreparedSubscriptions = (data: IFetchSubscriptions) => data.data.map(el => {
    const subscription_days: { id: string, date: Date }[] = [];
    for (let day of el.relationships.subscription_days.data) {
      const date = data.included.find(includedEl => includedEl.id === day.id);
      if (date === undefined || date.attributes.date === undefined) continue;
      subscription_days.push({
        id: day.id,
        date: new Date(date.attributes.date)
      });
    }

    return {
      id: el.id,
      price: el.attributes.price,
      price_per_day: el.attributes.price_per_day,
      user_id: el.attributes.user_id,
      active: el.attributes.active,
      cancelled: el.attributes.cancelled,
      subscription_days,
      payments: el.relationships.payments,
      paid: el.relationships.payments.data.find(el => data.included
        .find(included => included.type === "payment" && included.attributes.status === 'completed' && included.id == el.id))
    };
  })
;
