const black = "#000";
const grey = "#c0c5cd";

const tags: { [key: string]: string } = {
  primary: '#2ba76a',
  aquamarine: '#2CCFA3',
  yellow: '#FFC107',
  green: '#2CCF36',
  red: '#cf2c2c',
  orange: '#ef5a24',
  gray: '#787B80',
};

export const theme = {
  colors: {
    primary: "#2ba76a",
    primaryLight: "#c7ecd9",
    secondary: "#0091ff",
    error: "red",
    orange: "#ee5a24",
    neutral: {
      white: "#fff",
      black,
      medium: "#D7DCE3",
      light: "#E6EAEC",
    },
    text: {
      default: black,
      lighter: "#535558",
      light: "#787B80",
    },
    tag: tags
  },
  space: new Array(25).fill(null).map((value, index) => index * 4),
  radii: new Array(5).fill(null).map((value, index) => index * 4),
  breakpoints: [576, 768, 992, 1260],
  inputBorder: `1px solid ${grey}`,
};
