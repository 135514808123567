import styled from "styled-components";
import { Typography } from "./Typography";

export const DishTagContent = styled(Typography)`
  font-size: 12px;
  letter-spacing: 0;
  line-height: 24px;
  ${({ theme }) => `
    padding-right: ${theme.space[3]}px;
    color: ${theme.colors.neutral.black};
  `};
`;
