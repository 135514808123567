import React from "react";
import {Box} from "../Box";
import {Control, Controller, UseFormSetValue} from "react-hook-form";
import {DatePickerInput} from "../DatePickerInput/DatePickerInput";
import {compareDates} from "../../utilities/date";
import Emitter from "utilities/EventEmitter"

interface ICalendarDatePickers {
  control: Control<any>
  lastDisabledDay: Date
  disabledDays?: Date[]
  setDaysChanged: React.Dispatch<React.SetStateAction<boolean>>
  watcher: {
    date_to: string | Date | undefined
    date_from: string | Date | undefined
  }
  setValue: UseFormSetValue<any>
}

export const CalendarDatePickers: React.FC<ICalendarDatePickers> = ({
                                                                      control,
                                                                      lastDisabledDay,
                                                                      setDaysChanged,
                                                                      disabledDays,
                                                                      watcher,
                                                                      setValue
                                                                    }) => {

  return (
    <>
      <Box width="45%" pr={2}>
        <Controller
          control={control}
          name="date_from"
          render={({field: {value, onChange}}) => (
            <DatePickerInput
              label="Pierwszy dzień"
              disabledDays={disabledDays}
              day={value ? new Date(value) : undefined}
              maxDate={(watcher.date_to && watcher.date_from && compareDates(new Date(watcher.date_to), new Date(watcher.date_from))) ? undefined : watcher.date_to}
              minDate={lastDisabledDay}
              disablePastDays
              transparent
              onChange={(date) => {
                if (date && watcher.date_to && compareDates(new Date(date), new Date(watcher.date_to), ">")) {
                  const selectedDate = new Date(date);
                  const oneDay = 86400000;
                  setValue("date_to", new Date(selectedDate.getTime() + oneDay));
                }
                Emitter.emit('CALENDAR_INPUT_DATE_CHANGED', date ? new Date(date) : undefined);
                onChange(date ? new Date(date) : undefined);
                setDaysChanged(true);
              }}
            />
          )}
        />
      </Box>
      <Box width="45%" pl={2}>
        <Controller
          control={control}
          name="date_to"
          render={({field: {value, onChange}}) => (
            <DatePickerInput
              minDate={watcher.date_from}
              disabledDays={disabledDays}
              label="Ostatni dzień"
              day={value ? new Date(value) : undefined}
              disablePastDays
              transparent
              onChange={(date) => {
                Emitter.emit('CALENDAR_INPUT_DATE_CHANGED', date ? new Date(date) : undefined);
                onChange(date ? new Date(date) : undefined);
                setDaysChanged(true);
              }}
            />
          )}
        />
      </Box>
    </>
  );
};
