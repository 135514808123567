import React, { useContext, useState } from "react";
import { IRegisterHealthInterviewFormData } from "views/RegisterHealthInterviewView";
import { ITaxonsForm } from "views/RegisterPreferencesView";
import { IUpdateUser } from "services/user";
import { IRegisterSubscriptionForm } from "views/RegisterSubscriptionView";
import { ISettingsDietForm } from "views/Settings/SettingsDietView";
import { IDeliveryForm } from "../types/delivery";

type ISettingsForms = IRegisterHealthInterviewFormData &
  ISettingsDietForm & ITaxonsForm & IDeliveryForm & IUpdateUser &
  {
    subscription: IRegisterSubscriptionForm,
    taxons: number[]
  };

export interface ISettingsFormContext {
  formsData: Partial<ISettingsForms>;
}

export interface ISettingsFormDispatchContext {
  setFormsData: (value: any, clearData?: boolean) => void;
}

const SettingsFormContext = React.createContext<ISettingsFormContext | undefined>(undefined);

const SettingsFormDispatchContext = React.createContext<ISettingsFormDispatchContext | undefined>(undefined);

const settingsFormValue = localStorage.getItem("settings-form-value");

export const SettingsFormContextProvider: React.FC = ({ children }) => {
  const [formsData, setFormData] = useState<Partial<ISettingsForms>>(
    settingsFormValue ? JSON.parse(settingsFormValue) : undefined
  );

  const setFormsData = (data: Partial<ISettingsForms>, clearData?: boolean) => {
    const settingsData = clearData ? {...data} : { ...formsData, ...data };
    localStorage.setItem(
      "settings-form-value",
      JSON.stringify(settingsData)
    );
    setFormData((prevState) => {
      if (prevState && !clearData) return { ...prevState, ...data };
      return data;
    });
  };

  return (
    <SettingsFormContext.Provider value={{ formsData }}>
      <SettingsFormDispatchContext.Provider
        value={{
          setFormsData
        }}
      >
        {children}
      </SettingsFormDispatchContext.Provider>
    </SettingsFormContext.Provider>
  );
};

export const useSettingsFormStateContext = () => {
  const context = useContext(SettingsFormContext);
  if (!context) {
    throw new Error(
      "useSettingsFormContext must be inside a SettingsFormContextProvider with a value"
    );
  }
  return context;
};

export const useSettingsFormDispatchContext = () => {
  const context = useContext(SettingsFormDispatchContext);
  if (!context) {
    throw new Error(
      "useSettingsFormDispatchContext must be inside a SettingsFormStateDispatchProvider with a value"
    );
  }
  return context;
};
