import { fetchAPI } from "utilities/fetchAPI";

export interface IPostPayment {
  payment: {
    payable_object_id: number
    payable_object_type: "subscription" | "wallet"
    amount?: number
    continue_path: string
    pay_with_wallet?: boolean
  }
}

export interface IPostPaymentResponse {
  data: {
    id: string
    type: string
    attributes: {
      order_id: string
      status: string
      url: string
      payable_type: string
      payable_id: string
    }
  }
}

export interface IFetchPayment {
  data: {
    id: string
    type: string
    attributes: {
      order_id: string
      status: "pending" | "failed" | "completed"
      url: string
    }
  }
}

export const postPayment = async (data: IPostPayment) =>
  await fetchAPI<IPostPaymentResponse>(`${process.env.REACT_APP_API_URL}api/v2/storefront/payments`, {
    method: "POST",
    body: JSON.stringify(data)
  });

export const fetchPayment = async (id: number) =>
  await fetchAPI<IFetchPayment>(`${process.env.REACT_APP_API_URL}api/v2/storefront/payments/${id}`, {
    method: "GET"
  });
