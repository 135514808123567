import styled from "styled-components";
import {
  border,
  BorderProps,
  color,
  ColorProps,
  space,
  layout,
  LayoutProps,
  SpaceProps,
  position,
  PositionProps,
  system
} from "styled-system";

interface Props extends BorderProps, ColorProps, LayoutProps, SpaceProps, PositionProps { transform?: string }

export const Box = styled.div<Props>`
${system({
  transform: true,
})}
  ${border}
  ${color}
  ${layout}
  ${space}
  ${position}
`;
