import React from "react";
import { Modal } from "components/Modal/Modal";
import styled from "styled-components";
import { Typography } from "components/Typography";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Box } from "components/Box";
import { useHistory } from "react-router";

export interface ISettingsModal {
  isOpen: boolean;
  onCancelClick: () => void;
}

const Wrapper = styled.div`
  ${({ theme }) => `
    padding: ${theme.space[8]}px ${theme.space[9]}px ${theme.space[6]}px ${theme.space[10]}px;
  `};
`;

export const SettingsModal: React.FC<ISettingsModal> = ({
  isOpen,
  onCancelClick,
}) => {
  const history = useHistory();
  return (
    <Modal isOpen={isOpen} onCancelClick={onCancelClick}>
      <Wrapper>
        <Typography variant="h3">Twój profil nie jest kompletny. Możesz uzupełnić dane w Ustawieniach swojego konta.</Typography>
        <Flex justifyContent="space-around" mt={4}>
          <Box mx={4}>
            <Button
              onClick={() => {
                history.push("/settings");
                localStorage.setItem("onboarding-alert", 'false');
              }}
              variant="primary"
            >
              Przejdź do ustawień
            </Button>
          </Box>
          <Button onClick={onCancelClick} variant="secondary">Pozostań na stronie</Button>
        </Flex>
      </Wrapper>
    </Modal>
  );
};
