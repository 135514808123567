import { Typography } from "components/Typography";
import { MainLayout } from "layouts/MainLayout";
import { Col, Container, Row } from "react-grid-system";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import React, { useEffect, useState } from "react";
import { Card } from "components/Card";
import styled from "styled-components";
import { Link } from "components/Link";
import { Button } from "components/Button";
import { AdditionalLabel } from "components/AdditionalLabel";
import { OldPrice, Price, PriceContainer } from "components/DishPrice";
import { IconStar } from "components/Icons/IconStar";
import { DishTag } from "components/DishTag";
import { DishTagContent } from "components/DishTagContent";
import { DishTagLabel } from "components/DishTagLabel";
import { DishOffer } from "components/DishOffer/DishOffer";
import { PriceInput } from "components/PriceInput";
import { DishTabs, INutrientState } from "components/DishTabs/DishTabs";
import { useHistory, useParams } from "react-router";
import { fetchProduct, fetchProducts, IFetchProductsSingleItem, IProductVendor } from "../../services/product";
import { fetchVendor, IFetchVendor } from "../../services/vendor";
import { toast } from "react-toastify";

interface INutrient {
  label: string
  value: number
  unit: string
  dishTag?: boolean
}

const StyledCard = styled(Card)`
  height: 100%;
  padding: 23px 37px 31px 40px;
`;
const StyledAdditionalLabel = styled(AdditionalLabel)`
  position: unset;
  display: inline-block;
  margin-right: 15px;
`;
const PriceContainerCustom = styled(PriceContainer)`
  justify-content: flex-start;
`;
const Tag = styled.div<{ color: string }>`
  ${({ theme, color }) => `
    background-color: ${theme.colors.tag[color]}
  `};
  display: inline-block;
  margin-right: 15px;
  border-radius: 12px;
  padding: 0 12px;
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 24px;
`;

const StyledImage = styled.img`
  border-radius: 8px;
  width: 100%;
  object-fit: contain;
`;

export const DishSingleView: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [itemCount, setItemCount] = useState<number>(1);
  const [product, setProduct] = useState<IFetchProductsSingleItem | null>(null);
  const [vendor, setVendor] = useState<IProductVendor | undefined>(undefined);
  const [nutrients, setNutrients] = useState<INutrient[]>([]);
  const [allergens, setAllergens] = useState<{ label: string }[]>([]);
  const [ingredients, setIngredients] = useState<string>("");
  const [products, setProducts] = useState<IFetchProductsSingleItem["data"][]>([]);

  const votingStars = [1, 2, 3, 4, 5];

  const additionalLabelVariants: { [key: string]: string } = {
    new: "NOWOŚĆ",
    promotion: "PROMOCJA -30%"
  };

  const changeItemCount = (num: number): void => {
    if (num < 1 || num > 99) return;
    setItemCount(num);
  };

  useEffect(() => {
    fetchProduct(Number(id), [
      "default_variant",
      "variants",
      "option_types",
      "product_properties",
      "taxons",
      "vendor",
      "images"
    ]).then((response) => {
      setProduct(response);
      const vendor = response.included.find(el => el.type === "vendor");
      setVendor(vendor);
      setIngredients(response.data.attributes.ingredients.join(", "));
      setAllergens(response.data.attributes.allergens.map(el => {
        return { label: el }
      }))
      setNutrients([
        { label: "Energia: ", value: response.data.attributes.calories, unit: "kcal", dishTag: true },
        { label: "Białko: ", value: response.data.attributes.proteins, unit: "g", dishTag: true },
        { label: "Węglowodany: ", value: response.data.attributes.carbohydrates, unit: "g", dishTag: true },
        { label: "Tłuszcz: ", value: response.data.attributes.fats, unit: "g", dishTag: true },
        { label: "Błonnik:", value: response.data.attributes.fiber, unit: "g" },
        { label: "Cukry:", value: response.data.attributes.sugars, unit: "g" },
        { label: "Sól:", value: response.data.attributes.salt, unit: "g" },
        { label: "NKT:", value: response.data.attributes.nkt, unit: "g" }
      ]);

      if (vendor) {
        fetchProducts({ vendors_ids: Number(vendor.id), per_page: 4 })
          .then((response) => {
            setProducts(response.data);
          })
          .catch(err => {
            toast.error("Wystąpił błąd podczas pobierania produktów.");
          });
      }
    });
  }, [id]);

  return (
    <MainLayout>
      {product &&
        <Container>
          <Row>
            <Col xs={4}>
              <StyledImage src="https://picsum.photos/300/400" alt="desc" />
            </Col>
            <Col xs={8}>
              <StyledCard>
                {/*<div>*/}
                {/*  {product.data.attributes.new? &&*/}
                {/*  <StyledAdditionalLabel variant="new">*/}
                {/*    {additionalLabelVariants.new}*/}
                {/*  </StyledAdditionalLabel>*/}
                {/*  }*/}
                {/*  <StyledAdditionalLabel variant="promotion">*/}
                {/*    {additionalLabelVariants.promotion}*/}
                {/*  </StyledAdditionalLabel>*/}
                {/*  <Tag color="aquamarine">Bio</Tag>*/}
                {/*  <Tag color="yellow">Gluten free</Tag>*/}
                {/*  <Tag color="green">Wege</Tag>*/}
                {/*</div>*/}

                <Box my={3}>
                  <Typography variant="h1">{product.data.attributes.name}</Typography>
                </Box>
                {/*
                <Flex>
                  <Box mr={3}>
                    <Typography>{product.data.attributes.weight}g</Typography>
                  </Box>
                </Flex> */}

                <Flex>
                  <Box mr={3}>
                    <Typography>{product.data.attributes.weight}g</Typography>
                  </Box>
                  {vendor && <Link onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    history.push(`/vendor/${vendor.id}`);
                  }} to={"/vendor/" + vendor.id}>{vendor.attributes.name}</Link>}
                </Flex>

                <PriceContainerCustom>
                  {product.data.attributes.compare_at_price &&
                    <OldPrice>{product.data.attributes.compare_at_price.replace(".", ",")} zł</OldPrice>}
                  {product.data.attributes.price && <Price>{product.data.attributes.price.replace(".", ",")} zł</Price>}
                  <Box ml={4}>
                    <Flex>
                      <Box position="relative">
                        <Box position="absolute" top="-85%" left="5px" opacity="0.5">
                          <Typography fontSize={12}>Średnia ocena</Typography>
                        </Box>
                        {votingStars.map(el =>
                          <Box key={el} display="inline-block" mx={1}>
                            <IconStar active={el <= Number(product.data.attributes.avarage_rating ? product.data.attributes.avarage_rating : 0)} />
                          </Box>
                        )}
                      </Box>
                      <Typography>{`(${product.data.attributes.rating_count})`}</Typography>
                    </Flex>
                  </Box>
                </PriceContainerCustom>

                <Flex my={2}>
                  {/*<Box width="80px" mr={3}>*/}
                  {/*  <PriceInput count={itemCount} changeValue={changeItemCount} />*/}
                  {/*</Box>*/}
                  {/*<Box width="75px" mr={3}>*/}
                  {/*  <Button fullWidth variant="primary">*/}
                  {/*    Zamów*/}
                  {/*  </Button>*/}
                  {/*</Box>*/}
                  {/*<Box width="120px">*/}
                  {/*  <Button fullWidth variant="primary">*/}
                  {/*    Dodaj na stałe*/}
                  {/*  </Button>*/}
                  {/*</Box>*/}
                </Flex>

                <div>
                  {nutrients.filter(el => el.dishTag).map((el, index) =>
                    <DishTag key={index} mb={2} mr={3}>
                      <DishTagLabel mr={1}>{el.label}</DishTagLabel>
                      <DishTagContent>{el.value + el.unit}</DishTagContent>
                      {/* todo icon first item */}
                    </DishTag>
                  )}
                </div>

                <Box mt={2}>
                  <DishTabs
                    ingredients={ingredients}
                    nutrients={nutrients as unknown as INutrientState[]}
                    description={product.data.attributes.description}
                    allergens={allergens}
                    preparationAndStorage={product.data.attributes.preparation_and_storage}
                  />
                </Box>
              </StyledCard>
            </Col>
          </Row>
          {/*<Flex justifyContent="space-between" alignItems="center" mt={3} mb={1}>*/}
          {/*  <h2>Podobne dania</h2>*/}
          {/*  <Link to="#">Zobacz wiecej</Link>*/}
          {/*</Flex>*/}
          {/*<Flex justifyContent="space-between" alignItems="center" my={1}>*/}
          {/*  {[1, 2, 3, 4].map((e) => {*/}
          {/*    return (*/}
          {/*      <DishOffer*/}
          {/*        horizontal={true}*/}
          {/*        key={e}*/}
          {/*        showOldPrice={e === 3}*/}
          {/*      />*/}
          {/*    );*/}
          {/*  })}*/}
          {/*</Flex>*/}
          <Flex justifyContent="flex-start" alignItems="center" mt={5} mb={1}>
            <h2>Inne od tego producenta</h2>
            {/*<Link to="#">Zobacz wiecej</Link>*/}
          </Flex>
          <Flex justifyContent="space-between" alignItems="center" my={1}>
            {products.map((e) => {
              return (
                <DishOffer
                  horizontal={true}
                  key={e.id}
                  dish={e}
                // optionsButton={{
                //   label: "Wybierz",
                //   onClick: () => console.log(e.id)
                // }}
                />
              );
            })}
          </Flex>
        </Container>
      }
    </MainLayout>
  );
};
