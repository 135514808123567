import React, {useState} from "react";
import styled from "styled-components";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {IProduct} from "types/product";
import {Card} from "components/Card";
import {Box} from "components/Box";
import {Flex} from "components/Flex";
import {Typography} from "components/Typography";
import {Input} from "components/Input";
import {Button} from "components/Button";
import {Link} from "components/Link";
import {AdditionalLabel} from "components/AdditionalLabel";
import {OldPrice, Price, PriceContainer} from "components/DishPrice";
import {DishTag} from "components/DishTag";
import {DishTagLabel} from "components/DishTagLabel";
import {DishTagContent} from "components/DishTagContent";
import {PriceInput} from "components/PriceInput";
import {IconStar} from "components/Icons/IconStar";
import {IconHeart} from "components/Icons/IconHeart";
import {useHistory} from "react-router";
import {FaIcon} from "components/Icons/FaIcon";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {IProductsVendor} from "services/product";

interface IDishOffer {
  horizontal?: boolean;
  dishId?: number;
  vendor?: IProductsVendor;
  userRating?: boolean;
  dish: IProduct["data"];
  optionsButton?: { label: string; onClick: (args: any) => void };
  deleteIcon?: boolean;
  onDelete?: (id: number) => void;
  onRate?: (id: number, rating: number) => void;
  onLike?: (id: number) => void;
  currentUserRating?: number | null;
}

const ImageContainer = styled.div<{ fullWidth?: boolean }>`
  position: relative;
  box-sizing: border-box;
  height: 144px;
  border: 1px solid #f3f4f6;
  display: flex;
  align-items: center;
  ${({theme, fullWidth}) => `
    width: ${fullWidth ? "100%" : "136px"};
    border-radius: ${theme.radii[2]}px;
  `}
`;

export const IconDelete = styled(FaIcon).attrs({
  icon: faTrashAlt
})`
  position: absolute;
  top: 13px;
  right: 10px;
  color: #c0c5cd;
`;

const StyledImage = styled.img`
  border-radius: 8px;
  max-height: 90%;
  width: 100%;
  object-fit: contain;
  ${({theme}) => `
    border-radius: ${theme.radii[2]}px;
  `}
`;

const InputContainer = styled(Box) <{ isHorizontal?: boolean }>`
  position: relative;
  ${({isHorizontal}) => `
    ${isHorizontal ? ` width: 100px; ` : ` width: 100%; `}
    & > ${Input} {
      width: inherit;
    }
  `}
`;

const StyledAdditionalLabel = styled(AdditionalLabel)`
  position: absolute;
  top: 8px;
  left: 8px;
  margin-right: 15px;
`;

const IconsContainer = styled(Flex) <{ toBeRated?: boolean }>`
  display: inline-flex;
  align-content: center;

  .iconsList:hover ${IconStar} {
    color: ${({toBeRated}) => toBeRated ? "#FFC107 !important" : "auto"}
  }
;

  .iconsList ${IconStar}:hover ~ ${IconStar} {
    color: ${({toBeRated}) => toBeRated ? "rgba(192, 197, 205, 0.5) !important" : "auto"}
  }
`;

export const DishOffer: React.FC<IDishOffer> = ({
                                                  horizontal = false,
                                                  dish,
                                                  deleteIcon,
                                                  vendor,
                                                  onRate,
                                                  onLike,
                                                  onDelete,
                                                  userRating,
                                                  currentUserRating = null,
                                                  optionsButton
                                                }) => {
  const history = useHistory();
  const votingStars = [1, 2, 3, 4, 5];
  const [userStars, setUserStars] = useState<number>(0);

  const dishTagData: { label: string, value: number, unit: string }[] = [
    {label: "E: ", value: dish.attributes.calories, unit: "kcal"},
    {label: "P: ", value: dish.attributes.proteins, unit: "g"},
    {label: "W: ", value: dish.attributes.carbohydrates, unit: "g"},
    {label: "T: ", value: dish.attributes.fats, unit: "g"}
  ];
  const [itemCount, setItemCount] = useState<number>(1);
  const additionalLabelVariants: { [key: string]: string } = {
    new: "NOWOŚĆ",
    promotion: "PROMOCJA"
  };

  const changeItemCount = (num: number): void => {
    if (num < 1 || num > 99) return;
    setItemCount(num);
  };

  return (
    <Box style={{cursor: "pointer"}} width={horizontal ? "276px" : "100%"}>
      <Card onClick={() => history.push(`/products/item/${dish.id}`)}>
        <Box p={2} pr={horizontal ? 2 : 4}>
          <Flex
            justifyContent="space-between"
            flexDirection={horizontal ? "column" : "row"}
          >
            <Flex flexDirection={horizontal ? "column" : "row"}>
              <Box mr={horizontal ? 0 : 4}>
                <ImageContainer fullWidth={horizontal}>
                  <StyledImage
                    src="https://cdn.discordapp.com/attachments/859009509522931713/875006070068764722/DIABETICARE.png"
                    alt="desc"
                  />
                  {/* todo ask */}
                  {/*{dish.promotionable && dish.promotionable !== undefined && (*/}
                  {/*  <StyledAdditionalLabel variant="promotion">*/}
                  {/*    {additionalLabelVariants["promotion"]}*/}
                  {/*  </StyledAdditionalLabel>*/}
                  {/*)}*/}
                </ImageContainer>
              </Box>
              <Box mr={horizontal ? 0 : 4} px={horizontal ? 4 : 0}>
                <Flex mt={5} alignItems="flex-start">
                  <Typography>
                    {dish.attributes.name}
                  </Typography>
                </Flex>
                {vendor &&
                  <Typography
                    pt={1}
                    onClick={(e) => {
                      e.stopPropagation()
                      history.push(`/vendor/${vendor.id}`)
                    }}
                    variant="caption"
                  >
                    {dish.attributes.brand_name}
                  </Typography>
                }
                <Box mb={4}>
                  <IconsContainer toBeRated={userRating}>
                    <Typography mr={3}>{dish.attributes.weight}g</Typography>
                    <Box className="iconsList" onClick={(e) => e.stopPropagation()} position="relative">
                      {/*<Box position="absolute" top="-85%" left="5px" opacity="0.5">*/}
                      {/*<Typography fontSize={12}>{userRating ? "Twoja ocena" : "Średnia ocena"}</Typography>*/}
                      {/*</Box>*/}
                      {votingStars.map((el, index) =>
                        <React.Fragment key={index}>
                          {userRating ? (
                            <>
                              {onRate &&
                                <IconStar
                                  key={el}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    onRate(Number(dish.id), el);
                                    setUserStars(el);
                                  }}
                                  active={el <= (currentUserRating ? currentUserRating : 0)}
                                  cursorPointer
                                />
                              }
                            </>
                          ) : (
                            <IconStar active={el <= Number(dish.attributes.avarage_rating)} key={el}/>
                          )}
                        </React.Fragment>
                      )}
                    </Box>
                    {!userRating &&
                      <Typography ml={2}>{`(${dish.attributes.rating_count})`}</Typography>
                    }
                    {onLike &&
                      <Box display="inline-block" mr={horizontal ? 0 : 5} ml={3}>
                        <IconHeart
                          active={dish.attributes.current_user_favourite_product}
                          onClick={(e) => {
                            e.stopPropagation();
                            onLike(Number(dish.id));
                          }}
                        />
                      </Box>
                    }
                  </IconsContainer>
                  {/*{vendor && <Link onClick={(e) => {*/}
                  {/*  e.stopPropagation();*/}
                  {/*  e.preventDefault();*/}
                  {/*  history.push(`/vendor/${vendor.id}`);*/}
                  {/*}} to={"/vendor/" + vendor.id}>{vendor.attributes.name}</Link>}*/}
                </Box>
                <div>
                  {dishTagData.map((el, index) =>
                    <DishTag key={index} mb={2} mr={3}>
                      <DishTagLabel mr={1}>{el.label}</DishTagLabel>
                      <DishTagContent>{el.value + el.unit}</DishTagContent>
                      {/* todo icon first item */}
                    </DishTag>
                  )}
                </div>
              </Box>
            </Flex>
            <Box pr={4} px={horizontal ? 4 : 0}>
              <PriceContainer>
                {/*{dish.attributes.compare_at_price &&*/}
                {/*<OldPrice>{dish.attributes.compare_at_price.replace(".", ",")} zł</OldPrice>}*/}
                {dish.attributes.price && <Price>{dish.attributes.price.replace(".", ",")} zł</Price>}
                {/*<Price>{dish.price ? dish.price.replace('.', ",") : "-"} zł</Price>*/}
              </PriceContainer>
              <Flex
                mt={1}
                flexDirection={horizontal ? "row" : "column"}
                justifyContent="space-between"
              >
                <InputContainer isHorizontal={horizontal} mb={2}>
                  {/* tmp */}
                  <div style={{visibility: "hidden"}}>
                    <PriceInput width={deleteIcon ? "65%" : "100%"} onClick={(e) => e.stopPropagation()}
                                count={itemCount}
                                changeValue={changeItemCount}/>
                  </div>
                  {(deleteIcon && onDelete) &&
                    // @ts-ignore
                    <IconDelete onClick={(e) => {
                      e.stopPropagation();
                      onDelete(Number(dish.id));
                    }}
                    />
                  }
                </InputContainer>
                {optionsButton && (
                  <Box width={horizontal ? "45%" : "100%"}>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        optionsButton.onClick(dish);
                      }}
                      fullWidth
                      variant="primary"
                    >
                      {optionsButton.label}
                    </Button>
                  </Box>
                )}
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Card>
    </Box>
  );
};
