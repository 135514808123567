import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import React, {useEffect, useState} from "react";
import { Box } from "components/Box";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import {confirmEmail, setNewPassword} from "services/user";
import { Typography } from "components/Typography";
import { LoginRegisterLayout } from "layouts/LoginRegisterLayout";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import {TextField} from "../components/TextField";
import {changePasswordSchema} from "../schemas/register";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation } from "react-router-dom";
import * as queryString from "querystring";

export interface ISetNewPassword {
  password: string,
  password_confirmation: string
  reset_password_token: string
}

export const ConfirmNewEmailView: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation()
  const values = queryString.parse(search)
  const token = values['?token'] as string

  const handleConfirmEmail = async () => {
    try {
      await confirmEmail(token);
      toast.success("E-mail został aktywowany.");
      history.push("/login");
    } catch (error) {
      toast.error("Coś poszło nie tak.");
      history.push("/login");
    } finally {
    }
  }

  useEffect(() => {
    if (!token) {
      history.push("/login");
      return
    }
    handleConfirmEmail()
  }, [token]);

  return <></>;
};
