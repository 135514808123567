import React, { LiHTMLAttributes as DivHTMLAttributes } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

interface StepI extends DivHTMLAttributes<HTMLDivElement> {
  step: number;
  active?: boolean;
  disable?: boolean;
}
export interface Step {
  active?: boolean;
  disable?: boolean;
}

export interface Props {
  steps: Step[];
}

const CheckmarkIcon = styled(FontAwesomeIcon).attrs({ icon: faCheck })`
  ${({ theme }) => `
        color: ${theme.colors.neutral.white};
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      `}
`;
const Step = styled.div<StepI>`
  ${({ theme, step, active, disable }) => `
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin: 0 4px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: ${theme.colors.primary};
    font-size: 12px;
    ${CheckmarkIcon} {
          display: block;
        }
      ${
        active &&
        `
        ${CheckmarkIcon} {
              display: none;
            }
          &::after {
            content: "${step}";
            color: ${theme.colors.neutral.white};
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        `
      };
      ${
        disable
          ? `
          background-color: ${theme.colors.neutral.medium};
          ${CheckmarkIcon} {
              display: none;
            }
          &::after {
            content: "${step}";
            color: ${theme.colors.neutral.white};
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        `
          : null
      }
  `}
`;
const Separator = styled.div<{ disable?: boolean }>`
  ${({ theme, disable }) => `
    background-color: ${theme.colors.primary};
    display: inline-block;
    vertical-align: middle;
    width: 25px;
    height: 2px;
    ${
      disable &&
      `
      background-color: ${theme.colors.neutral.medium};
    `
    }
  `}
`;

export const Stepper: React.FC<Props> = ({ steps, ...props }) => {
  return (
    <>
      {steps.map(({ active, disable }, index) => (
        <React.Fragment key={index}>
          <Step
            key={index}
            step={index + 1}
            active={active}
            disable={disable}
            {...props}
          >
            <CheckmarkIcon />
          </Step>
          {index < steps.length - 1 && <Separator disable={disable} />}
        </React.Fragment>
      ))}
    </>
  );
};
