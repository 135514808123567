import {Flex} from "components/Flex";
import React, {useState} from "react";
import styled from "styled-components";
import {Typography} from "../Typography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faTimes} from "@fortawesome/free-solid-svg-icons";
import {rgba} from "utilities/rgba";

interface IIconDown {
  expanded?: boolean
}

export interface IRow {
  rowData: React.ReactNode[]
  expandedData?: React.ReactNode
  additionalData?: any
}

interface ITable {
  expandedRows?: boolean,
  deleteRows?: boolean,
  onDeleteRow?: (index: number) => void;
  delimiters: string[],
  columnNames: (string | null)[],
  rows: IRow[]
}

interface IStyledDelimiter {
  width: string
}

interface IStyledRowItem extends IStyledDelimiter {
  expanded?: boolean
}

const FaIcon = styled(FontAwesomeIcon)`
  align-self: center;
  cursor: pointer;
`;

const IconDown = styled(FaIcon).attrs({icon: faChevronDown})<IIconDown>`
  color: #c0c5cd;
  ${({expanded}) => `
    transform: rotate(${expanded ? "180deg" : "0"});
  `}
`;
const IconRemove = styled(FaIcon).attrs({icon: faTimes})`
  color: #c0c5cd;
  font-size: 12px;
`;

const boxShadow = `0 2px 16px -8px ${rgba("#6D7278", 0.75)}`;
const StyledRow = styled.tr<{ transparent?: boolean, expandedRows?: boolean }>`
  ${({theme, transparent, expandedRows}) => `
    box-shadow: ${transparent ? "unset" : boxShadow};
    padding: ${theme.space[2]}px;
    border-radius: ${theme.radii[4]}px;
    height: ${transparent ? 0 : "56px"};
    background-color: ${transparent ? "transparent" : theme.colors.neutral.white};
    & > th {
      ${transparent && "padding: 0;"}
    }
    & > th:first-child {
      padding-left: ${theme.space[expandedRows ? 2 : 6]}px;
    }
    & > td:first-child {
      padding-left: ${theme.space[expandedRows ? 2 : 6]}px;
    }
  `};
`;

const StyledRowHeader = styled.th<IStyledDelimiter>`
  ${({theme, width}) => `
    width: ${width};
    padding-top: ${theme.space[2]}px;
    padding-bottom: ${theme.space[2]}px;
    &:first-child {
      border-bottom-left-radius: ${theme.radii[4]}px;
      border-top-left-radius: ${theme.radii[4]}px;
      padding-left: ${theme.space[2]}px;
    }
    &:last-child {
      border-bottom-right-radius: ${theme.radii[4]}px;
      border-top-right-radius: ${theme.radii[4]}px;
      padding-right: ${theme.space[2]}px;
    }
  `}
`;

const StyledRowItem = styled.td<IStyledRowItem>`
  ${({theme, width, expanded}) => `
    padding-top: ${theme.space[2]}px;
    padding-bottom: ${theme.space[2]}px;
    width: ${width};
    &:first-child {
      border-bottom-left-radius: ${expanded ? "unset" : theme.radii[4] + "px"};
      border-top-left-radius: ${theme.radii[4]}px;
      padding-left: ${theme.space[2]}px;
    }
    &:last-child {
      border-bottom-right-radius: ${expanded ? "unset" : theme.radii[4] + "px"};
      border-top-right-radius: ${theme.radii[4]}px;
      padding-right: ${theme.space[2]}px;
    }
  `}
`;

const ExpandedContent = styled.tr`
  clip-path: inset(-1px -20px -20px -20px);
  box-shadow: 0 2px 16px -8px ${rgba("#6D7278", 0.75)};
  ${({theme}) => `
    border-radius: ${theme.radii[4]}px;
    background-color: ${theme.colors.neutral.white};
    & > td {
      padding: ${theme.space[6]}px ${theme.space[8]}px;
      border-bottom-left-radius: ${theme.radii[4]}px;
      border-bottom-right-radius: ${theme.radii[4]}px;
    }
  `}
`;

const StyledColumnName = styled(Typography).attrs({variant: "label"})`
  text-align: start;
  padding: 0;
`;

const Spacer = styled.tr`
  ${({theme}) => `
    height: ${theme.space[2]}px;
  `}
`;

const StyledTable = styled.table.attrs({width: "100%", cellSpacing: "0"})`
  border-collapse: collapse;
`;

export const Table: React.FC<ITable> = ({onDeleteRow, expandedRows, delimiters, columnNames, rows, deleteRows}) => {
  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([]);
  let updatedDelimiters = expandedRows ? ["40px", ...delimiters] : delimiters;
  updatedDelimiters = deleteRows ? [...updatedDelimiters, "56px"] : updatedDelimiters;
  const updatedColumnNames = expandedRows ? [null, ...columnNames] : columnNames;

  const handleExpandRow = (rowIndex: number) => {
    setExpandedIndexes(prevState => prevState.includes(rowIndex) ?
      prevState.filter(e => e !== rowIndex) : [...prevState, rowIndex]);
  };

  return (
    <>
      <StyledTable>
        <tbody>
        <StyledRow expandedRows={expandedRows} transparent>
          {updatedColumnNames.map((name, index) =>
            <StyledRowHeader width={updatedDelimiters[index]} key={index + `${name}`}>
              <StyledColumnName>{name}</StyledColumnName>
            </StyledRowHeader>
          )}
        </StyledRow>
        {rows.map((row, index) =>
          <React.Fragment key={index}>
            {row &&
              <>
                <Spacer/>
                <StyledRow expandedRows={expandedRows} key={index + expandedIndexes.length}>
                  {expandedRows &&
                    <StyledRowItem key={index + expandedIndexes.length + 'rowItem'} expanded={expandedIndexes.includes(index) ? true : undefined} width="40px">
                      <Flex justifyContent="center" pr={2} onClick={() => handleExpandRow(index)}>
                        <IconDown expanded={expandedIndexes.includes(index) ? true : undefined}/>
                      </Flex>
                    </StyledRowItem>}
                  {row.rowData.map((data, dataIndex) =>
                    <StyledRowItem expanded={expandedIndexes.includes(index) ? true : undefined}
                                   width={updatedDelimiters[dataIndex + 1]}
                                   key={index + 'rowItem' + dataIndex}>{data}</StyledRowItem>
                  )}
                  {(deleteRows && onDeleteRow) &&
                    <StyledRowItem key={index + 'rowItem'} expanded={expandedIndexes.includes(index) ? true : undefined} width="40px">
                      <Flex justifyContent="center" px={2}>
                        <IconRemove onClick={() => onDeleteRow(index)}/>
                      </Flex>
                    </StyledRowItem>}
                </StyledRow>
                {expandedIndexes.includes(index) &&
                  <ExpandedContent>
                    <td colSpan={row.rowData.length + 1 + (expandedRows ? 1 : 0)}>
                      {row.expandedData}
                    </td>
                  </ExpandedContent>
                }
              </>
            }
          </React.Fragment>
        )}
        </tbody>
      </StyledTable>
    </>
  );
};
