import { Box } from "components/Box";
import { Typography } from "components/Typography";
import { Redirect, useHistory } from "react-router";
import { Row, Col } from "react-grid-system";
import { fetchUser } from "services/user";
import React, { useEffect, useState } from "react";
import { Router } from "components/Router";
import { Route } from "types/route";
import { MainLayout } from "layouts/MainLayout";
import { Card } from "components/Card";
import { Menu } from "components/Menu/Menu";
import { useSettingsFormDispatchContext, useSettingsFormStateContext } from "contexts/SettingsFormContext";
import { toast } from "react-toastify";

export const SettingsView: React.FC<{ routes: Array<Route> }> = ({ routes: subRoutes }) => {
  const history = useHistory();
  const [userFetched, setUserFetched] = useState<boolean>(false);
  const { setFormsData } = useSettingsFormDispatchContext();
  const { formsData } = useSettingsFormStateContext();

  const items = [
    {
      label: "Konto i dane osobowe",
      path: "/settings/profile"
    },
    {
      label: "Wywiad zdrowotny",
      path: "/settings/health-interview"
    },
    {
      label: "Wartości odżywcze/dieta",
      path: "/settings/diet"
    },
    {
      label: "Preferencje żywieniowe",
      path: "/settings/preferences"
    },
    {
      label: "Dostawa",
      path: "/settings/delivery"
    }
    // {
    //   label: "Płatność",
    //   path: "/settings/payment"
    // }
  ];

  useEffect(() => {
    setFormsData({}, true)
    fetchUser()
      .then(response => {
        setFormsData({
          ...response.data.attributes,
          taxons: response.data.relationships.taxons.data.map(el => Number(el.id)),
          meal_types_ids: response.data.relationships.meal_types.data.map(el => Number(el.id))
        })
        setUserFetched(true)
      })
      .catch(err => {
        toast.error("Coś poszło nie tak.");
      })
  }, [])


  return (
    <MainLayout>
      {history.location.pathname === "/settings" && <Redirect to={"/settings/profile"} />}
      <Box mb={2}>
        <Typography variant="h1">Ustawienia</Typography>
      </Box>
      <Row>
        <Col xs={3}>
          <Menu items={items} />
        </Col>
        <Col xs={9}>
          <Card py={10} px={20}>
            {userFetched && <Router routes={subRoutes} />}
          </Card>
        </Col>
      </Row>
    </MainLayout>
  );
};
