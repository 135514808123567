export const getDisabledDays = (dateToCheck?: Date) => {
  const today = dateToCheck ? dateToCheck : new Date();
  const oneMinute = 60000;
  const oneHour = 3600000;
  const utcTime = today.getTime() + (today.getTimezoneOffset() * oneMinute);
  const timeOffset = oneHour * 1;
  const PolandTime = new Date(utcTime + timeOffset);
  const disableNextDays = PolandTime.getHours() >= 15;
  const days = [
    disableNextDays ? 2 : 1,
    disableNextDays ? 2 : 1,
    disableNextDays ? 2 : 1,
    disableNextDays ? 2 : 1,
    disableNextDays ? 3 : 1,
    disableNextDays ? 3 : 2,
    disableNextDays ? 3 : 2
  ];

  const oneDay = 86400000;
  const disabledNextDays = [];
  for (let i = 0; i <= days[new Date(PolandTime).getDay()]; i++) {
    disabledNextDays.push(new Date(PolandTime.getTime() + (i * oneDay)));
  }
  return disabledNextDays
};

export const getPolandUtcTime = (date: Date) => {
  const oneMinute = 60000;
  const oneHour = 3600000;
  const utcTime = date.getTime() + (date.getTimezoneOffset() * oneMinute);
  const timeOffset = oneHour * 1;
  return new Date(utcTime + timeOffset);
}

export const getPrefPriceForSubscription = (energy: number) => {
  if (energy > 3000) {
    const diff = (energy - 3000) / 200
    return [Math.round(80 + diff * 1.5), Math.round(100 + diff * 2)]
  }

  const data: {[key: number]: [number, number]} = {
    200: [10, 20],
    300: [15, 25],
    400: [20, 30],
    500: [25, 35],
    600: [30, 40],
    700: [35, 45],
    800: [40, 50],
    900: [45, 55],
    1000: [50, 60],
    1100: [52, 62],
    1200: [53, 64],
    1300: [55, 66],
    1400: [56, 68],
    1500: [58, 70],
    1600: [59, 72],
    1700: [61, 74],
    1800: [62, 76],
    1900: [64, 78],
    2000: [65, 80],
    2100: [67, 82],
    2200: [68, 84],
    2300: [70, 86],
    2400: [71, 88],
    2500: [73, 90],
    2600: [74, 92],
    2700: [76, 94],
    2800: [77, 96],
    2900: [79, 98],
    3000: [80, 100],
  }

  return data[Math.round(energy / 100) * 100]
}

export const getLastDisabledDay = () => {
  const disabledDays = getDisabledDays()
  return new Date(new Date().setDate(disabledDays[disabledDays.length - 1].getDate() + 1));
}
