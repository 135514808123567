import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styled from "styled-components";
import { Flex } from "components/Flex";

interface IPagination {
  current: number;
  pages: number;
  onPageClick: (pageNumber: number) => void;
}

interface IPageNumber {
  active?: boolean;
}

const IconPrevious = styled(FontAwesomeIcon).attrs({ icon: faAngleLeft })`
  color: "#C0C5CD";
`;
const IconNext = styled(FontAwesomeIcon).attrs({ icon: faAngleRight })`
  color: "#C0C5CD";
`;

const Wrapper = styled.div`
  margin-left: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
`;

const ItemContainer = styled(Flex)<IPageNumber>`
  ${({ theme, active }) => `
    background-color: ${active ? "#2BA76A" : "transparent"};
    border-radius: ${theme.radii[2]}px;
    color: ${active ? "#F3F4F6" : "#C0C5CD"};
    font-weight: ${active && "bold"};
  `};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  margin: 0 2px;
  height: 32px;
  width: 32px;
`;

export const Pagination: React.FC<IPagination> = ({
                                                    current,
                                                    pages,
                                                    onPageClick
                                                  }) => {
  const previous = current - 1 < 1 ? false : current - 1;
  const next = current + 1 > pages ? false : current + 1;

  const pagination = (current: number, total: number) => {
    const list = [];
    let pageLimit = 5;
    let upperLimit, lowerLimit;
    let currentPage = lowerLimit = upperLimit = Math.min(current, total);

    for (let b = 1; b < pageLimit && b < total;) {
      if (lowerLimit > 1) {
        lowerLimit--;
        b++;
      }
      if (b < pageLimit && upperLimit < total) {
        upperLimit++;
        b++;
      }
    }

    for (let i = lowerLimit; i <= upperLimit; i++) {
      list.push(i);
    }
    return list;
  };


  const mapPageNumber = () => {
    let data = pagination(current, pages);
    data = data.filter(item => item !== 0);
    if (!data.includes(1)) {
      if (data[0] > 2) data.unshift(0);
      data.unshift(1);
    }
    if (!data.includes(pages)) {
      if (data[data.length - 1] < pages - 1) data.push(0);
      data.push(pages);
    }

    return data.map(number =>
      <ItemContainer
        onClick={() => {
          number !== 0 ? onPageClick(number) : null;
        }}
        key={Math.random() + number}
        active={number === current}
      >
        {number === 0 ? "..." : number}
      </ItemContainer>
    );
  };

  return (
    <>
      {pages > 1 && (
        <Wrapper>
          {previous && (
            <ItemContainer
              onClick={() => {
                onPageClick(previous);
              }}
            >
              <IconPrevious />
            </ItemContainer>
          )}
          {mapPageNumber()}
          {next && (
            <ItemContainer
              onClick={() => {
                onPageClick(next);
              }}
            >
              <IconNext />
            </ItemContainer>
          )}
        </Wrapper>
      )}
    </>
  );
};
