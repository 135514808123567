import { Redirect, Route } from "react-router-dom";
import { Route as RouteProps } from "types/route";

export const RouteWithSubRoutes = (route: RouteProps) => {
  const authenticated = !!localStorage.getItem("boofet-app-token")

  return (
    <Route
      path={route.path}
      render={(props) =>
        route.redirect ? (
          <Redirect to={route.redirect} />
        ) : route.private ? (
          authenticated ? (
            route.component && (
              <route.component {...props} routes={route.routes} />
            )
          ) : (
            <Redirect to="/login" />
          )
        ) : (
          route.component && (
            <route.component {...props} routes={route.routes} />
          )
        )
      }
    />
  );
};
