import React from "react";
import styled from "styled-components";
import {Box} from "./Box";
import {Grid} from "./Grid";
import {Typography} from "./Typography";
import {SpeechBubble} from "./SpeechBubble";
import {Flex} from "./Flex";

interface Item {
  label: string;
  value: string | React.ReactNode;
  info?: string
  isBold?: boolean
}

export interface IDataGridProps {
  data: Array<Item | Array<Item>>;
}

const Root = styled.div`
  ${({theme}) => `
    margin-bottom: -${theme.space[3]}px;
  `};
  .tooltip-icon {
    margin: 0;
  }
`;

const Item = (item: Item) => (
  <>
    {item.info
      ? <Flex alignItems="center">
        <Typography variant="label" color="text.light" mr={2}>
          {item.label}
        </Typography>
        <SpeechBubble html={item.info}>i</SpeechBubble>
      </Flex>
      : <Typography variant={item.isBold ? "h4" : "label"} color="text.light">
        {item.label}
      </Typography>
    }
    <Typography color="text.lighter">{item.value}</Typography>
  </>
);

export const DataGrid: React.FC<IDataGridProps> = ({data}) => (
  <Root>
    {data.map((item, index) =>
      Array.isArray(item) ? (
        <Grid
          key={index}
          mb={3}
          gridTemplateColumns={new Array(item.length).fill("1fr").join(" ")}
        >
          {item.map((subItem, index) => (
            <div key={index}>{Item(subItem)}</div>
          ))}
        </Grid>
      ) : (
        <Box key={index} mb={3}>
          {Item(item)}
        </Box>
      )
    )}
  </Root>
);
