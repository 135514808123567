import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { GlobalStyles } from "GlobalStyles";
import { ThemeProvider } from "styled-components";
import { theme } from "config/theme";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "contexts/AuthContext";
import { RegisterFormContextProvider } from "contexts/RegisterFormContext";
import { ScrollToTop } from "components/ScrollToTop";
import { setConfiguration } from "react-grid-system";
import { gridConfig } from "config/grid";
import { SettingsFormContextProvider } from "./contexts/SettingsFormContext";
import { SubscriptionContextProvider } from "./contexts/SubscriptionContext";
import { GlobalStateContextProvider } from "./contexts/GlobalStateContext";

setConfiguration(gridConfig);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AuthContextProvider>
        <RegisterFormContextProvider>
          <SettingsFormContextProvider>
            <SubscriptionContextProvider>
              <GlobalStateContextProvider>
                <BrowserRouter>
                  <ScrollToTop />
                  <App />
                </BrowserRouter>
              </GlobalStateContextProvider>
            </SubscriptionContextProvider>
          </SettingsFormContextProvider>
        </RegisterFormContextProvider>
      </AuthContextProvider>
      <GlobalStyles />
    </ThemeProvider>
  </React.StrictMode>
  ,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
