import { Box } from "components/Box";
import { Flex } from "components/Flex";
import React, { useEffect } from "react";
import Select from "react-select";
import styled from "styled-components";
import { Typography } from "components/Typography";

export interface IOption {
  label: string;
  value: string;
}

interface ITags {
  options: IOption[];
  selectedOptions?: IOption[];

  onChange?(e: IOption[]): void;

  transparent?: boolean;
  label?: string;
}

interface IStyledTags {
  transparent?: ITags["transparent"];
}

const StyledSelect = styled(Select)<IStyledTags>`
  ${({ transparent }) => `
    ${transparent && `& > div:nth-child(2) {background-color: transparent;}`}
  `}
  .valueContainer {
    opacity: 1;
  }
`;

const Tag = styled.div`
  ${({ theme }) => `
    display: inline-block;
    padding: ${theme.space[1]}px ${theme.space[3]}px;
    border-radius: ${theme.radii[10]}px;
    background-color: ${theme.colors.neutral.light};
  `}
  
  & span {
    cursor: pointer;
  }
`;

// const customStyles = {
//   multiValue: (provided: any) => ({
//     ...provided,
//     display: "none"
//   })
// };

export const Tags: React.FC<ITags> = ({ selectedOptions, options, onChange, label, transparent }) => {
  const [value, setValue] = React.useState<IOption[]>(selectedOptions || []);
  const handleChange = (e: IOption[]) => {
    setValue(e);
    if (onChange) onChange(e);
  };
  // const handleRemove = (element: string) => {
  //   const newVal = value.filter((val: IOption) => val.value != element);
  //   setValue(newVal);
  //   if (onChange) onChange(newVal);
  // };

  useEffect(() => {
    if (selectedOptions) setValue(selectedOptions)
  }, [selectedOptions])

  return (
    <>
      <Box mb={4}>
        {label &&
        <Typography variant="label" ml={3}>
          {label}
        </Typography>
        }
        <StyledSelect
          label={label}
          transparent={transparent}
          value={value}
          onChange={handleChange}
          closeMenuOnSelect={false}
          isMulti
          options={options}
        />
      </Box>
      {/*<Flex flexWrap="wrap" mr={3} mb={2}>*/}
      {/*  {value.map(({ value, label }) => (*/}
      {/*    <Box key={value} mr={3} mb={2}>*/}
      {/*      <Tag>*/}
      {/*        {label} <span onClick={() => handleRemove(value)}>X</span>*/}
      {/*      </Tag>*/}
      {/*    </Box>*/}
      {/*  ))}*/}
      {/*</Flex>*/}
    </>
  );
};
