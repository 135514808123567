import { http } from "utilities/http";
import { taxonKeys } from "types/taxon";
import qs from "qs";

export interface IFetchTaxonResponse {
  data: {
    id: string
    type: string
    attributes: {
      depth: number
      description: null | string
      is_child: boolean
      is_leaf: boolean
      is_root: boolean
      left: number
      meta_description: string
      meta_title: string
      meta_keywords: string
      name: string
      permalink: string
      position: number
      pretty_name: string
      right: number
      seo_title: string
      updated_at: string
    }
    relationships: {
      parent: {
        data: {
          id: string
          type: string
        }
      }
      children: {
        data: {
          id: string
          type: string
        }[]
      }
      taxonomy: {
        data: {
          id: string
          type: string
        }
      }
      image: {
        data: {
          id: string
          type: string
        }
      }
      products: {
        data: {
          id: string
          type: string
        }[]
      }
    }
  }
}

export interface IFetchTaxonsResponse extends IFetchTaxonResponse {
  included: {
    id: string
    type: string
    attributes: {
      name: string
      pretty_name: string
      permalink: string
      seo_title: string
      meta_title: string
      meta_description: string
      meta_keywords: string
      left: number
      right: number
      position: number
      depth: number
      is_root: boolean
      is_child: boolean
      is_leaf: boolean
      updated_at: string
    }
    relationships: {
      parent: {
        data: {
          id: string
          type: string
        }
      }
      children: {
        data: {
          id: string
          type: string
        }[]
      }
      taxonomy: {
        data: {
          id: string
          type: string
        }
      }
      image: {
        data: {
          id: string
          type: string
        }
      }
      products: {
        data: {
          id: string
          type: string
        }[]
      }
    }
  }[],
  meta: {
    count: number
    total_count: number
    total_pages: number
  },
  links: {
    self: string
    next: string
    prev: string
    last: string
    first: string
  }
}

export const fetchTaxonById = async (id: string | number) => {
  return await http<IFetchTaxonResponse>(
    `${process.env.REACT_APP_API_URL}api/v2/storefront/taxons/${id}`);
};

export const fetchTaxon = async (taxonomy: taxonKeys) => {
  const query = qs.stringify({ "filter[name]": taxonomy, page: 1, include: "children" });
  return await http<IFetchTaxonsResponse>(
    `${process.env.REACT_APP_API_URL}api/v2/storefront/taxons?${query}`);
};

export const fetchTaxons = (taxonomies: Array<taxonKeys>) => {
  return Promise.all(taxonomies.map((taxonomy) => fetchTaxon(taxonomy)))
    .then(responses => {
      return Promise.resolve(Object.fromEntries(taxonomies.map((taxonomy, index) => {
        return [taxonomy, responses[index]];
      })));
    })
    .catch(e => {
      console.log(e)
      throw new Error(e?.message)
    });
};
