import { useEffect, useRef, useState } from "react";
import { fetchSubscriptions } from "../services/user";
import { getPreparedSubscriptions } from "../utilities/subscription";
import { toast } from "react-toastify";

export const useSubscriptionDays = (isAuthenticated: boolean, reFetchSubscriptions: boolean) => {
  const [subscriptionDays, setSubscriptionDays] = useState<Date[]>([]);
  const [paidSubscriptionDays, setPaidSubscriptionDays] = useState<Date[]>([]);
  const [waitingSubscriptionDays, setWaitingSubscriptionDays] = useState<Date[]>([]);

  const fetchAndPrepareSubscriptions = async () => {
    try {
      const response = await fetchSubscriptions();
      const preparedSubscriptions = getPreparedSubscriptions(response);
      const paidSubscriptions = preparedSubscriptions.filter(el => !!el.paid)
      const waitingSubscriptions = preparedSubscriptions.filter(el => !el.cancelled)

      let days = preparedSubscriptions.map(el => el.subscription_days.map(subEl => subEl ? new Date(subEl.date) : null).filter(el => !!el)).reduce((a, b) => {
        return a.concat(b);
      }, []);
      let paidDays = paidSubscriptions.map(el => el.subscription_days.map(subEl => subEl ? new Date(subEl.date) : null).filter(el => !!el)).reduce((a, b) => {
        return a.concat(b);
      }, []);
      let waitingDays = waitingSubscriptions.map(el => el.subscription_days.map(subEl => subEl ? new Date(subEl.date) : null).filter(el => !!el)).reduce((a, b) => {
        return a.concat(b);
      }, []);

      // @ts-ignore
      setSubscriptionDays(days);
      // @ts-ignore
      setPaidSubscriptionDays(paidDays);
      // @ts-ignore
      setWaitingSubscriptionDays(waitingDays);
      for (let i = 2; i <= response.meta.total_pages; i++) {
        const response = await fetchSubscriptions(`${i}`);
        const preparedSubscriptions = getPreparedSubscriptions(response);
        const paidSubscriptions = preparedSubscriptions.filter(el => !!el.paid)
        const waitingSubscriptions = preparedSubscriptions.filter(el => !el.cancelled)
        let days = preparedSubscriptions.map(el => el.subscription_days.map(subEl => subEl ? new Date(subEl.date) : null).filter(el => !!el)).reduce((a, b) => {
          return a.concat(b);
        }, []);
        let paidDays = paidSubscriptions.map(el => el.subscription_days.map(subEl => subEl ? new Date(subEl.date) : null).filter(el => !!el)).reduce((a, b) => {
          return a.concat(b);
        }, []);
        let waitingDays = waitingSubscriptions.map(el => el.subscription_days.map(subEl => subEl ? new Date(subEl.date) : null).filter(el => !!el)).reduce((a, b) => {
          return a.concat(b);
        }, []);
        // @ts-ignore
        setSubscriptionDays(prevState => [...prevState, ...days]);
        // @ts-ignore
        setPaidSubscriptionDays(prevState => [...prevState, ...paidDays]);
        // @ts-ignore
        setWaitingSubscriptionDays(prevState => [...prevState, ...waitingDays]);
      }
    } catch (e) {
      toast.error("Nie powiodła się próba pobrania danych użytkownika.");
    }
  };

  useEffect(() => {
    if (isAuthenticated) fetchAndPrepareSubscriptions();
  }, [isAuthenticated, reFetchSubscriptions]);

  return {
    subscriptionDays,
    setSubscriptionDays,
    paidSubscriptionDays,
    setPaidSubscriptionDays,
    waitingSubscriptionDays,
    setWaitingSubscriptionDays,
    fetchAndPrepareSubscriptions
  };
};
