import React from 'react';
import styled, { css } from 'styled-components';
import { Flex } from "./Flex";
import { Typography } from "./Typography";
import PhoneInput from 'react-phone-number-input'
import pl from 'react-phone-number-input/locale/pl.json'
import 'react-phone-number-input/style.css'

interface IProps {
  label: string;
  value: string;
  onChange: () => void;
  error: string | undefined;
}

const Wrapper = styled.div(
  () => css`
    ${({ theme }) => `
      .PhoneInputInput {
        height: ${theme.space[10]}px;
        padding: 0 ${theme.space[3]}px;
        font-size: 14px;
        line-height: 20px;
        border: ${theme.inputBorder};
        border-radius: ${theme.radii[2]}px;
      }
      .PhoneInputCountryIcon {
        width: 100%;
        height: 22px;
        > img {
          width: auto;
        }
      }
      option {
        font-size: 14px;
        line-height: 20px;
        font-family: "Open Sans",sans-serif;
      }
    `}
  `
);

export const PhoneInputWrapper: React.FC<IProps> = ({ label, value, onChange, error }) => {
  return (
    <Wrapper>
      <Typography variant="label" ml={72}>
        {label}
      </Typography>
      <PhoneInput
        labels={pl}
        specialLabel="Numer telefonu"
        defaultCountry="PL"
        international
        value={value}
        onChange={onChange}
        placeholder={'+48 123-456-789'}
      />
      <Flex justifyContent="flex-end">
        <Typography variant="error">{error}</Typography>
      </Flex>
    </Wrapper>
  );
};
