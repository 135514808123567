import { Box } from "components/Box";
import { Typography } from "components/Typography";
import styled from "styled-components";
import React from "react";
import Descend from '../../stories/assets/graph-descend.svg';
import Ascend from '../../stories/assets/graph_ascend.svg';
import Const from '../../stories/assets/graph-const.svg';
import { theme } from "../../config/theme";

interface IGraph {
  weight?: number;
  target_weight?: number;
  start_date?: string;
  end_date?: string;
  settings?: boolean;
}

const Image = styled.img`
  width: 100%;
  height: auto;
`;

export const Graph: React.FC<IGraph> = ({
  weight,
  target_weight,
  start_date,
  end_date,
  settings
}) => {
  return (
    <Box py={4} position="relative" mb={10}>
      {weight && target_weight && end_date &&
        <>
          <Image src={
            weight > target_weight
              ? Descend
              : weight < target_weight
                ? Ascend
                : Const
          } />
          <Box
            backgroundColor={theme.colors.primary}
            width={60}
            px="9px"
            py="5px"
            borderRadius="8px"
            position="absolute"
            top={
              weight > target_weight
                ? "18%"
                : weight < target_weight
                  ? "76%"
                  : "33%"
            }
            left={settings ? "60px" : "20px"}
            transform="translateY(-50%)"
          >
            <Typography color={theme.colors.neutral.white} fontSize={12} textAlign="center">
              {`${weight} kg`}
            </Typography>
          </Box>
          <Box
            backgroundColor={theme.colors.primary}
            width={60}
            px="9px"
            py="5px"
            borderRadius="8px"
            position="absolute"
            top={
              weight > target_weight
                ? "50%"
                : weight < target_weight
                  ? "17%"
                  : "28%"
            }
            right={settings ? "40px" : "7px"}
            transform="translateY(-50%)"
          >
            <Typography color={theme.colors.neutral.white} fontSize={12} textAlign="center">
              {`${target_weight} kg`}
            </Typography>
          </Box>
          <Box
            position="absolute"
            bottom="-10px"
            left={settings ? "50px" : "20px"}
          >
            <Typography fontSize={12} textAlign="center">
              {start_date}
            </Typography>
          </Box>
          <Box
            position="absolute"
            bottom="-10px"
            right={settings ? "30px" : "15px"}
          >
            <Typography fontSize={12} textAlign="center">
              {end_date}
            </Typography>
          </Box>
        </>
      }
    </Box>
  );
};
