import {MainLayout} from "layouts/MainLayout";
import {Table} from "components/Table/Table";
import React, {useEffect, useState} from "react";
import {changeRouteStatus, fetchRoutes} from "../services/vendor";
import {Flex} from "../components/Flex";
import {Calendar} from "../components/Calendar/Calendar";
import {Card} from "../components/Card";
import styled from "styled-components";
import {formatDate} from "../utilities/date";
import {getPreparedDelivery} from "../utilities/delivery";
import {Checkbox} from "../components/Checkbox";
import {Typography} from "../components/Typography";

interface IRow {
  rowData: string[] | React.ReactNode[]
  expandedData: JSX.Element
}

const Wrapper = styled(Flex)`
  align-items: flex-start;

  > div:first-child {
    flex: 3;
  }

  > div {
    flex: 1;
    margin-left: 12px;
  }
`

export const orderStatuses: { [key: string]: string } = {
  pending: 'Oczekujące',
  ready: 'Gotowe',
  shipped: 'Dostarczone'
}

const getToggleStatus = (status: string, type: string) => {
  if (status === 'pending') return "ready"
  if (status === 'shipped') return "ready"
  return type === 'delivery' ? 'shipped' : 'pending'
}

export const DeliveryView: React.FC = () => {
  // const [isLoading, setIsLoading] = useState(false);
  const delimiters = ["40%", "15%", "15%", "15%", "15%"];
  const courierColumnNames = ["Adres", "Przybycie", "Wyjazd", "Typ", "Status"];
  // const [products, setProducts] = useState<IFetchProductsSingleItem["data"][]>([]);
  const [receiptRows, setReceiptRows] = useState<IRow[]>([]);
  const [deliveryRows, setDeliveryRows] = useState<IRow[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const handleFetchRoutes = () => {
    fetchRoutes(formatDate(selectedDate, '-'), 'receipt').then(res => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const {dataPrepared} = getPreparedDelivery(res, 'receipt', customCheckbox)
      const rows: IRow[] = []
      dataPrepared.forEach(el => {
        el.dataForTable.forEach(el => {
          rows.push({
            rowData: el.rowData,
            expandedData: <Flex flexDirection="column">{
              Object.keys(el.expandedData).map(key => {
                return <p style={{margin: "2px 0"}} key={key}>{key}: {el.expandedData[key]}</p>
              })
            }</Flex>
          })
        })
      })
      setReceiptRows(rows)
    })
    fetchRoutes(formatDate(selectedDate, '-'), 'delivery').then(res => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const {dataPrepared} = getPreparedDelivery(res, 'delivery', customCheckbox)
      const rows: IRow[] = []
      dataPrepared.forEach(el => {
        el.dataForTable.forEach(el => {
          rows.push({
            rowData: el.rowData,
            expandedData: <Flex flexDirection="column">{
              Object.keys(el.expandedData).map(key => {
                return <p style={{margin: "2px 0"}} key={key}>{key}: {el.expandedData[key]}</p>
              })
            }</Flex>
          })
        })
      })
      setDeliveryRows(rows)
    })
  }

  function customCheckbox(routePointId: number | string, status: string, type: string) {
    return <Checkbox
      disabled={(type === 'delivery' && status === 'pending') || (type === 'receipt' && status === 'shipped')}
      checked={(type === 'delivery' && status === 'shipped') || (type === 'receipt' && status === 'ready')}
      onChange={(val) => {
        changeRouteStatus(routePointId, getToggleStatus(status, type))
          .then(res => {
            handleFetchRoutes()
          })
      }}
      label={orderStatuses[status]}
    />
  }

  useEffect(() => {
    try {
      handleFetchRoutes()
    } catch (e) {
      console.log(e)
    }
  }, [selectedDate])

  useEffect(() => {
    // fetchProducts().then(res => setProducts(res.data))
  }, [])

  // console.log(products)

  return (
    <MainLayout>
      <Wrapper mb={4}>
        <div>
          {receiptRows.length > 0 &&
            <>
              <Typography variant="h3" mb={1}>Odbiór</Typography>
              <Table
                expandedRows
                delimiters={delimiters}
                columnNames={courierColumnNames}
                rows={receiptRows}
              />
            </>
          }
          {deliveryRows.length > 0 &&
            <>
              <Typography variant="h3" mb={1} mt={4}>Dostawa</Typography>
              <Table
                expandedRows
                delimiters={delimiters}
                columnNames={courierColumnNames}
                rows={deliveryRows}
              />
            </>
          }
        </div>
        <Card py={6} px={8}>
          <Calendar
            key={selectedDate.getTime()}
            initDays={[selectedDate]}
            initialMonth={selectedDate}
            selectOneDay
            onDayChanged={setSelectedDate}
          />
        </Card>
      </Wrapper>
    </MainLayout>
  );
};
