import { Flex } from "components/Flex";
import React from "react";
import styled from "styled-components";
import { Typography } from "components/Typography";
import { space, SpaceProps } from "styled-system";
import { ReactComponent as AvatarIcon } from "icons/avatar.svg";

interface Props extends SpaceProps {
}

interface IPerson {
  fullName: string;
  imgPath: string;
  hugeFullName?: boolean;
}

const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;
const AvatarPlaceholder = styled(AvatarIcon)`
  width: 32px;
  height: 32px;
`;

const FullName = styled(Typography)<Props>`
  ${({ theme }) => `
     color: ${theme.colors.text.lighter};
  `};
  ${space}
`;

export const Person: React.FC<IPerson> = ({ fullName, imgPath, hugeFullName }) => (
  <Flex alignItems="center">
    {imgPath === "#"
      ? <AvatarPlaceholder />
      : <Avatar src={imgPath} />
    }
    <FullName variant={hugeFullName ? "h1" : "label"} ml={4}>{fullName}</FullName>
  </Flex>
);
