import styled from "styled-components";
import { grid, GridProps, space, SpaceProps } from "styled-system";

interface Props extends GridProps, SpaceProps {}

export const Grid = styled.div<Props>`
  display: grid;
  ${grid}
  ${space}
`;
