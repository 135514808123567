import { fetchAPI } from "utilities/fetchAPI";
import qs from "qs";
import { PaginationType } from "types/common";

export interface IProductVendor {
  id: string
  type: string
  attributes: {
    name: string
    about_us: string
    slug: string
    city: string
    zip_code: string
    street: string
    street_number: string
    flat_number: string
    phone: string
    contact_description: string
  }
  relationships: {
    image: {
      data: {
        id: string
        type: string
      }
    }
    product: {
      data: {
        id: string
        type: string
      }
    }
  }
}

export interface IFetchProductsSingleItem {
  data: {
    id: string
    type: string
    attributes: {
      name: string
      description: string,
      brand_name: string,
      available_on: string | null,
      avarage_rating: number,
      current_user_product_rating: number | null,
      current_user_favourite_product?: boolean,
      meta_description: string | null,
      meta_keywords: string | null,
      updated_at: string,
      purchasable: boolean,
      in_stock: boolean,
      backorderable: boolean,
      available: boolean,
      currency: string,
      price: string,
      display_price: string,
      compare_at_price: string | null,
      display_compare_at_price: string | null,
      carbohydrates: number,
      proteins: number,
      fats: number,
      calories: number
      weight: string,
      rating_count: number,
      slug: string
      fiber: number
      sugars: number
      salt: number
      nkt: number
      preparation_and_storage: string
      quantity: number
      new?: boolean
      allergens: string[]
      ingredients: string[]
    }
    relationships: {
      variants: {
        data: {
          id: string,
          type: string
        }[]
      },
      option_types: {
        data: {
          id: string,
          type: string
        }[]
      },
      product_properties: {
        data: {
          id: string,
          type: string
        }[]
      },
      taxons: {
        data: {
          id: string,
          type: string
        }[]
      },
      images: {
        data: {
          id: string,
          type: string
        }[]
      },
      default_variant: {
        data: {
          id: string,
          type: string
        }
      },
      vendor: {
        data: {
          id: string
          type: string
        }
      }
    }
  },
  included: (IProductVendor)[]
}

export interface IProductsVendor {
  id: string,
  type: string
  attributes: {
    name: string
    about_us: string
    slug: string
    city: string
    zip_code: string
    street: string
    street_number: string
    flat_number: string
    phone: string
    contact_description: string
  }
}

export interface IFetchProducts {
  data: IFetchProductsSingleItem["data"][],
  meta: PaginationType
  included: (IProductsVendor)[]
}

export interface IFetchMealTypes {
  data: {
    id: string
    type: string
    attributes: {
      name: string
    }
    relationships: {
      products: {
        data: {
          id: string
          type: string
        }[]
      }
    }
  }[]
}

export interface IFetchUserRating {
  data: {
    id: number
    rating_value: number
  }
  relationships: {
    vendor: {
      data: {
        id: string
        type: string
      }
    }
  }
}

export const fetchMealTypes = async () => await fetchAPI<IFetchMealTypes>(
  `${process.env.REACT_APP_API_URL}meal_types`
);

export const fetchProducts = async (filters?: Object, controllerRef?: AbortController | null) => {
  const query = filters ? `?${qs.stringify(filters, {
    arrayFormat: "brackets",
    encode: false
  })}` : "";
  return await fetchAPI<IFetchProducts>(
    `${process.env.REACT_APP_API_URL}api/v2/storefront/products${query}`, {
      // @ts-ignore
      signal: controllerRef ? controllerRef.current?.signal : null
    });
};

type productIncludes =
  "default_variant"
  | "variants"
  | "option_types"
  | "product_properties"
  | "taxons"
  | "images"
  | "vendor"

export const fetchProduct = async (id: number, params?: productIncludes[]) => {
  const include = params ? params.join(",") : "";
  return await fetchAPI<IFetchProductsSingleItem>(
    `${process.env.REACT_APP_API_URL}api/v2/storefront/products/${id}?include=${include}`);
};

export const rateProduct = async (id: number, rating: number, subscription_day_id: string | number) =>
  await fetchAPI<IFetchProductsSingleItem>(`${process.env.REACT_APP_API_URL}api/v2/storefront/products/rate`, {
    method: "POST",
    body: JSON.stringify({ id: id, rating_value: rating, subscription_day_id })
  });

export const likeProduct = async (id: number) =>
  await fetchAPI<IFetchProductsSingleItem>(`${process.env.REACT_APP_API_URL}api/v2/storefront/products/fav`, {
    method: "POST",
    body: JSON.stringify({ id })
  });

export const unlikeProduct = async (id: number) =>
  await fetchAPI<IFetchProductsSingleItem>(`${process.env.REACT_APP_API_URL}api/v2/storefront/products/unfav`, {
    method: "DELETE",
    body: JSON.stringify({ id })
  });
