export const http = async <T = null>(
  url: string,
  options?: RequestInit
): Promise<T> => {
  const response = await fetch(url, {
    ...options,
    headers: {
      ...options?.headers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  if (!response.ok) {
    if (response.status === 401) {
      if (localStorage.getItem("boofet-app-token")) {
        localStorage.removeItem("boofet-app-token");
        window.location.replace("/login");
      }
    }
    if (response.status === 403) {
      throw new Error('Coś poszło nie tak.')
      if (localStorage.getItem("boofet-app-token")) {
        localStorage.removeItem("boofet-app-token");
        window.location.replace("/login");
      }
    }
    if (response.status === 422) {
      const responseData = await response.json()
      if (responseData.message) throw new Error(responseData.message)
      if (responseData.error) throw new Error(responseData.error)
      throw new Error(responseData)
    }
    if (response.status === 400 || `${response.status}`[0] === '5' || response.status === 404) throw new Error('Coś poszło nie tak.')
  }

  if ((response.status !== 200 && response.status !== 201) && (response.statusText === "No Content" || response.statusText === "")) {
    return new Promise((resolve) => {
      resolve(null as any);
    });
  }

  return response.json();
};
