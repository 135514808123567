import { Button } from "components/Button";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Typography } from "components/Typography";
import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import {
  IRangeFields,
  IRegisterHealthInterviewFormData,
  parsedValue,
  rangeFields
} from "views/RegisterHealthInterviewView";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerSchema } from "schemas/register";
import { TextField } from "components/TextField";
import { InputRange } from "components/InputRange/InputRange";
import { useSettingsFormDispatchContext, useSettingsFormStateContext } from "contexts/SettingsFormContext";
import { updateUser } from "services/user";
import { toast } from "react-toastify";
import { DatePickerInput } from "../../components/DatePickerInput/DatePickerInput";
import { useCalculateDiet } from "../../hooks/calculateDiet";

export const SettingsHealthInterviewView: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { formsData } = useSettingsFormStateContext();
  const { setFormsData } = useSettingsFormDispatchContext();

  const { register, handleSubmit, watch, control, formState: { errors } } =
    useForm<IRegisterHealthInterviewFormData>({
      defaultValues: {
        type: formsData?.type ?? "person",
        gender: formsData?.gender ?? "male",
        birthday: formsData?.birthday ?? undefined,
        weight: formsData?.weight ?? 68,
        target_weight: formsData?.target_weight ?? 68,
        height: formsData?.height ?? 165
      }
    });

  const watcher = watch();

  const calculatedWeight = useCalculateDiet(
    watcher.height || 170,
    watcher.weight || 70,
    watcher.target_weight || 70,
    formsData.target_days || 30,
    formsData.energy || 2200,
    formsData.physical_activity || 10000,
    watcher.gender || "male",
    watcher.birthday || "1990-11-11",
    true
  );

  const onSubmit = async (data: IRegisterHealthInterviewFormData) => {
    if (data.weight + 21 < data.target_weight) {
      toast.error("Waga docelowa nie może przekraczać wagi + 21 kg");
      return;
    }

    if (data.weight - 21 > data.target_weight) {
      toast.error("Waga docelowa nie może być mniejsza niż waga - 21 kg.");
      return;
    }

    const parsedData = parsedValue(data);

    // recalculate diet data for SettingsDiet view
    const additionalData: { target_days?: number, energy?: number } = {
      target_days: formsData.target_days,
      energy: formsData.energy
    };
    if ("object" === typeof calculatedWeight) {
      additionalData.target_days = calculatedWeight.prefDays;
      additionalData.energy = calculatedWeight.CPM;
    }
    setFormsData({ ...data, ...additionalData });

    setIsLoading(true);
    await updateUser({ ...parsedData, ...additionalData })
      .then(response => {
        toast.success("Zmiany zostały zapisane.");
      })
      .catch(() => toast.error("Coś poszło nie tak."))
      .finally(() => setIsLoading(false));
  };


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h2">Wywiad zdrowotny</Typography>
      <Box mb={6} mt={3}>
        {!watch("type") || watch("type") === "person" ? (
          <>
            <Box mb={6}>
              <Controller
                control={control}
                name="birthday"
                render={({ field: { value, onChange } }) => (
                  <DatePickerInput
                    width="40%"
                    maxDate={new Date()}
                    required
                    placeholderText='dd.mm.yyyy'
                    label="Data urodzenia"
                    day={value ? new Date(value) : undefined}
                    transparent
                    onChange={(date) => {
                      onChange(date ? new Date(date) : undefined);
                    }}
                  />
                )}
              />
            </Box>

            {rangeFields.map((field: IRangeFields) => (
              <Box mb={6} key={field.name}>
                <Typography variant="label">{field.label}</Typography>
                <Controller
                  control={control}
                  name={field.name}
                  render={({ field: { value, onChange } }) => (
                    <InputRange
                      min={field.min}
                      max={field.max}
                      value={value}
                      onChange={(value) => {
                        onChange(value);
                      }}
                    />
                  )}
                />
              </Box>
            ))}
          </>
        ) : (
          <Typography variant="h4" fontWeight="300" my={10}>
            Przy prowadzeniu zamówienia jako firma, pomijamy dane wywiad
            zdrowotny oraz nie ustawiamy wartości odżywczych, jeżeli chciałbyś
            spersonalizować dietę pod siebie oraz dostać fakturę, zaznacz pole
            osoba prywatna, dane do faktury będą pobrane później.
          </Typography>
        )}
      </Box>
      {(!watch("type") || watch("type") === "person") &&
      <Flex justifyContent="flex-end">
        <Button variant="primary" disabled={isLoading}>Zapisz zmiany</Button>
      </Flex>
      }
    </form>
  );
};
