import { Box } from "components/Box";
import { Stepper } from "components/Stepper/Stepper";
import { useRegisterFormStateContext } from "contexts/RegisterFormContext";
import { format } from "date-fns";
import React from "react";
import { Typography } from "components/Typography";
import { DataGrid } from "components/DataGrid";
import { useHistory } from "react-router";

interface IRegisterFormStatus {
  content?: string;
}

export const RegisterFormStatus: React.FC<IRegisterFormStatus> = ({
  content
}) => {
  const { steps, formsData } = useRegisterFormStateContext();
  const history = useHistory();

  if (!history.location.pathname.includes('/register/summary') && history.location.pathname !== '/register') {
    if (localStorage.getItem("boofet-app-token")) {
      if (formsData?.paymentId) history.push("/register/summary/" + formsData?.paymentId)
    } else {
      history.push("/register")
    }
  }

  const step1 = [
    [
      {
        label: "Rodzaj konta",
        value: formsData?.type === "company" ? "Firmowe" : "Osoba prywatna"
      },
      {
        label: "Płeć",
        value: formsData?.gender === "male" ? "Mężczyzna" : "Kobieta"
      }
    ],
    [
      {
        label: "Data urodzenia",
        value:
          formsData?.birthday &&
          format(new Date(`${formsData.birthday}`), "dd.MM.yyyy")
      },
      {
        label: "Waga",
        value: `${formsData?.weight} kg`
      },
    ],
    [
      {
        label: "Waga docelowa",
        value: `${formsData?.target_weight} kg`
      },
      {
        label: "Wzrost",
        value: `${formsData?.height} cm`
      }
    ]
  ];

  const step2 = [
    [
      {
        label: "Ilość dni",
        value: formsData?.target_days
      },
      {
        label: "Energia",
        value: `${formsData?.energy} kcal`
      }
    ],
    {
      label: "Ilość wykonywanych kroków",
      value: formsData?.physical_activity ? formsData?.physical_activity.toLocaleString().replace(",", ' ') : formsData?.physical_activity
    },
    {
      label: "Posiłki",
      value:
        formsData?.meal_types &&
        formsData?.meal_types
          .filter(({ checked }) => checked)
          .map(({ name }, index, arr) => name + (index+1 === arr.length ? '' : ', '))
    },
  ];

  const step3 = [
    { label: "Dieta", value: formsData?.diets && formsData.diets.filter(diet => diet.checked).map(diet => diet.label).join(", ") },
    { label: "Wykluczenia - składniki", value: formsData?.ingredients && formsData.ingredients.map(ingredient => ingredient.label).join(", ") },
    // { label: "Wykluczenia alergeny", value: formsData?.allergens && formsData.allergens.map(allergen => allergen.label).join(", ") },
    { label: "Kuchnia", value: formsData?.kitchens && formsData.kitchens.filter(kitchen => kitchen.checked).map(kitchen => kitchen.label).join(", ") }
  ];

  const budget = formsData?.subscription?.amount || 0
  const step4 = [
    [
      { label: "Dzienny budżet", value: `${formsData?.subscription?.amount} zł` },
      { label: "Okres", value: `${(formsData?.subscription?.date_from)?.toString().split('-').join('.')} - ${(formsData?.subscription?.date_to)?.toString().split('-').join('.')}` }
    ],
    [
      {
        label: "Koszt dostawy",
        value: `${(formsData?.subscription?.amount || 1) > 54 ? 0 : 8} zł`
      },
      {
        label: "Wartość abonamentu",
        value: `${((budget > 54 ? budget : budget + 8)) * (formsData?.subscription?.number_of_days || 0)} zł`
      }
    ]
  ];

  const step5a = [
    { label: "Imię i nazwisko", value: `${formsData?.firstname} ${formsData?.lastname}` },
    { label: "Adres", value: `${formsData?.ship_address?.street} ${formsData?.ship_address?.number}${formsData?.ship_address?.local && `/${formsData?.ship_address?.local}`} ${formsData?.ship_address?.zipcode} ${formsData?.ship_address?.city}` },
    { label: "Dodatkowe informacje", value: `${formsData?.ship_address?.stairway && `Klatka: ${formsData?.ship_address?.stairway},`} ${formsData?.ship_address?.door_code && `Kod: ${formsData?.ship_address?.door_code},`} ${formsData?.ship_address?.floor && `Piętro: ${formsData?.ship_address?.floor},`} ${formsData?.ship_address?.additional_info && formsData?.ship_address?.additional_info}` }
  ]

  const step5b = [
    { label: "Nazwa firmy", value: formsData?.bill_address?.company_name },
    { label: "Adres firmy", value: `${formsData?.bill_address?.street} ${formsData?.bill_address?.number} ${formsData?.bill_address?.local && `/${formsData?.bill_address?.local}`} ${formsData?.bill_address?.zipcode} ${formsData?.bill_address?.city}` },
    { label: "NIP", value: formsData?.bill_address?.nip }
  ]


  return (
    <Box mb={6}>
      <Stepper steps={steps} />
      {content && !formsData ? (
        <Box mt={8}>
          <Typography color="text.lighter">{content}</Typography>
        </Box>
      ) : (
        <Box>
          {(formsData && Object.keys(formsData).length > 0) ? (
            <>
              <Typography variant="h3" mt={6} mb={3}>
                Profil
              </Typography>
              <>
                <DataGrid data={step1} />
                {formsData?.energy && (
                  <>
                    <Typography variant="h3" mt={6} mb={3}>
                      Wartości odżywcze/dieta
                    </Typography>
                    <DataGrid data={step2} />
                  </>
                )}
                {formsData?.diets && (
                  <>
                    <Typography variant="h3" mt={6} mb={3}>
                      Preferencje żywieniowe
                    </Typography>
                    <DataGrid data={step3} />
                  </>
                )}
                {formsData?.subscription && (
                  <>
                    <Typography variant="h3" mt={6} mb={3}>
                      Abonament
                    </Typography>
                    <DataGrid data={step4} />
                  </>
                )}
                {formsData?.firstname && (
                  <>
                    <Typography variant="h3" mt={6} mb={3}>
                      Dane adresowe
                    </Typography>
                    <DataGrid data={step5a} />
                  </>
                )}
                {formsData?.bill_address?.company_name && (
                  <>
                    <Typography variant="h3" mt={6} mb={3}>
                      Dane firmy
                    </Typography>
                    <DataGrid data={step5b} />
                  </>
                )}
              </>
            </>

          ) : (
            <Box mt={4}>
              <Typography variant="h4">{content}</Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
