import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Typography } from "components/Typography";
import { RegisterFormStatus } from "containers/RegisterFormStatus";
import { useRegisterFormDispatchContext } from "contexts/RegisterFormContext";
import { LoginRegisterLayout } from "layouts/LoginRegisterLayout";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { fetchPayment } from "services/payment";
import { toast } from "react-toastify";
import { Box } from "components/Box";
import { Loader } from "components/Loader/Loader";
import { useInterval } from "hooks/interval";
import { Link } from "components/Link";

export const RegisterSummaryView: React.FC = () => {
  const { paymentId } = useParams<{ paymentId: string }>();
  const history = useHistory();
  const [requestStatus, setRequestStatus] = React.useState<string>("pending");
  const { setActiveStep } = useRegisterFormDispatchContext();

  const getPayment = (id: number) => {
    if (Number.isNaN(id) || id === 0) return;
    fetchPayment(id)
      .then(payment => {
        setRequestStatus(payment.data.attributes.status);
      })
      .catch(() => toast.error("Nie udało się pobrać płatność."));
  };

  useInterval(() => getPayment(Number(paymentId)), (paymentId && requestStatus === "pending") ? 1000 : null);

  useEffect(() => {
    setActiveStep(6);
    if (!localStorage.getItem("boofet-app-token")) history.push("/register");
  }, []);

  return (
    <LoginRegisterLayout
      left={
        <>
          <Typography variant="h1" mb={6}>
            {requestStatus === "pending" && "Płatność w trakcie realizacji."}
            {requestStatus === "completed" && "Dziękujemy, płatność została zrealizowana."}
            {requestStatus === "failed" && "Twoja płatność nie powiodła się."}
          </Typography>
          {requestStatus === "completed" &&
          <Typography color="text.lighter">
            Tym samym rozpocząłeś swoją przygodę z Boofet.
            <br />
            Przejdź do serwisu i przekonaj się, jakie dania na podstawie analizy Twojego Wywiadu Zdrowotnego i
            <br />
            upodobań kulinarnych przygotował dla Ciebie Inteligentny Dietetyk.
            <br />
            <br />
            Życzymy smacznego i oczekiwanych efektów.
          </Typography>
          }
          {requestStatus === "failed" &&
          <Typography color="text.lighter">
            Pamiętaj, aby opłacić swoje zamówienie najpóźniej na 72h przed rozpoczęciem jego realizacji.
            <br />
            <br />
            Możesz wrócić do płatności w sekcji <Link to="/history-order">Historia zamówień</Link>.
          </Typography>
          }
          {requestStatus === "pending" &&
          <Typography color="text.lighter">
            Czekamy na odpowiedź z Twojego banku. Wróć do nas za chwilę.
            <br />
            <br />
            Pamiętaj, aby opłacić swoje zamówienie najpóźniej na 72 godziny przed jego realizacją.
            <br />
            <br />
            Możesz wrócić do płatności w sekcji <Link to="/history-order">Historia zamówień</Link>.
          </Typography>
          }
          <Box mt={12}>
            {requestStatus === "pending" &&
            <Box mt={6}>
              <Loader />
            </Box>
            }
          </Box>
          <Flex justifyContent="flex-end" mt={12}>
            <Button variant="primary" onClick={() => history.push("/nutrition-plans")}>
              Przechodzę do serwisu
            </Button>
          </Flex>
        </>
      }
      right={<RegisterFormStatus />}
    />
  );
};
