import { InputHTMLAttributes } from "react";
import { Input } from "components/Input";
import styled from "styled-components";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FaIcon } from "./Icons/FaIcon";

export const IconPlus = styled(FaIcon).attrs({
  icon: faMinus
})`
  position: absolute;
  top: 13px;
  left: 10px;
  color: #c0c5cd;
`;
export const IconMinus = styled(FaIcon).attrs({
  icon: faPlus
})`
  position: absolute;
  top: 13px;
  right: 10px;
  color: #c0c5cd;
`;

const WrapperInput = styled.div<{width: string}>`
  position: relative;
  display: inline-block;
  ${({ width }) => `
    width: ${width};
  `}
  & Input {
    text-align: center;
    ::selection { background-color: transparent; color: unset }
  }
`;

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  count: number;
  changeValue(val: number): void;
  width?: string;
}

export const PriceInput: React.FC<Props> = ({ changeValue, count, width= '100%', ...inputProps }) => (
  <>
    <WrapperInput width={width}>
      <Input type="number" min="1" max="99" value={count} onChange={(e) => {
        changeValue(Number(e.target.value));
      }} fullWidth {...inputProps} />
      <IconMinus onClick={(e) => {
        e.stopPropagation()
        changeValue(count + 1);
      }} />
      <IconPlus onClick={(e) => {
        e.stopPropagation()
        changeValue(count - 1);
      }} />
    </WrapperInput>
  </>
);
