import { http } from "utilities/http";
import { fetchAPI } from "utilities/fetchAPI";
interface IAuthUser {
  username: string;
  password: string;
}
interface IAuthUserResponse {
  access_token: string;
  created_at: number;
  expires_in: number;
  refresh_token: string;
  token_type: string;
}

export const AuthUserService = (credentials: IAuthUser) =>
  http<IAuthUserResponse>(`${process.env.REACT_APP_API_URL}spree_oauth/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ grant_type: "password", ...credentials }),
  });

interface IUserRegister {
  email: string;
  password: string;
  password_confirmation: string;
}

export const AuthRefreshToken = () =>
  http<IAuthUserResponse>(`${process.env.REACT_APP_API_URL}spree_oauth/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      grant_type: "refresh_token",
      refresh_token: localStorage.getItem("boofet-app-refresh-token")
    }),
  });

interface IUserRegister {
  email: string;
  password: string;
  password_confirmation: string;
}

export const registerUser = (user: IUserRegister) =>
  http(`${process.env.REACT_APP_API_URL}api/v2/storefront/account`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ user }),
  });

export interface IOnboardingUser {
  email?: string;
  is_company?: boolean;
  gender?: string;
  birthday?: string;
  weight?: number;
  target_weight?: number;
  height?: number;
  target_days?: number;
  energy?: number;
  physical_activity?: number;
  i_eat_everything?: boolean;
  password?: string;
  password_confirmation?: string;
}

export const onboardingUser = (user: IOnboardingUser) =>
  fetchAPI(`${process.env.REACT_APP_API_URL}/api/v2/storefront/account`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ user }),
  });
