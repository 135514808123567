import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";
import { Typography } from "./Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: boolean;
  capitalizeLabel?: boolean;
  columnHalfSpace?: boolean;
}

interface IControl {
  error?: boolean;
}

const CheckmarkIcon = styled(FontAwesomeIcon).attrs({ icon: faCheck })`
  ${({ theme }) => `
    color: ${theme.colors.neutral.white};
    display: none;
    height: 24px
  `}
`;
const Label = styled.label<{ capitalize?: boolean, columnHalfSpace?: boolean, disabled?: boolean }>`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  ${({ capitalize, columnHalfSpace, disabled }) => `
    ${disabled && `opacity: 0.4;`};
    & ${Typography}:first-letter {
      ${capitalize && 'text-transform: capitalize;'}
    }
    ${columnHalfSpace && 'flex-basis: 50%;'}
  `}
`;

const Control = styled.div<IControl>`
  ${({ theme, error }) => `
    flex: 0 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border: ${theme.inputBorder};
    border-radius: ${theme.radii[1]}px;
    ${
  error &&
  `
      border-color: ${theme.colors.error};
    `
}
  `}
`;

const Input = styled.input`
  ${({ theme }) => `
    position: relative;
    opacity: 0;
    width: 16px;
    height: 16px;
    position: absolute;


    &:checked {
      + ${Control} {
        background-color: ${theme.colors.primary};
        border-color: ${theme.colors.primary};
        ${CheckmarkIcon} {
          display: block;
        }
      }
    }
  `}
`;

export const Checkbox = React.forwardRef<HTMLInputElement, Props>(
  ({ label, error, capitalizeLabel, columnHalfSpace, ...props }, ref) => (
    <Label capitalize={capitalizeLabel} columnHalfSpace={columnHalfSpace} disabled={props.disabled}>
      <Input type="checkbox" {...props} ref={ref} />
      <Control error={error}>
        <CheckmarkIcon />
      </Control>
      <Typography color="text.light">{label}</Typography>
    </Label>
  )
);
