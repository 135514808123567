import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: 0 auto;
  z-index: 1;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  ${({ theme }) => `
      border: 6px solid ${theme.colors.neutral.white};
      border-top: 6px solid ${theme.colors.primary};
  `}
`;

export const Loader: React.FC = () => (
  <Wrapper/>
)
