import { min, max, isEqual, isSameDay } from 'date-fns'

export const formatDate = (d: Date, char?: string) => d ? `${(d.getDate()) <= 9 ? "0" + (d.getDate()) : (d.getDate())}${char || '-'}${(d.getMonth() + 1) <= 9 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)}${char || '-'}${d.getFullYear()}` : '';
export const formatDateConstructable = (d: Date, char?: string) => d ? `${d.getFullYear()}${char || '-'}${(d.getMonth() + 1) <= 9 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)}${char || '-'}${(d.getDate()) <= 9 ? "0" + (d.getDate()) : (d.getDate())}` : '';

type typeToCompare = '<' | '>' | '<=' | '>='

export const compareDates = (date1: Date, date2: Date, type?: typeToCompare) => {
  if (type === '<') return date1.setHours(0, 0, 0, 0) < date2.setHours(0, 0, 0, 0)
  if (type === '>') return date1.setHours(0, 0, 0, 0) > date2.setHours(0, 0, 0, 0)
  if (type === '<=') return date1.setHours(0, 0, 0, 0) <= date2.setHours(0, 0, 0, 0)
  if (type === '>=') return date1.setHours(0, 0, 0, 0) >= date2.setHours(0, 0, 0, 0)
  return date1.setHours(0, 0, 0, 0) === date2.setHours(0, 0, 0, 0)
}

export const findMinAndMaxDate = (dates: Date[]) => {
  if (dates.length < 1) return []
  return [min(dates), max(dates)]
}

type uniqCompareType = 'day' | 'dateTime'

export const getUniqDates = (dates: Date[], type?: uniqCompareType) => dates.filter((date, i, self) => {
  if (type === 'day') return self.findIndex(d => isSameDay(d, date)) === i
  if (type === 'dateTime') return self.findIndex(d => isEqual(d, date)) === i
  return self.findIndex(d => isEqual(d, date)) === i
})

export const includeWeekdaysInRange = (dates: Date[]) => {
  const [minDate, maxDate] = findMinAndMaxDate(dates)
  const datesMs = dates.map(el => el.setHours(0, 0, 0, 0))

  const daysWithWeekdays = dates
  for (let d = minDate; d <= maxDate; d.setDate(d.getDate() + 1)) {
    if (!datesMs.includes(d.setHours(0, 0, 0, 0)) && [0, 6].includes(d.getDay())) daysWithWeekdays.push(new Date(d))
  }

  return daysWithWeekdays
}

export const includesDate = (dates: Date[], date: Date) => {
  return !!dates.find(el => el.setHours(0, 0, 0, 0) === date.setHours(0, 0, 0, 0))
}

export const monthNames = [
  'stycznia',
  'lutego',
  'marca',
  'kwietnia',
  'maja',
  'czerwca',
  'lipca',
  'sierpnia',
  'września',
  'października',
  'listopada',
  'grudnia'
]
