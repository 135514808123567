import { ToastContainer } from "react-toastify";
import { Router } from "components/Router";
import { routes } from "routes";
import { Switch } from "react-router";
import { useEffect } from "react";
import { useAuthDispatchContext } from "./contexts/AuthContext";
import { AuthRefreshToken } from "./services/auth";

export const App = () => {
  const { setToken } = useAuthDispatchContext();

  useEffect(() => {
    AuthRefreshToken()
      .then(response => {
        setToken(response.access_token, response.refresh_token, response.expires_in);
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <div>
      <Switch>
        <Router routes={routes} />
      </Switch>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default App;
