import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import React, { useState } from "react";
import { Box } from "components/Box";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { resetPassword } from "services/user";
import { Typography } from "components/Typography";
import { LoginRegisterLayout } from "layouts/LoginRegisterLayout";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import {TextField} from "../components/TextField";

export interface IResetPassword {
  email: string
}

export const RegisterResetPasswordView: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory();

  const { register, handleSubmit, formState: { errors } } =
    useForm<IResetPassword>({
      defaultValues: {
        email: "",
      }
    });

  const onSubmit = async (data: IResetPassword) => {
    try {
      setIsLoading(true)
      await resetPassword(data.email);
      toast.success("Na podany adres mailowy został wysłany link do zmiany hasła.");
      history.push("/login");
    } catch (error) {
      if ('Unexpected end of JSON input' === error.message) {
        toast.success("Sprawdź swoją skrzynke pocztową.");
        history.push("/login");
        return
      }
      toast.error("Coś poszło nie tak.");
    } finally {
      setIsLoading(false)
    }
  };

  return (
    <LoginRegisterLayout
      left={
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h1" mb={6}>
            Resetuj hasło
          </Typography>
          <Box mb={6}>
            <TextField
              label="Adres e-mail"
              type="text"
              placeholder="Email"
              error={errors.email && "Adres email jest wymagany."}
              {...register("email", { required: true })}
            />
          </Box>

          <Flex justifyContent="flex-end">
            <Button variant="primary" disabled={isLoading}>Resetuj hasło</Button>
          </Flex>
        </form>
      }
      right={
        <Box>
          <Typography variant="common">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam pulvinar sapien id leo fringilla, nec posuere tortor viverra. Morbi laoreet ligula ut ex vestibulum, quis viverra nulla egestas. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Fusce eu quam vehicula lacus pellentesque tincidunt ac id ex.
          </Typography>
        </Box>
      }
    />
  );
};
