// three conditions below are for selecting meal type checkbox after router push from nutrition plans view
import qs from "qs";
import React from "react";
import { parseURL } from "./URLHelper";
import { fetchProducts, IFetchProducts } from "../services/product";
import { toast } from "react-toastify";
import { useGlobalStateContext } from "../contexts/GlobalStateContext";
import { IFetchUser } from "../services/user";
import { IExclusions } from "../views/search/SearchView";

export interface ISearchFilters {
  kitchens: string[],
  popular_tags: string[],
  meal_types_names: string[],
  exclusions_ids: string[],
  "product_names"?: string
  page: number
}

export interface IConvertedSearchFilters {
  meal_types_names: string[],
  tags_ids: string[],
  kitchens_ids: string[],
  exclusions_ids: string[],
  "product_names"?: string
  page: number
}

export const setConfigurationAfterNutritionPlansViewRedirect = (
  filtersFromUrl: qs.ParsedQs,
  setSelectedMealId: React.Dispatch<React.SetStateAction<number | null>>,
  setSelectedDayTime: React.Dispatch<React.SetStateAction<string | undefined>>
) => {
  if (filtersFromUrl.hasOwnProperty("selected_meal_id")) {
    setSelectedMealId(Number(filtersFromUrl.selected_meal_id));
  }
  if (filtersFromUrl.hasOwnProperty("selected_day_time")) {
    setSelectedDayTime(`${filtersFromUrl.selected_day_time}`);
  }
};

export const prepareFiltersFromURL = (): ISearchFilters => {
  const filtersFromUrl = parseURL();

  const filters: ISearchFilters = {
    meal_types_names: [],
    kitchens: [],
    popular_tags: [],
    exclusions_ids: [],
    page: 1
  };

  const taxonNames: string[] = ["kitchens", "popular_tags", "meal_types_names", "exclusions_ids"];
  taxonNames.map(el => {
    if (Array.isArray(filtersFromUrl[el])) {
      // @ts-ignore
      filtersFromUrl[el].map(filterValue => filters[el].push(filterValue));
    }
  });

  return { ...filters, page: Number(filtersFromUrl.page) };
};

export const checkFiltersExists = (): boolean => {
  const filters = prepareFiltersFromURL();
  let exists = false;
  for (let filter in filters) {
    // @ts-ignore
    if (Array.isArray(filters[filter]) && filters[filter].length > 0) exists = true;
  }
  return exists;
};

export const convertFiltersForProductsFetching = (filters: ISearchFilters & { selected_day_time?: string }) => {
  const meal_types_names = filters["meal_types_names"];
  const tags_ids = filters["popular_tags"];
  const kitchens_ids = filters["kitchens"];
  const exclusions_ids = filters["exclusions_ids"];

  return {
    meal_types_names,
    tags_ids,
    kitchens_ids,
    exclusions_ids,
    page: filters.page,
    selected_day_time: filters.selected_day_time,
    "product_names": filters["product_names"]
  };
};

export const fetchInitProducts = ({
                                    meal_types_names,
                                    page,
                                    tags_ids,
                                    kitchens_ids,
                                    exclusions_ids,
                                    selected_day_time,
                                    product_names
                                  }: IConvertedSearchFilters & {
                                    selected_day_time?: string
                                  },
                                  cb: (response: IFetchProducts) => any,
                                  controllerRef?: AbortController | null
) => {
  fetchProducts({
    meal_types_names,
    page,
    tags_ids,
    kitchens_ids,
    exclusions_ids,
    'product_names[]': product_names,
    available_on: selected_day_time ?? undefined
  }, controllerRef)
    .then((response) => cb(response))
    .catch(err => {
      if (err?.message !== 'The user aborted a request.') toast.error("Wystąpił błąd podczas pobierania produktów.");
    });
};
