import styled from "styled-components";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FaIcon } from "./FaIcon";
import { space, SpaceProps } from "styled-system";

interface Props extends SpaceProps {active?: boolean}

export const IconShoppingCart = styled(FaIcon).attrs({
  icon: faShoppingCart
})<Props>`
  font-size: 20px;
  ${({ theme, active }) => `
    color: ${active ? theme.colors.primary : theme.colors.text.lighter};
  `}
  ${space}
`;

