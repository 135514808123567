import styled from "styled-components";
import { Flex } from "./Flex";
import { Typography } from "./Typography";

export interface INutritionBar {
  name: string;
  value: number;
  maxValue: number;
  width: number;
  unit: string;
  color: string;
}

const ProgressBar = styled.div<{
  color: INutritionBar["color"];
  width: number;
}>`
  ${({ theme, color, width }) => `
    height: 8px;
    border-radius: 4px;
    background-color: #f3f4f6;
    position : relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: ${width * 100}%;
      background-color: ${color};
      border-radius: 4px;
    }
  `};
`;

export const NutritionBar: React.FC<INutritionBar> = ({
  name,
  value,
  maxValue,
  width,
  unit,
  color,
}) => (
  <div>
    <Flex mb={3}>
      <Typography fontWeight={600} mr={1}>
        {name}
      </Typography>
      <Typography color={color}>
        {value}
        {unit}/{maxValue}
        {unit}
      </Typography>
    </Flex>
    <ProgressBar width={width} color={color} />
  </div>
);
