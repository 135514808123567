import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import React, {useEffect, useState} from "react";
import { Box } from "components/Box";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { setNewPassword } from "services/user";
import { Typography } from "components/Typography";
import { LoginRegisterLayout } from "layouts/LoginRegisterLayout";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import {TextField} from "../components/TextField";
import {changePasswordSchema} from "../schemas/register";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation } from "react-router-dom";
import * as queryString from "querystring";

export interface ISetNewPassword {
  password: string,
  password_confirmation: string
  reset_password_token: string
}

export const RegisterSetNewPasswordView: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory();
  const { search } = useLocation()
  const values = queryString.parse(search)
  const token = values['?token'] as string

  const { register, handleSubmit, formState: { errors } } =
    useForm<ISetNewPassword>({
      defaultValues: {
        password: "",
        password_confirmation: "",
        reset_password_token: token
      },
      resolver: yupResolver(changePasswordSchema)
    });

  const onSubmit = async (data: ISetNewPassword) => {
    try {
      setIsLoading(true)
      await setNewPassword(data, token);
      toast.success("Hasło zostało zmienione.");
      history.push("/login");
    } catch (error) {
      if ('Unexpected end of JSON input' === error.message) {
        toast.success("Hasło zostało zmienione.");
        history.push("/login");
        return
      }
      toast.error("Coś poszło nie tak.");
    } finally {
      setIsLoading(false)
    }
  };

  useEffect(() => {
    !token && history.push("/login");
  }, [token]);

  return (
    <LoginRegisterLayout
      left={
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h1" mb={6}>
            Ustaw nowe hasło
          </Typography>
          <Box mb={6}>
            <TextField
              label="Hasło"
              type="password"
              placeholder="Hasło"
              {...register("password", { required: true })}
              error={errors?.password?.message}
            />
          </Box>
          <Box mb={6}>
            <TextField
              label="Powtórz hasło"
              type="password"
              placeholder="Powtórz hasło"
              {...register("password_confirmation", { required: true })}
              error={errors?.password_confirmation?.message}
            />
          </Box>

          <Flex justifyContent="flex-end">
            <Button variant="primary" disabled={isLoading}>Ustaw nowe hasło</Button>
          </Flex>
        </form>
      }
      right={
        <Box>
          <Typography variant="common">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam pulvinar sapien id leo fringilla, nec posuere tortor viverra. Morbi laoreet ligula ut ex vestibulum, quis viverra nulla egestas. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Fusce eu quam vehicula lacus pellentesque tincidunt ac id ex.
          </Typography>
        </Box>
      }
    />
  );
};
