import {Typography} from "components/Typography";
import {MainLayout} from "layouts/MainLayout";
import debounce from 'lodash.debounce';
import {Col, Row} from "react-grid-system";
import {Box} from "components/Box";
import {Flex} from "components/Flex";
import {IOption, Tags} from "components/Tags/Tags";
import {Checkbox} from "components/Checkbox";
import styled from "styled-components";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faListUl, faThLarge} from "@fortawesome/free-solid-svg-icons";
import {DishOffer} from "components/DishOffer/DishOffer";
import {Pagination} from "components/Pagination/Pagination";
import {fetchMealTypes, IFetchProductsSingleItem, IProductsVendor, likeProduct, unlikeProduct} from "services/product";
import {useHistory} from "react-router";
import qs from "qs";
import {useLocation} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {ChangeProductModal} from "components/ChangeProductModal/ChangeProductModal";
import {updateScheduledMeal} from "services/nutritionPlans";
import {fetchTaxons} from "../../services/taxon";
import {toast} from "react-toastify";
import {PaginationType} from "../../types/common";
import {fetchUser} from "services/user";
import {parseURL} from "../../utilities/URLHelper";
import {
  checkFiltersExists,
  convertFiltersForProductsFetching,
  fetchInitProducts, ISearchFilters,
  prepareFiltersFromURL,
  setConfigurationAfterNutritionPlansViewRedirect
} from "../../utilities/SearchViewHelper";
import {useGlobalStateContext} from "../../contexts/GlobalStateContext";
import {checkRegistrationStatus} from "../../utilities/checkRegistrationStatus";
import {SettingsModal} from "../../components/SettingsModal";
import {formatDate} from "../../utilities/date";
import {IProduct} from "../../types/product";
import {TextField} from "../../components/TextField";
import useDebounce from "../../hooks/useDebounce";

type registerSearchFormKeys = "meal_types_names" | "popular_tags" | "kitchens"

export interface IMealType {
  name: string;
  checked: boolean;
}

interface IPopularTag {
  id: number;
  name: string;
  checked: boolean;
}

interface IKitchens {
  id: number;
  name: string;
  checked: boolean;
}

export interface IExclusions {
  id: number;
  label: string;
  value: string;
}

export interface IRegisterSearchForm {
  meal_types_names: IMealType[];
  popular_tags: IPopularTag[];
  kitchens: IKitchens[];
  exclusions_ids: IExclusions[];
}

interface IStyledBox {
  horizontalView: boolean;
}

const sortSelectOptions = [
  {
    label: "Cena rosnąco",
    value: "price-asc"
  },
  {
    label: "Cena malejąco",
    value: "price-desc"
  }
];

const ingredientsOptions = [
  {value: "goat_cheese", label: "Ser Kozi"},
  {value: "brussels", label: "Brukselka"},
  {value: "lemongrass", label: "Trawa cytrynowa"},
  {value: "cinnamon", label: "Cynamon"},
  {value: "coriander", label: "Kolendra"},
  {value: "avocado", label: "Awokado"}
];

const dishTypes = [
  "Kanapki",
  "Zupy",
  "Dania główne",
  "Dania główne 2",
  "Dania główne 3",
  "COŚ"
];

const ShowMore = styled(Typography)`
  ${({theme}) => `
    color: ${theme.colors.text.lighter};
  `};
  height: 17px;
  cursor: pointer;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
`;
const StyledBox = styled(Box) <IStyledBox>`
  ${({horizontalView}) => `
    width: ${horizontalView ? "100%" : "auto"};
  `}
`;
const FaIcon = styled(FontAwesomeIcon)`
  align-self: center;
  color: #c0c5cd;
  cursor: pointer;
  font-size: 16px;
  ${({theme}) => `
    margin-top: ${theme.space[3]}px;
  `};
`;
const IconListUl = styled(FaIcon).attrs({
  icon: faListUl
}) <{ active?: boolean }>`
  ${({theme, active}) => `
    ${active ? ` color: ${theme.colors.primary}; ` : ` color: #c0c5cd; `}
  `}
`;
const IconThLarge = styled(FaIcon).attrs({
  icon: faThLarge
}) <{ active?: boolean }>`
  ${({theme, active}) => `
    margin-left: ${theme.space[4]}px;
    margin-right: ${theme.space[4]}px;
    ${active ? ` color: ${theme.colors.primary}; ` : ` color: #c0c5cd; `}
  `}
`;

export const SearchView = () => {
  const parsedURL = parseURL();
  const {profile, wallet, role} = useGlobalStateContext();
  const walletValue = Math.floor(wallet?.attributes?.amount || 0)
  const filtersPrepared = prepareFiltersFromURL();
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  // variables for selection product to change scheduled meal
  const [selectedProduct, setSelectedProduct] = useState<number | null>(null);
  const [selectedMealId, setSelectedMealId] = useState<number | null>(null);
  const [selectedDayTime, setSelectedDayTime] = useState<string | undefined>(
    parsedURL.selected_day_time ? `${parsedURL.selected_day_time}` : undefined
  );
  const [selectedProductPrice, setSelectedProductPrice] = useState<number | undefined>(
    parsedURL.product_price ? +parsedURL.product_price : undefined
  );
  // variables for "show more" action for checkboxes
  const [dishTypeCount, setDishTypeCount] = useState(3);
  const [tagsCount, setTagsCount] = useState(3);
  const [kitchensCount, setKitchensCount] = useState(3);

  const [isTaxonsFetched, setIsTaxonsFetched] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [kitchens, setKitchens] = useState<IKitchens[]>([]);
  const [popularTags, setPopularTags] = useState<IPopularTag[]>([]);
  const [exclusions, setExclusions] = useState<IExclusions[]>([]);
  const [mealTypes, setMealTypes] = useState<IMealType[]>([]);
  const [vendors, setVendors] = useState<IProductsVendor[]>([]);
  const [showHorizontalList, setShowHorizontalList] = useState<boolean>(false);
  const [products, setProducts] = useState<IFetchProductsSingleItem["data"][]>([]);
  const [pagination, setPagination] = useState<PaginationType | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(filtersPrepared.page || 1);

  // container for filters from URL
  const [filters, setFilters] = useState<Omit<ISearchFilters, "page">>({
    meal_types_names: filtersPrepared.meal_types_names || [],
    kitchens: filtersPrepared.kitchens || [],
    popular_tags: filtersPrepared.popular_tags || [],
    exclusions_ids: profile?.data.relationships.taxons.data.map(el => el.id) || filtersPrepared.exclusions_ids || []
  });
  const [search, setSearch] = useState("");
  const controllerRef = useRef<AbortController | null>();


  const history = useHistory();
  const location = useLocation();
  const productListRef = useRef<HTMLDivElement>(null);

  const {register, watch, reset, control, setValue} = useForm<IRegisterSearchForm>();
  const hookValues = watch();

  useEffect(() => {
    setConfigurationAfterNutritionPlansViewRedirect(
      parseURL(),
      setSelectedMealId,
      setSelectedDayTime
    );

    const valuesForForm: { [key: string]: any } = {
      meal_types_names: [],
      kitchens: [],
      popular_tags: [],
      exclusions_ids: []
    };

    fetchMealTypes().then(res => {
      setMealTypes(res.data.map((mealType, index) => {
        valuesForForm.meal_types_names[index] = {
          name: mealType.attributes.name,
          checked: filters.meal_types_names.includes(mealType.attributes.name)
        };
        return {name: mealType.attributes.name, checked: false};
      }));
    });

    // fetch taxons and check checkboxes with filter values from URL
    // fetchTaxons(["tags", "kitchens", "ingredients", "allergens"]).then(response => {
    fetchTaxons(["tags", "kitchens", "ingredients"]).then(response => {
      setKitchens(response.kitchens.included.map((el, index) => {
        valuesForForm.kitchens[index] = {
          id: Number(el.id),
          name: el.attributes.name,
          checked: filters.kitchens.includes(el.id)
        };
        return {id: Number(el.id), name: el.attributes.name, checked: false};
      }));
      setPopularTags(response.tags.included.map((el, index) => {
        valuesForForm.popular_tags[index] = {
          id: Number(el.id),
          name: el.attributes.name,
          checked: filters.popular_tags && filters.popular_tags.includes(el.id)
        };
        return {id: Number(el.id), name: el.attributes.name, checked: false};
      }));

      // const taxonExclusions = [...response.allergens.included, ...response.ingredients.included];
      const taxonExclusions = [/*...response.allergens.included,*/ ...response.ingredients.included];
      setExclusions(taxonExclusions.map((el, index) => {
        if (filters.exclusions_ids.includes(el.id)) {
          valuesForForm.exclusions_ids.push({
            id: Number(el.id),
            label: el.attributes.name,
            value: el.id
          });
        }
        return {id: Number(el.id), label: el.attributes.name, value: el.id};
      }));
    })
      .then(() => {
        reset(valuesForForm);
        setIsTaxonsFetched(true);
      })
      .catch(e => console.log(e));

    return () => {
      setProducts([]);
      setPagination(null);
    };
  }, []);

  const fetchProductsFiltered = () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    const controller = new AbortController();
    controllerRef.current = controller;

    const searchFilter = search.length > 0 ? {"product_names[]": search} : {}
    history.push(`products?${qs.stringify({...filters, page: currentPage, ...searchFilter}, {
      arrayFormat: "brackets",
      encode: false
    })}`);

    fetchInitProducts(convertFiltersForProductsFetching(
        {
          ...filters,
          page: currentPage,
          "product_names": search,
          selected_day_time: selectedDayTime
        }),
      (response) => {
        setProducts(response.data);
        setVendors(response.included);
        setPagination(response.meta);
        if (response.meta.total_pages < currentPage) setCurrentPage(1);
      },
      // @ts-ignore
      controllerRef
    );
  };

  useDebounce(() => {
      fetchProductsFiltered()
    }, [search], 500
  );

  useEffect(() => {
    if (isTaxonsFetched) fetchProductsFiltered();
  }, [filters, isTaxonsFetched]);

  // check if user has exclusions and (if yes) set it to form and URL
  useEffect(() => {
    if (profile && isTaxonsFetched && !checkFiltersExists()) {
      const profileTaxonsIds = profile.data.relationships.taxons.data.map(el => el.id);
      if (profileTaxonsIds.length > 0) {
        setValue("exclusions_ids", exclusions.filter(exclusion => profileTaxonsIds.includes(exclusion.id.toString())));
      }
    }
  }, [profile, isTaxonsFetched]);

  // prevent infinite rerender
  useEffect(() => {
    if (Object.keys(hookValues).length < 2) return;

    const updatedFilters: Omit<ISearchFilters, "page"> = {
      meal_types_names: [],
      kitchens: [],
      exclusions_ids: [],
      popular_tags: []
    };
    Object.keys(hookValues).forEach(filterName => {
      if (filterName === "meal_types_names") {
        updatedFilters.meal_types_names = hookValues["meal_types_names"] && hookValues["meal_types_names"]
          .filter(el => el.checked).map(el => el.name);
      } else if (filterName === "exclusions_ids") {
        updatedFilters.exclusions_ids = hookValues["exclusions_ids"] && hookValues["exclusions_ids"].map(el => el.id.toString());
      } else {
        // @ts-ignore
        updatedFilters[filterName] = hookValues[filterName] && hookValues[filterName].filter(el => el.checked).map(el => el.id.toString());
      }
    });

    if (JSON.stringify(filters) !== JSON.stringify(updatedFilters)) setFilters(updatedFilters);
  }, [hookValues]);

  useEffect(() => {
    if (productListRef.current !== null) {
      productListRef.current.scrollIntoView({behavior: "smooth"});
    }
    fetchProductsFiltered();
  }, [currentPage]);

  // todo
  useEffect(() => {
    const isAlert = localStorage.getItem("onboarding-alert");
    const val = isAlert !== 'false' && role !== 'courier'
    isAlert && setIsSettingsModalOpen(val);
    fetchUser()
      .then(response => {
        const checkResult = checkRegistrationStatus("/products", response.data.relationships.subscriptions.data.length);
        if (checkResult !== "/products") history.push(checkResult);
      })
      .catch(e => {
        history.push("/");
      });
  }, []);

  // functions for changing products in scheduled meals
  const handleProductSelected = (productId: number) => {
    setSelectedProduct(productId);
    setIsModalOpen(true);
  };

  const handlePriceDifference = (newProductPrice: number, selectedProductPrice: number) => {
    if (newProductPrice > selectedProductPrice) {
      if (walletValue < (newProductPrice - selectedProductPrice)) {
        toast.error('Przykro nam nie możemy zamienić posiłku ponieważ w Twojej skarbonce nie wystarczy środków. Po doładowaniu skarbonki będziesz mógł(a) zmienić swój posiłek.')
      } else {
        toast.success('Kwota wynikająca z różnicy cenowej posiłków zostanie pobrana z Twojej skarbonki.')
      }
    }
  }

  const handleSelectionSubmit = () => {
    if (null === selectedProduct || null === selectedMealId || null === selectedDayTime) return;
    const newProductPrice = Number(products.find(el => +el.id === selectedProduct)?.attributes?.price || 0)
    updateScheduledMeal(selectedMealId, selectedProduct)
      .then((response) => {
        // todo test 27.02.23
        selectedProductPrice && handlePriceDifference(newProductPrice, selectedProductPrice)
        history.push("/nutrition-plans?" + qs.stringify({selected_day_time: selectedDayTime}));
      })
      .catch(err => {
        toast.error(err.message === "New product price is too high"
          ? "Cena produktu jest za wysoka."
          : "wymiana posilku nie jest mozliwa"
        );
      })
      .finally(() => {
        setSelectedProduct(null);
        setIsModalOpen(false);
      });
  };

  const getOptionsButton = (product: IFetchProductsSingleItem["data"]) => {
    return selectedMealId
      ? (selectedProductPrice && walletValue < (+product.attributes.price - selectedProductPrice)
        ? {
          label: "Doładuj skarbonkę",
          onClick: () => history.push('/wallet')
        }
        : {
          label: "Wybierz",
          onClick: () => handleProductSelected(Number(product.id))
        })
      : undefined
  }

  const handleLikeProduct = (id: number) => {
    const productToRate = products.find(el => +el.id === id);
    if (!productToRate) return;
    const method = productToRate.attributes.current_user_favourite_product
      ? unlikeProduct
      : likeProduct

    method(id)
      .then((res) => {
        setProducts(prevState => {
          return prevState.map(el => {
            if (+el.id !== id) return el;
            el.attributes.current_user_favourite_product = res.data.attributes.current_user_favourite_product;
            return el;
          });
        });
      })
      .catch(() => toast.error("Wystąpił błąd."));
  };

  return (
    <div ref={productListRef}>
      <MainLayout>
        <Flex alignItems="center" mb={3}>
          <Col xs={3}>
            <Typography variant="h1">
              Przeglądaj dania {selectedDayTime ? `(dostępne na ${formatDate(new Date(selectedDayTime))})` : ""}
            </Typography>
          </Col>
          <Col xs={9}>
            <Flex alignItems="center">
              <Box width="30%" mr="auto" pl={2}>
                <TextField
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  transparent
                  label="Szukaj dania"
                />
              </Box>
              {/* width="75%" */}
              {/* <Box width="100%" mr={8}>
              <DatePickerInput
                fullWidth
                transparent
                label="Wybierz dzień"
                day={new Date()}
                onChange={(day) => console.log(day)}
              />
            </Box>
            <Box width="100%">
              <Select
                transparent
                options={sortSelectOptions}
                label="Sortowanie"
              />
            </Box> */}
              <IconThLarge
                onClick={() => {
                  setShowHorizontalList(false);
                }}
                active={!showHorizontalList}
              />
              <IconListUl
                onClick={() => {
                  setShowHorizontalList(true);
                }}
                active={showHorizontalList}
              />
            </Flex>
          </Col>
        </Flex>
        <Row>
          <Col xs={3}>
            <Box mb={8}>
              <Typography variant="h4" mb={3}>
                Posiłki
              </Typography>
              {mealTypes.map((el, index) =>
                <Box key={el.name} mb={2}>
                  {/*// @ts-ignore*/}
                  <Checkbox label={el.name} {...register(`meal_types_names.${index}.checked`)} />
                </Box>
              )}
            </Box>

            {/*<Box mb={8}>*/}
            {/*  <Typography variant="h4" mb={3}>*/}
            {/*    Typ dania*/}
            {/*  </Typography>*/}
            {/*  {dishTypes.slice(0, dishTypeCount).map(dishType => (*/}
            {/*    <Box key={dishType} mb={4}>*/}
            {/*      <Checkbox label={dishType} />*/}
            {/*    </Box>*/}
            {/*  ))}*/}
            {/*  {(dishTypes.length > 3 && dishTypeCount < dishTypes.length) &&*/}
            {/*  <ShowMore onClick={() => {*/}
            {/*    setDishTypeCount(dishTypes.length);*/}
            {/*  }}>pokaż wiecej ({dishTypes.slice(-(dishTypes.length - dishTypeCount)).length})</ShowMore>*/}
            {/*  }*/}
            {/*</Box>*/}
            {/*<Box mb={8}>*/}
            {/*  <Typography variant="h4" mb={3}>*/}
            {/*    Kaloryczność*/}
            {/*  </Typography>*/}
            {/*  <Flex>*/}
            {/*    <Box width={88} mr={4}>*/}
            {/*      <TextField transparent label="od" type="number" />*/}
            {/*    </Box>*/}
            {/*    <Box width={88}>*/}
            {/*      <TextField transparent label="do" type="number" />*/}
            {/*    </Box>*/}
            {/*  </Flex>*/}
            {/*</Box>*/}

            {popularTags &&
              <Box mb={8}>
                <Typography variant="h4" mb={3}>
                  Popularne tagi
                </Typography>
                {popularTags.slice(0, tagsCount).map((el, index) =>
                  <Box mb={2} key={el.id}>
                    {/*// @ts-ignore*/}
                    <Checkbox label={el.name} {...register(`popular_tags.${index}.checked`)} />
                  </Box>
                )}
                {(popularTags.length > 3 && tagsCount < popularTags.length) &&
                  <ShowMore onClick={() => {
                    setTagsCount(popularTags.length);
                  }}>pokaż wiecej ({popularTags.slice(-(popularTags.length - tagsCount)).length})</ShowMore>
                }
              </Box>
            }

            {kitchens &&
              <Box mb={8}>
                <Typography variant="h4" mb={3}>
                  Kuchnia
                </Typography>
                {/*.slice(0, kitchensCount)*/}
                {kitchens.slice(0, kitchensCount).map((el, index) =>
                  <Box mb={2} key={el.id}>
                    {/*// @ts-ignore*/}
                    <Checkbox label={el.name} {...register(`kitchens.${index}.checked`)} />
                  </Box>
                )}
                {(kitchens.length > 3 && kitchensCount < kitchens.length) &&
                  <ShowMore onClick={() => {
                    setKitchensCount(kitchens.length);
                  }}>pokaż wiecej ({kitchens.slice(-(kitchens.length - kitchensCount)).length})</ShowMore>
                }
              </Box>
            }
            {/*<Box mb={8}>*/}
            {/*  <Typography variant="h4" mb={3}>*/}
            {/*    Białka*/}
            {/*  </Typography>*/}
            {/*  <Box mb={4}>2/}
            {/*    <Checkbox label="Wołowina" />*/}
            {/*  </Box>*/}
            {/*  <Box mb={4}>2/}
            {/*    <Checkbox label="Wieprzowina" />*/}
            {/*  </Box>*/}
            {/*  <Box mb={4}>2/}
            {/*    <Checkbox label="Kurczak" />*/}
            {/*  </Box>*/}
            {/*  <Box mb={4}>2/}
            {/*    <Checkbox label="Indyk" />*/}
            {/*  </Box>*/}
            {/*  <Box mb={4}>2/}
            {/*    <Checkbox label="Ryby" />*/}
            {/*  </Box>*/}
            {/*</Box>*/}
            {/*<Box mb={8}>*/}
            {/*  <Typography variant="h4" mb={3}>*/}
            {/*    Węglodowodany*/}
            {/*  </Typography>*/}
            {/*  <Box mb={4}>2/}
            {/*    <Checkbox label="Makaron" />*/}
            {/*  </Box>*/}
            {/*  <Box mb={4}>2/}
            {/*    <Checkbox label="Ryż" />*/}
            {/*  </Box>*/}
            {/*  <Box mb={4}>2/}
            {/*    <Checkbox label="Kasza" />*/}
            {/*  </Box>*/}
            {/*  <Box mb={4}>2/}
            {/*    <Checkbox label="Warzywa strączkowe" />*/}
            {/*  </Box>*/}
            {/*  <Box mb={4}>2/}
            {/*    <Checkbox label="Mączne" />*/}
            {/*  </Box>*/}
            {/*</Box>*/}
            {/*<Box mb={4}>2/}
            {/*  <Typography variant="h4" mb={3}>*/}
            {/*    Uwzględnij*/}
            {/*  </Typography>*/}
            {/*  <Tags transparent label="label" options={ingredientsOptions} />*/}
            {/*</Box>*/}
            <Box mb={8}>
              <Typography variant="h4" mb={3}>
                Wyklucz
              </Typography>
              {/*selectedOptions={getExclusionsPrepared(watcher.ingredients)}*/}
              <Controller
                control={control}
                name="exclusions_ids"
                render={({field: {onChange}}) => (
                  <Tags
                    transparent
                    selectedOptions={hookValues.exclusions_ids}
                    options={exclusions}
                    onChange={(selectedExclusions: IExclusions[]) => onChange(selectedExclusions)}
                  />
                )}
              />
            </Box>
            {/*<Box mb={8}>*/}
            {/*  <Typography variant="h4" mb={3}>*/}
            {/*    Marka*/}
            {/*  </Typography>*/}
            {/*  <Box mb={2}>*/}
            {/*    <Checkbox label="Fitcare" />*/}
            {/*  </Box>*/}
            {/*  <Box mb={2}>*/}
            {/*    <Checkbox label="Maczfit" />*/}
            {/*  </Box>*/}
            {/*  <Box mb={2}>*/}
            {/*    <Checkbox label="Salad story" />*/}
            {/*  </Box>*/}
            {/*  <Box mb={2}>*/}
            {/*    <Checkbox label="Pan kanapka" />*/}
            {/*  </Box>*/}
            {/*  <Box mb={2}>*/}
            {/*    <Checkbox label="Kokos" />*/}
            {/*  </Box>*/}
            {/*</Box>*/}
          </Col>
          <Col xs={9}>
            <Flex justifyContent="space-between" width="100%" flexWrap="wrap">
              {(products && vendors) &&
                products.map((product, index) => (
                  <StyledBox
                    horizontalView={showHorizontalList}
                    my={4}
                    key={index}
                  >
                    <DishOffer
                      vendor={vendors.find(el => el.id === product?.relationships?.vendor?.data?.id)}
                      dish={product}
                      onLike={handleLikeProduct}
                      horizontal={!showHorizontalList}
                      optionsButton={getOptionsButton(product)}
                    />
                  </StyledBox>
                ))}
            </Flex>
            {pagination &&
              <Flex justifyContent="center">
                <Pagination
                  current={currentPage}
                  pages={pagination.total_pages}
                  onPageClick={pageNumber => setCurrentPage(pageNumber)}
                />
              </Flex>
            }
          </Col>
        </Row>
      </MainLayout>
      <ChangeProductModal
        isOpen={isModalOpen}
        onCancelClick={() => {
          setSelectedProduct(null);
          setIsModalOpen(false);
        }}
        onSubmit={handleSelectionSubmit}
      />
      <SettingsModal
        isOpen={isSettingsModalOpen}
        onCancelClick={() => {
          setIsSettingsModalOpen(false);
          localStorage.setItem("onboarding-alert", "false");
        }}
      />
    </div>
  );
};
