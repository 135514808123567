import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import React, { useState } from "react";
import { Box } from "components/Box";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { InputGroup } from "components/InputGroup";
import { InputRange } from "components/InputRange/InputRange";
import { fetchSubscriptions, IUpdateUser, updateUser } from "services/user";
import { Radio } from "components/Radio";
import { Typography } from "components/Typography";
import { RegisterFormStatus } from "containers/RegisterFormStatus";
import {
  useRegisterFormDispatchContext,
  useRegisterFormStateContext
} from "contexts/RegisterFormContext";
import { LoginRegisterLayout } from "layouts/LoginRegisterLayout";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { DatePickerInput } from "components/DatePickerInput/DatePickerInput";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { formatDateConstructable } from "../utilities/date";

export interface IRangeFields {
  name: "weight" | "target_weight" | "height";
  label: string;
  min: number;
  max: number;
  step?: number;
  speechBubble?: boolean;
}

export interface IRegisterHealthInterviewFormData {
  type: "company" | "person";
  gender: "male" | "female";
  birthday: string;
  weight: number;
  target_weight: number;
  height: number;
  physical_activity: number;
}

export const rangeFields: IRangeFields[] = [
  {
    name: "weight",
    label: "Waga",
    min: 18,
    max: 150
  },
  {
    name: "target_weight",
    label: "Waga docelowa",
    min: 18,
    max: 150
  },
  {
    name: "height",
    label: "Wzrost",
    min: 100,
    max: 220
  },
];

export const parsedValue = ({
                              type,
                              gender,
                              birthday,
                              weight,
                              target_weight,
                              height
                            }: IRegisterHealthInterviewFormData) => {
  // const isoDate = new Date(birthday).toISOString();
  return {
    is_company: type === "company",
    gender,
    birthday,
    weight,
    target_weight,
    height
  };
};

export const RegisterHealthInterviewView: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory();
  const { formsData } = useRegisterFormStateContext();
  const { setActiveStep, setFormsData } = useRegisterFormDispatchContext();

  const { register, handleSubmit, watch, control, formState: { errors } } =
    useForm<IRegisterHealthInterviewFormData>({
      defaultValues: {
        type: formsData?.type ?? "person",
        gender: formsData?.gender ?? "male",
        birthday: formsData?.birthday ?? undefined,
        weight: formsData?.weight ?? 62,
        target_weight: formsData?.target_weight ?? 62,
        height: formsData?.height ?? 165
      }
    });

  const onSubmit = async (data: IRegisterHealthInterviewFormData) => {
    if (data.weight + 21 < data.target_weight) {
      toast.error("Waga docelowa nie może przekraczać wagi + 21 kg");
      return
    }
    if (data.weight - 21 > data.target_weight) {
      toast.error("Waga docelowa nie może być mniejsza niż waga - 21 kg.");
      return
    }

    try {
      setIsLoading(true)
      const birthday = formatDateConstructable(new Date(data.birthday))
      const parsedData = parsedValue({ ...data, birthday });
      setFormsData({ ...data, birthday });

      await updateUser(parsedData as IUpdateUser);
      history.push("/register/diet");
    } catch (error) {
      toast.error("Coś poszło nie tak.");
    } finally {
      setIsLoading(false)
    }
  };

  useEffect(() => {
    setActiveStep(0);
  }, []);

  const maxDay = new Date();
  maxDay.setFullYear(maxDay.getFullYear() - 5);
  const minDay = new Date();
  minDay.setFullYear(minDay.getFullYear() - 120);

  return (
    <LoginRegisterLayout
      left={
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h1" mb={6}>
            Profil
          </Typography>
          <Box mb={3}>
            <InputGroup label="Typ">
              <Radio
                label="Osoba prywatna"
                value="person"
                {...register("type")}
              />
              {/*<Radio label="Firma" value="company" {...register("type")} />*/}
            </InputGroup>
          </Box>
          {!watch("type") || watch("type") === "person" ? (
            <>
              <Box mb={4}>
                <InputGroup label="Płeć">
                  <Radio
                    value="male"
                    label="Mężczyzna"
                    {...register("gender")}
                  />
                  <Radio value="female" label="Kobieta" {...register("gender")} />
                </InputGroup>
              </Box>

              <Box mb={6}>
                <Controller
                  control={control}
                  name="birthday"
                  render={({ field: { value, onChange } }) => (
                    <DatePickerInput
                      maxDate={maxDay}
                      minDate={minDay}
                      width="100%"
                      required
                      placeholderText='dd.mm.yyyy'
                      label="Data urodzenia"
                      day={value ? new Date(value) : undefined}
                      transparent
                      onChange={(date) => {
                        onChange(date ? new Date(date) : undefined);
                      }}
                    />
                  )}
                />
              </Box>

              {rangeFields.map((field: IRangeFields) => (
                <Box mb={6} key={field.name}>
                  <Typography variant="label">{field.label}</Typography>
                  <Controller
                    control={control}
                    name={field.name}
                    render={({ field: { value, onChange } }) => (
                      <InputRange
                        min={field.min}
                        max={field.max}
                        value={value}
                        step={field.step}
                        onChange={(value) => {
                          onChange(value);
                        }}
                      />
                    )}
                  />
                </Box>
              ))}
            </>
          ) : (
            <Typography variant="h4" fontWeight="300" my={10}>
              Przy prowadzeniu zamówienia jako firma, pomijamy dane wywiad
              zdrowotny oraz nie ustawiamy wartości odżywczych, jeżeli chciałbyś
              spersonalizować dietę pod siebie oraz dostać fakturę, zaznacz pole
              osoba prywatna, dane do faktury będą pobrane później.
            </Typography>
          )}

          <Flex justifyContent="flex-end">
            <Button variant="primary" disabled={isLoading}>Dalej</Button>
          </Flex>
        </form>
      }
      right={
        <RegisterFormStatus
          content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam pulvinar sapien id leo fringilla, nec posuere tortor viverra. Morbi laoreet ligula ut ex vestibulum, quis viverra nulla egestas. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Fusce eu quam vehicula lacus pellentesque tincidunt ac id ex. " />
      }
    />
  );
};
