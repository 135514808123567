import styled from "styled-components";
import {
  space,
  SpaceProps,
  ColorProps,
  BackgroundColorProps,
  FontWeightProps,
  system,
  variant,
} from "styled-system";

interface Props extends ColorProps, BackgroundColorProps, FontWeightProps, SpaceProps {
  variant?: "primary" | "secondary" | "cancel";
  fullWidth?: boolean;
}

export const Button = styled.button<Props>`
  ${({ theme, fullWidth }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    ${fullWidth ? "width: 100%" : ""};
    height: 40px;
    padding: 0 ${theme.space[4]}px;
    font-size: 12px;
    line-height: 16px;
    color: #6D7278;
    background-color: #f3f4f6;
    border-radius: ${theme.radii[2]}px;
    border: 0;
    cursor: pointer;
  `}
  ${variant({
    variants: {
      primary: {
        color: "neutral.white",
        backgroundColor: "primary",
        fontWeight: "bold",
      },
      secondary: {
        color: "neutral.white",
        backgroundColor: "secondary",
        fontWeight: "bold",
      },
      cancel: {
        color: "neutral.black",
        backgroundColor: "neutral.light",
        fontWeight: "bold",
      },
    },
  })}
  ${system({
    color: {
      property: "color",
      scale: "colors",
    },
    backgroundColor: {
      property: "color",
      scale: "colors",
    },
    fontWeight: true,
  })}
  ${space}
`;
