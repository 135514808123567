import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { IFetchUser, UserWallet } from "services/user";
import { getUserWallet } from "../utilities/profile";
import { useSubscriptionDays } from "../hooks/subscriptionDays";

export interface IGlobalStateContext {
  profile: IFetchUser | null;
  role: "courier" | "user";
  wallet: UserWallet | null;
  subscriptionDays: Date[];
  paidSubscriptionDays: Date[];
  waitingSubscriptionDays: Date[];
}

export interface IGlobalStateDispatchContext {
  setProfile: (profile: IFetchUser | null) => void;
  setSubscriptionDays: Dispatch<SetStateAction<Date[]>>
  setPaidSubscriptionDays: Dispatch<SetStateAction<Date[]>>
  setReFetchSubscriptions: Dispatch<SetStateAction<boolean>>
  setWaitingSubscriptionDays: Dispatch<SetStateAction<Date[]>>
  fetchAndPrepareSubscriptions: () => void
}

const GlobalStateContext = React.createContext<IGlobalStateContext | undefined>(undefined);

const GlobalStateDispatchContext = React.createContext<IGlobalStateDispatchContext | undefined>(undefined);

export const GlobalStateContextProvider: React.FC = ({ children }) => {
  const [reFetchSubscriptions, setReFetchSubscriptions] = useState<boolean>(false);
  const [profile, setProfile] = useState<IFetchUser | null>(null);
  const [wallet, setWallet] = useState<UserWallet | null>(null);
  const {
    subscriptionDays,
    setSubscriptionDays,
    paidSubscriptionDays,
    setPaidSubscriptionDays,
    waitingSubscriptionDays,
    setWaitingSubscriptionDays,
    fetchAndPrepareSubscriptions
  } = useSubscriptionDays(!!profile, reFetchSubscriptions);

  const role = (profile?.data.attributes?.roles && profile?.data.attributes?.roles[0] === 'courier') ? "courier" : 'user'

  return (
    <GlobalStateContext.Provider value={{
      profile,
      wallet,
      subscriptionDays,
      paidSubscriptionDays,
      waitingSubscriptionDays,
      role
    }}>
      <GlobalStateDispatchContext.Provider
        value={{
          setProfile: (profile: IFetchUser | null) => {
            setProfile(profile);
            if (!profile) {
              setWallet(null);
              setSubscriptionDays([]);
              return;
            }
            const wallets = getUserWallet(profile);
            if (wallets.length > 0) setWallet(wallets[0] as UserWallet);
          },
          setSubscriptionDays,
          setPaidSubscriptionDays,
          setReFetchSubscriptions,
          setWaitingSubscriptionDays,
          fetchAndPrepareSubscriptions
        }}
      >
        {children}
      </GlobalStateDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export const useGlobalStateContext = () => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error(
      "useGlobalStateContext must be inside a GlobalStateContextProvider with a value"
    );
  }
  return context;
};

export const useGlobalStateDispatchContext = () => {
  const context = useContext(GlobalStateDispatchContext);
  if (!context) {
    throw new Error(
      "useGlobalStateDispatchContext must be inside a GlobalStateDispatchProvider with a value"
    );
  }
  return context;
};
