import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Modal } from "components/Modal/Modal";
import { Typography } from "components/Typography";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Box } from "components/Box";
import { Calendar } from "components/Calendar/Calendar";
import { getDisabledDays } from "utilities/calendar";
import { IFetchSubscription, moveSubscription } from "services/user";
import { toast } from "react-toastify";
import { formatDate } from "utilities/date";

export interface IChangeDayModal {
  subscriptions: Date[];
  handleDayMoved: (oldDate: Date, newDate: Date) => void;
  subscription: IFetchSubscription | null;
  dayToMove: Date | null;
  isOpen: boolean;
  onCancelClick: () => void;
}

export const ChangeDayModal: React.FC<IChangeDayModal> = ({
                                                            isOpen,
                                                            subscriptions,
                                                            handleDayMoved,
                                                            subscription,
                                                            dayToMove,
                                                            onCancelClick
                                                          }) => {
  const [disabledDays, setDisabledDays] = useState<Date[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const onSubmit = () => {
    if (!dayToMove || !subscription || !selectedDate) {
      toast.error("Nie udało się zmienić daty. Spróbuj ponownie.");
      return;
    }
    moveSubscription({
      subscription: {
        id: Number(subscription.subscription.data[subscription.subscription.data.length - 1].id),
        old_date: formatDate(dayToMove),
        new_date: formatDate(selectedDate)
      }
    }).then((res) => {
      handleDayMoved(dayToMove, new Date(res.subscription_day.data.attributes.date))
      toast.success("Data została zmieniona.");
    }).catch(() => toast.error("Nie udało się zmienić daty. Spróbuj ponownie."))
      .finally(() => onCancelClick());
  };

  const handleDayChanged = (day: Date) => {
    setSelectedDate(day);
  };

  useEffect(() => {
    setDisabledDays([...getDisabledDays(), ...subscriptions]);
  }, [subscriptions]);

  return (
    <Modal isOpen={isOpen} onCancelClick={onCancelClick}>
      <Box mt={8} mr={9} mb={10} ml={10} width="500px">
        <Typography variant="h1">Przenieś na:</Typography>
        <Box mt={6}>
          <Calendar
            onDayChanged={handleDayChanged}
            disabledDays={disabledDays}
            disablePastDays
            selectOneDay
          />
        </Box>

        <Flex justifyContent="flex-end" alignItems="center" mt={4}>
          <Flex justifyContent="space-between">
            <Box mx={4}>
              <Button onClick={onCancelClick} variant="secondary">Anuluj</Button>
            </Box>
            <Button onClick={onSubmit} variant="primary">Przenieś</Button>
          </Flex>
        </Flex>
      </Box>
    </Modal>
  );
};
