import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";
import { Typography } from "./Typography";

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

const Label = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

const Control = styled.div`
  ${({ theme }) => `
    flex: 0 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border: ${theme.inputBorder};
    border-radius: 50%;
  `}
`;

const Input = styled.input`
  ${({ theme }) => `
    position: relative;
    opacity: 0;
    width: 16px;
    height: 16px;
    position: absolute;

    &:checked {
      + ${Control} {
        border-color: ${theme.colors.primary};
        border-width: 8px;
      }
    }
  `}
`;

export const Radio = React.forwardRef<HTMLInputElement, Props>(
  ({ label, ...props }, ref) => (
    <Label>
      <Input type="radio" {...props} ref={ref} />
      <Control />
      <Typography color="text.light">{label}</Typography>
    </Label>
  )
);
