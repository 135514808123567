import {Box} from "components/Box";
import {Typography} from "components/Typography";
import {useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {TextField} from "components/TextField";
import {yupResolver} from "@hookform/resolvers/yup";
import {updateProfileSchema} from "schemas/register";
import {IRegisterFormData} from "views/RegisterView";
import {Flex} from "components/Flex";
import styled from "styled-components";
import {Radio} from "components/Radio";
import {InputGroup} from "components/InputGroup";
import {IRegisterHealthInterviewFormData} from "views/RegisterHealthInterviewView";
import {Button} from "components/Button";
import {useSettingsFormDispatchContext, useSettingsFormStateContext} from "contexts/SettingsFormContext";
import {updateUser} from "services/user";
import {toast} from "react-toastify";
import {Col, Row} from "react-grid-system";

const Link = styled.p`
  text-align: end;
  text-decoration: underline;
  cursor: pointer;
  ${({theme}) => `
    color: ${theme.colors.primary};
    margin-top: ${theme.space[2]}px;
  `}
`;

export const SettingsProfileView: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const {formsData} = useSettingsFormStateContext();
  const {setFormsData} = useSettingsFormDispatchContext();

  const {
    register,
    handleSubmit,
    formState: {errors},
    setValue
  } = useForm<IRegisterFormData & IRegisterHealthInterviewFormData>({
    defaultValues: {
      email: formsData.email || "",
      // old_password: "",
      password: "********",
      type: formsData.type || "person",
      gender: formsData.gender || "male"
    },
    resolver: yupResolver(updateProfileSchema)
  });

  useEffect(() => {
    if (!editPassword) {
      setValue('password', '********')
      return
    }
    // setValue('old_password', '')
    setValue('password', '')
    setValue('password_confirmation', '')
  }, [editPassword])

  const onSubmit = (data: IRegisterFormData & IRegisterHealthInterviewFormData) => {
    console.log(data.email, formsData.email)
    setIsLoading(true);
    const payload: { [key: string]: any } = {
      email: data.email,
      is_company: data.type === "company",
      gender: data.gender
    };
    if (data?.password_confirmation?.length > 0) {
      // payload.old_password = data.old_password
      payload.password = data.password
      payload.password_confirmation = data.password_confirmation
    }

    setFormsData({...formsData, ...payload});
    updateUser(payload)
      .then((response) => {
        toast.success("Zmiany zostały zapisane.");
        if (data.email !== formsData.email) {
          toast.success("Sprawdź swój poprzedni email.");
        }
        if (payload.password_confirmation && payload.password_confirmation.length > 0) {
          toast.success("Hasło zostało zmienione.");
          setEditPassword(false)
        }
      })
      .catch(() => toast.error("Coś poszło nie tak."))
      .finally(() => setIsLoading(false));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h2">Dane logowania</Typography>
      <Box mb={6} mt={3}>
        <Row>
          <Col>
            <TextField
              // disabled
              label="Adres e-mail"
              {...register("email")}
              error={errors?.email?.message}
            />
          </Col>
          <Col>
            {/*{editPassword &&*/}
            {/*  <Box mb={2}>*/}
            {/*    <TextField*/}
            {/*      disabled={!editPassword}*/}
            {/*      type="password"*/}
            {/*      label="Stare hasło"*/}
            {/*      {...register("old_password")}*/}
            {/*      error={errors.old_password?.message}*/}
            {/*    />*/}
            {/*  </Box>*/}
            {/*}*/}
            <TextField
              disabled={!editPassword}
              type="password"
              label="Nowe hasło"
              {...register("password")}
              error={errors.password?.message}
            />
            {editPassword &&
              <Box mt={2}>
                <TextField
                  label="Powtórz nowe hasło"
                  type="password"
                  {...register("password_confirmation", {required: true})}
                  error={errors?.password_confirmation?.message}
                />
              </Box>
            }
          </Col>
        </Row>
        <Link onClick={() => setEditPassword(prevState => !prevState)}>zmień hasło</Link>
      </Box>
      <Typography variant="h2">Dane konta</Typography>
      <Box mb={6} mt={3}>
        {/*<InputGroup label="Rodzaj konta">*/}
        {/*  <Radio*/}
        {/*    label="Osoba prywatna"*/}
        {/*    value="person"*/}
        {/*    {...register("type")}*/}
        {/*  />*/}
        {/*  <Radio label="Firma" value="company" {...register("type")} />*/}
        {/*</InputGroup>*/}

        <Box mt={6}>
          <InputGroup label="Płeć">
            <Radio
              value="male"
              label="Mężczyzna"
              {...register("gender")}
            />
            <Radio
              value="female"
              label="Kobieta"
              {...register("gender")}
            />
          </InputGroup>
        </Box>
      </Box>

      <Flex justifyContent="flex-end">
        <Button variant="primary" disabled={isLoading}>Zapisz zmiany</Button>
      </Flex>
    </form>
  );
};
