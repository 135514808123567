import React, { useEffect, useState } from "react";
import { MainLayout } from "layouts/MainLayout";
import { Col, Row } from "react-grid-system";
import { Tabs } from "components/Tabs/Tabs";
import { IRow, Table } from "components/Table/Table";
import { Person } from "components/Person/Person";
import { Button } from "components/Button";
import { Card } from "components/Card";
import { Typography } from "components/Typography";
import { Box } from "components/Box";
import { cancelSubscription, fetchSubscriptions, fetchUser, IFetchSubscriptions } from "services/user";
import {compareDates, findMinAndMaxDate, formatDate} from "utilities/date";
import { useHistory } from "react-router-dom";
import { useSubscriptionDispatchContext } from "contexts/SubscriptionContext";
import styled from "styled-components";
import { PaymentModal } from "components/PaymentModal/PaymentModal";
import { getPreparedSubscriptions } from "utilities/subscription";
import { fetchPayment, IFetchPayment, postPayment } from "services/payment";
import { toast } from "react-toastify";
import { useGlobalStateContext, useGlobalStateDispatchContext } from "../contexts/GlobalStateContext";
import { useInterval } from "../hooks/interval";
import { useParams } from "react-router";
import { fetchAddresses } from "../services/address";
import { Flex } from "components/Flex";
import {SubscriptionsView} from "./SubscriptionsView";

interface ISubscription {
  id: string
  price: string | null
  price_per_day: number
  user_id: number
  active: boolean
  cancelled?: boolean
  payment?: IFetchPayment
  subscription_days: {
    id: string
    date: Date
  }[]
}

const Tag = styled.div<{ color: string }>`
  ${({ theme, color }) => `
    background-color: ${theme.colors.tag[color]}
  `};
  display: inline-block;
  margin-right: 15px;
  border-radius: 12px;
  padding: 0 12px;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
`;

export const HistoryOrderView = () => {
  const { paymentId } = useParams<{ paymentId: string }>();
  const history = useHistory();

  const [fullName, setFullName] = useState('');
  const [requestStatus, setRequestStatus] = React.useState<string | null>(null);
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
  const [rows, setRows] = useState<IRow[]>([]);

  // for modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amount, setAmount] = useState(150);

  const subscriptionContext = useSubscriptionDispatchContext();
  const { wallet } = useGlobalStateContext();
  const { setProfile, fetchAndPrepareSubscriptions } = useGlobalStateDispatchContext();

  const getPayment = (id: number) => {
    if (Number.isNaN(id) || id === 0) return;
    fetchPayment(id)
      .then(payment => {
        setRequestStatus(payment.data.attributes.status);
        if (payment.data.attributes.status === "completed")
          fetchUser().then(response => setProfile(response));
      })
      .catch(() => toast.error("Nie udało się pobrać płatność."));
  };

  useInterval(
    () => getPayment(Number(paymentId)),
    (paymentId && (requestStatus === null || requestStatus === "pending")) ? 1000 : null
  );

  const delimiters = ["25%", "25%", "15%", "15%", "15%"];
  const columnNames = ["Przypisany użytkownik", "Okres", "Wartość", "Status płatności", null];

  const tabs = [
    {
      label: "Abonamenty", node:
        <Table
          key={rows.length}
          delimiters={delimiters}
          columnNames={columnNames}
          rows={rows}
        />
    },
    {
      label: "Historia", node:
        <SubscriptionsView/>
    }
  ];

  const parseSubscriptions = (data: IFetchSubscriptions) => {
    if (!data || !data.hasOwnProperty("data")) return;
    const preparedSubscriptions = getPreparedSubscriptions(data);
    preparedSubscriptions.forEach(subscription => {
      // check if payment exists
      if (subscription.payments.data[0] !== undefined) {
        // fetch last payment data
        fetchPayment(Number(subscription.payments.data[subscription.payments.data.length - 1].id)).then(payment => {
          const subscriptionWithPayment = { ...subscription, payment };
          if (subscriptions.find(el => el.id === subscription.id) === undefined)
            setSubscriptions(prevState => {
              return [...prevState, subscriptionWithPayment];
            });
        });
      } else {
        setSubscriptions(prevState => {
          return [...prevState, subscription];
        });
      }
    });
  };

  const purchaseSubscription = (days: Date[], price: number, id: number, paymentId?: string) => {
    subscriptionContext.setSubscription({ days, price, id, paymentId });
    history.push("/shopping-cart");
  };

  const handleCancelSubscription = (id: string | number) => {
    cancelSubscription(id).then(res => {
      fetchAndPrepareSubscriptions()
      setSubscriptions(prevState => {
        return prevState.map(el => el.id === res.data.id
          ? {...el, cancelled: true}
          : el
        )
      })
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      prepareRows(subscriptions)
    })
  };

  const getPaymentTagByStatus = (status: "pending" | "failed" | "completed") => {
    if (status === "pending") return <Tag color="primary">Oczekujące</Tag>;
    if (status === "failed") return <Tag color="red">Nie opłacone</Tag>;
    if (status === "completed") return <Tag color="primary">Opłacone</Tag>;
    return <></>;
  };

  function prepareRows(subscriptions: ISubscription[]) {
    const preparedRows = subscriptions
      .filter(subscription => subscription.price_per_day !== null)
      .map(subscription => {
        const dates = subscription.subscription_days.map(el => new Date(el.date));
        const [minDate, maxDate] = findMinAndMaxDate(dates);

        return {
          additionalData: {
            minDate, maxDate
          },
          rowData: [
            <Person key={1} fullName={fullName}
              imgPath="#" />,
            `${formatDate(minDate).toString().split('-').join('.')} - ${formatDate(maxDate).toString().split('-').join('.')}`,
            subscription.price,
            subscription.payment ? getPaymentTagByStatus(subscription.payment.data.attributes.status) : "Nie było płatności",
            subscription.active ? <></> :
              subscription.cancelled
                ? <>Anulowane</>
                : <Flex key={subscription.id + 'sub'}>
                  <Button
                    onClick={() => handleCancelSubscription(subscription.id)}
                    ml="auto"
                    key={subscription.id + 'sub_anuluj'}
                    variant="secondary">Anuluj</Button>
                  <Button
                    onClick={() => purchaseSubscription(
                      dates,
                      Number(subscription.price_per_day),
                      Number(subscription.id),
                      subscription.payment?.data.attributes.status === 'failed' ? undefined : subscription.payment?.data.id)
                    }
                    ml="auto"
                    key={subscription.id + 'sub_oplac'}
                    variant="primary">Opłać</Button>
                </Flex>
          ],
          expandedData: <h1>Lorem ipsum</h1>
        };
      }).filter(el => compareDates(new Date(), el.additionalData.maxDate, '<='));

    if (preparedRows.length > 0 && preparedRows[0] !== undefined) { // @ts-ignore
      setRows(preparedRows);
    }
  };

  const handlePaymentSubmit = () => {
    if (amount < 1) {
      toast.error("Nie udało się doładować skarbonki.");
      return;
    }
    if (wallet && wallet.id)
      postPayment({
        payment: {
          payable_object_id: Number(wallet.id),
          payable_object_type: "wallet",
          amount,
          continue_path: "/history-order/"
        }
      }
      )
        .then(res => {
          // @ts-ignore
          window.location.href = res.data.attributes.url;
        })
        .catch(e => toast.error("Płatność nie powiodła się."));
  };

  useEffect(() => {
    if (fullName) prepareRows(subscriptions);
  }, [subscriptions, fullName]);

  useEffect(() => {
    setSubscriptions([]);
    fetchSubscriptions().then(response => parseSubscriptions(response));

    fetchAddresses()
      .then(response => {
        const shipAddress = response.data.find(el => el.attributes.address_type === "ship address");
        if (shipAddress) {
          setFullName(`${shipAddress.attributes.firstname} ${shipAddress.attributes.lastname}`)
        }
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <MainLayout>
      <Row>
        <Col xs={12}>
          <Tabs tabs={tabs} />
        </Col>
      </Row>
      <PaymentModal
        isOpen={isModalOpen}
        amount={amount}
        setAmount={setAmount}
        onCancelClick={() => {
          setIsModalOpen(false);
        }}
        onSubmit={handlePaymentSubmit}
      />
    </MainLayout>
  );
};
