import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

interface Props extends SpaceProps {}

export const DishTagLabel = styled.div<Props>`
  opacity: 0.5;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 24px;
  ${({ theme }) => `
    padding-left: ${theme.space[3]}px;
    color: ${theme.colors.neutral.black};
  `};
  ${space}
`;
