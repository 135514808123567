import { Button } from "components/Button";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Typography } from "components/Typography";
import React from "react";
import { TextField } from "components/TextField";
import { InputGroup } from "components/InputGroup";
import { Radio } from "components/Radio";
import { Grid } from "../../components/Grid";
import { Checkbox } from "../../components/Checkbox";

export const SettingsPaymentView: React.FC = () => {
  return (
    <>
      <Typography variant="h1" mb={6}>
        Płatność
      </Typography>
      <Box mb={6}>
        <InputGroup label="Wybierz płatność">
          <Radio name="payment-type" value="card" label="Karta płatnicza" />
          <Radio name="payment-type" value="payu" label="PayU" />
        </InputGroup>
      </Box>
      <Box mb={4}>
        <TextField label="Imię i nazwisko na karcie" />
      </Box>
      <Grid mb={4} gridTemplateColumns={"1fr 87px 87px"} gridColumnGap={4}>
        <TextField label="Numer karty" />
        <TextField label="Kod CVC/CVC2" />
        <TextField label="Data ważności" />
      </Grid>
      <Box mb={4}>
        <TextField label="Dodaj kod rabatowy" />
      </Box>
      <Box mb={4}>
        <Checkbox label="Utwórz skarbonkę" />
      </Box>
      <Box mb={4}>
        <Checkbox label="Wyrażam zgodę na lorem ipsum dolor sit amet." />
      </Box>
      <Checkbox label="Akceptuję regulamin i politykę prywatności." />

      <Flex justifyContent="flex-end">
        <Button variant="primary">Zapisz zmiany</Button>
      </Flex>
    </>
  );
};
