import {Route} from "types/route";
import {LoginView} from "views/LoginView";
import {RegisterDeliveryView} from "views/RegisterDeliveryView";
import {RegisterDietView} from "views/RegisterDietView";
import {RegisterHealthInterviewView} from "views/RegisterHealthInterviewView";
import {RegisterPaymentView} from "views/RegisterPaymentView";
import {RegisterPreferencesView} from "views/RegisterPreferencesView";
import {RegisterSubscriptionView} from "views/RegisterSubscriptionView";
import {RegisterSummaryView} from "views/RegisterSummaryView";
import {RegisterView} from "views/RegisterView";
import {SearchView} from "views/search/SearchView";
import {DishSingleView} from "views/dish/DishSingleView";
import {NutritionPlansView} from "views/NutritionPlansView";
import {HistoryOrderView} from "./views/HistoryOrderView";
import {ShoppingCartView} from "views/ShoppingCartView";
import {SettingsView} from "./views/Settings/SettingsView";
import {SettingsProfileView} from "./views/Settings/SettingsProfileView";
import {SettingsHealthInterviewView} from "./views/Settings/SettingsHealthInterviewView";
import {SettingsDietView} from "./views/Settings/SettingsDietView";
import {SettingsPreferencesView} from "./views/Settings/SettingsPreferencesView";
import {SettingsDeliveryView} from "./views/Settings/SettingsDeliveryView";
import {SettingsPaymentView} from "./views/Settings/SettingsPaymentView";
import {VendorView} from "./views/VendorView";
import {WalletView} from "./views/WalletView";
import {SubscriptionsView} from "./views/SubscriptionsView";
import {DeliveryView} from "./views/DeliveryView";
import {RegisterResetPasswordView} from "./views/RegisterResetPasswordView";
import {RegisterSetNewPasswordView} from "./views/RegisterSetNewPasswordView";
import {ConfirmNewEmailView} from "./views/ConfirmNewEmailView";

export const routes: Array<Route> = [
  {
    path: "/",
    exact: true,
    component: SearchView,
    private: true
  },
  {
    path: "/login",
    exact: true,
    component: LoginView
  },
  {
    path: "/register",
    exact: true,
    component: RegisterView
  },
  {
    path: "/reset-password",
    exact: true,
    component: RegisterResetPasswordView
  },
  {
    path: "/reset-password/set-password",
    exact: true,
    component: RegisterSetNewPasswordView
  },
  {
    path: "/confirm-email",
    exact: true,
    component: ConfirmNewEmailView
  },
  {
    path: "/register/health-interview",
    exact: true,
    component: RegisterHealthInterviewView
  },
  {
    path: "/register/diet",
    exact: true,
    component: RegisterDietView
  },
  {
    path: "/register/preferences",
    exact: true,
    component: RegisterPreferencesView
  },
  {
    path: "/register/subscription",
    exact: true,
    component: RegisterSubscriptionView
  },
  {
    path: "/register/delivery",
    exact: true,
    component: RegisterDeliveryView
  },
  {
    path: "/register/payment",
    exact: true,
    component: RegisterPaymentView
  },
  {
    path: "/register/summary/:paymentId?",
    exact: true,
    component: RegisterSummaryView
  },
  {
    path: "/products",
    exact: true,
    private: true,
    component: SearchView,
  },
  {
    path: "/products/item/:id",
    exact: true,
    private: true,
    component: DishSingleView
  },
  // {
  //   path: "/dish/:id",
  //   exact: true,
  //   private: true,
  //   component: DishSingleView
  // },
  {
    path: "/delivery",
    exact: true,
    private: false,
    component: DeliveryView
  },
  {
    path: "/nutrition-plans",
    exact: true,
    private: true,
    component: NutritionPlansView
  },
  {
    path: "/vendor/:id",
    exact: true,
    private: true,
    component: VendorView,
  },
  {
    path: "/history-order",
    exact: true,
    private: true,
    component: HistoryOrderView
  },
  {
    path: "/history-order/:paymentId?",
    exact: true,
    private: true,
    component: HistoryOrderView,
  },
  {
    path: "/shopping-cart",
    exact: true,
    private: true,
    component: ShoppingCartView
  },
  {
    path: "/subscriptions",
    exact: true,
    private: true,
    component: SubscriptionsView,
  },
  {
    path: "/wallet/:paymentId?",
    exact: true,
    private: true,
    component: WalletView,
  },
  {
    path: "/settings",
    private: true,
    component: SettingsView,
    routes: [
      {
        path: "/settings/profile",
        component: SettingsProfileView,
        exact: true,
        private: true
      },
      {
        path: "/settings/health-interview",
        component: SettingsHealthInterviewView,
        exact: true,
        private: true
      },
      {
        path: "/settings/diet",
        component: SettingsDietView,
        exact: true,
        private: true
      },
      {
        path: "/settings/preferences",
        component: SettingsPreferencesView,
        exact: true,
        private: true
      },
      {
        path: "/settings/delivery",
        component: SettingsDeliveryView,
        exact: true,
        private: true
      },
      {
        path: "/settings/payment",
        component: SettingsPaymentView,
        exact: true,
        private: true
      }
    ]
  }
];
