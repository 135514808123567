import { Button } from "components/Button";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Typography } from "components/Typography";
import { Controller } from "react-hook-form";
import React, { useState } from "react";
import { TextField } from "components/TextField";
import { Grid } from "components/Grid";
import { Checkbox } from "components/Checkbox";
import { useSettingsFormDispatchContext, useSettingsFormStateContext } from "contexts/SettingsFormContext";
import { PhoneInputWrapper } from "components/PhoneInputWrapper";
import { useDelivery } from "hooks/delivery";
import { IDeliveryForm } from "types/delivery";
import { validateZipcode } from "../../utilities/profile";

export const SettingsDeliveryView: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { formsData } = useSettingsFormStateContext();
  const { setFormsData } = useSettingsFormDispatchContext();

  const [displayFirmForm, setDisplayFirmForm] = useState(!!formsData?.bill_address?.company_name);

  const {
    register,
    handleSubmit,
    formState,
    control,
    onSubmit
  } = useDelivery(
    isLoading,
    setIsLoading,
    displayFirmForm,
    setDisplayFirmForm,
    formsData as IDeliveryForm,
    setFormsData
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h1" mb={5}>
          Dostawa
        </Typography>
        <Box mb={9}>
          <Typography variant="h2" mb={3}>
            Dane kontaktowe
          </Typography>
          <Grid mb={4} gridTemplateColumns="182px 1fr" gridColumnGap={4}>
            <Flex flexDirection="column">
              <TextField label="Imię" placeholder="Jan"
                         error={formState.errors?.firstname?.message} {...register("firstname")} />
            </Flex>
            <Flex flexDirection="column">
              <TextField label="Nazwisko" placeholder="Kowalski"
                         error={formState.errors?.lastname?.message} {...register("lastname")} />
            </Flex>
          </Grid>
          <Grid mb={4} gridColumnGap={4}>
            <Controller
              control={control}
              name="phone"
              render={({ field: { onChange, value } }) => (
                <PhoneInputWrapper label="Numer telefonu" value={value} onChange={onChange}
                                   error={formState.errors?.phone?.message} />
              )}
            />
          </Grid>
        </Box>
        <Box mb={displayFirmForm ? 2 : 9}>
          <Typography variant="h2" mb={3}>
            Adres dostawy
          </Typography>
          <Grid mb={4} gridTemplateColumns="1fr 64px 64px" gridColumnGap={4}>
            <Flex flexDirection="column">
              <TextField label="Ulica"
                         error={formState.errors?.ship_address?.street?.message} {...register("ship_address.street")} />
            </Flex>
            <Flex flexDirection="column">
              <TextField label="Numer"
                         error={formState.errors?.ship_address?.number?.message} {...register("ship_address.number")} />
            </Flex>
            <TextField label="Lokal" {...register("ship_address.local")} />
          </Grid>
          <Grid mb={4} gridTemplateColumns="1fr 220px" gridColumnGap={4}>
            <Flex flexDirection="column">
              <Controller
                control={control}
                name="ship_address.zipcode"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    label="Kod pocztowy"
                    placeholder="01-234"
                    value={validateZipcode(`${value}`)}
                    onChange={onChange}
                    error={formState.errors?.ship_address?.zipcode?.message}
                  />
                )}
              />
            </Flex>
            <Flex flexDirection="column">
              <TextField label="Miasto"
                         error={formState.errors?.ship_address?.city?.message} {...register("ship_address.city")} />
            </Flex>
          </Grid>
          <Grid mb={4} gridTemplateColumns="1fr 1fr 220px" gridColumnGap={4}>
            <TextField label="Piętro" {...register("ship_address.floor")} />
            <TextField label="Klatka" {...register("ship_address.stairway")} />
            <TextField label="Kod do drzwi klatki"
                       placeholder="1 kluczyk 1234" {...register("ship_address.door_code")} />
          </Grid>
          <Box mb={4}>
            <TextField label="Dodatkowe informacje"
                       placeholder="np. domofon nie działa." {...register("ship_address.additional_info")} />
          </Box>
          <Box mb={4}>
            <Controller
              control={control}
              name="leave_at_the_door"
              render={({ field: { onChange, value,  } }) => (
                <Checkbox label="Zostaw pod drzwiami" checked={value} onChange={() => {
                  onChange(!value);
                }} />
              )}
            />
          </Box>
          <Controller
            control={control}
            name="i_want_to_receive_an_invoice"
            render={({ field: { onChange } }) => (
              <Checkbox label="Chcę otrzymywać fakturę VAT" checked={displayFirmForm} onChange={() => {
                setDisplayFirmForm(!displayFirmForm);
                onChange(!displayFirmForm);
              }} />
            )}
          />
        </Box>
        {displayFirmForm && (
          <Box>
            <Typography variant="h2" mb={3}>
              Dane do faktury
            </Typography>
            <Box mb={4}>
              <Flex flexDirection="column">
                <TextField label="Nazwa firmy"
                           error={formState.errors?.bill_address?.company_name?.message} {...register("bill_address.company_name")} />
              </Flex>
            </Box>
            <Box mb={4}>
              <Flex flexDirection="column">
                <TextField label="NIP"
                           error={formState.errors?.bill_address?.nip?.message} {...register("bill_address.nip")} />
              </Flex>
            </Box>
            <Grid mb={4} gridTemplateColumns="1fr 64px 64px" gridColumnGap={4}>
              <Flex flexDirection="column">
                <TextField label="Ulica"
                           error={formState.errors?.bill_address?.street?.message} {...register("bill_address.street")} />
              </Flex>
              <Flex flexDirection="column">
                <TextField label="Numer"
                           error={formState.errors?.bill_address?.number?.message} {...register("bill_address.number")} />
              </Flex>
              <TextField label="Lokal" {...register("bill_address.local")} />
            </Grid>
            <Grid gridTemplateColumns="1fr 220px" gridColumnGap={4}>
              <Flex flexDirection="column">
                <Controller
                  control={control}
                  name="bill_address.zipcode"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Kod pocztowy"
                      placeholder="01-234"
                      value={validateZipcode(`${value}`)}
                      onChange={onChange}
                      error={formState.errors?.bill_address?.zipcode?.message}
                    />
                  )}
                />
              </Flex>
              <Flex flexDirection="column">
                <TextField label="Miasto"
                           error={formState.errors?.bill_address?.city?.message} {...register("bill_address.city")} />
              </Flex>
            </Grid>
          </Box>
        )}
        <Flex justifyContent="flex-end" mt={4}>
          <Button variant="primary" disabled={isLoading}>Zapisz zmiany</Button>
        </Flex>
      </form>
    </>
  );
};
