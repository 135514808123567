import { Button } from "components/Button";
import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Typography } from "components/Typography";
import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerSchema } from "schemas/register";
import { InputGroup } from "components/InputGroup";
import { Checkbox } from "components/Checkbox";
import { IOption, Tags } from "components/Tags/Tags";
import { getExclusionsPrepared, ITaxon, ITaxonsForm } from "views/RegisterPreferencesView";
import { updateUser } from "services/user";
import { toast } from "react-toastify";
import { fetchTaxon } from "services/taxon";
import { useSettingsFormDispatchContext, useSettingsFormStateContext } from "contexts/SettingsFormContext";
import { useGlobalStateDispatchContext } from "../../contexts/GlobalStateContext";

export const SettingsPreferencesView: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [diets, setDiets] = useState<ITaxon[]>([]);
  const [kitchens, setKitchens] = useState<ITaxon[]>([]);
  const [ingredients, setIngredients] = useState<ITaxon[]>([]);
  // const [allergens, setAllergens] = useState<ITaxon[]>([]);
  const { formsData } = useSettingsFormStateContext();
  const { setFormsData } = useSettingsFormDispatchContext();
  const { setProfile } = useGlobalStateDispatchContext();

  const { register, handleSubmit, watch, control, setValue } = useForm<ITaxonsForm>({
    defaultValues: {
      ingredients: formsData?.ingredients ?? [],
      // allergens: formsData?.allergens ?? [],
      kitchens: formsData?.kitchens ?? [],
      diets: formsData?.diets ?? []
    }
  });
  const watcher = watch();

  const buildArrayRequestPayload = (data: ITaxonsForm) => {
    const dietsIds = diets.map((el, index) => {
      return { ...el, checked: data.diets[index].checked };
    }).filter(el => el.checked).map(el => parseInt(el.value));

    const ingredientsIds = data.ingredients.map(ingredient => parseInt(ingredient.value));
    // const allergensIds = data.allergens.map(allergen => parseInt(allergen.value));

    const kitchensIds = kitchens.map((el, index) => {
      return { ...el, checked: data.kitchens[index].checked };
    }).filter(el => el.checked).map(el => parseInt(el.value));

    return [...dietsIds, ...ingredientsIds, ...kitchensIds];
    // return [...dietsIds, ...ingredientsIds, ...allergensIds, ...kitchensIds];
  };

  const onSubmit = handleSubmit(async (data) => {
    const taxons = buildArrayRequestPayload(data);
    const payload = taxons.map((id: number) => ({ id }));
    setFormsData({ taxons });
    setIsLoading(true);
    updateUser({ email: formsData.email }, payload)
      .then(response => {
        setProfile(response);
        toast.success("Zmiany zostały zapisane.");
      })
      .catch(() => toast.error("Coś poszło nie tak."))
      .finally(() => setIsLoading(false));
  });

  useEffect(() => {
    fetchTaxon("diets").then((response) => {
      const diets = response.included.map((taxon) => ({
        value: taxon.id,
        label: taxon.attributes.name,
        checked: false
      }));
      setDiets(diets);
      setValue("diets", diets.map(el => formsData.taxons?.includes(Number(el.value)) ? {
        ...el,
        checked: true
      } : el));
    });

    fetchTaxon("ingredients").then((response) => {
      const ingredients = response.included.map((taxon) => ({
        value: taxon.id,
        label: taxon.attributes.name,
        checked: false
      }));
      setIngredients(ingredients);
      setValue("ingredients", ingredients.filter(el => formsData.taxons?.includes(Number(el.value))).map(el => el.value ? {
        ...el,
        checked: true
      } : el));
    });

    // fetchTaxon("allergens").then((response) => {
    //   const allergens = response.included.map((taxon) => ({
    //     value: taxon.id,
    //     label: taxon.attributes.name,
    //     checked: false
    //   }));
    //   setAllergens(allergens);
    //   setValue("allergens", allergens.filter(el => formsData.taxons?.includes(Number(el.value))).map(el => el.value ? {
    //     ...el,
    //     checked: true
    //   } : el));
    // });

    fetchTaxon("kitchens").then((response) => {
      const kitchens = response.included.map((taxon) => ({
        value: taxon.id,
        label: taxon.attributes.name,
        checked: false
      }));
      setKitchens(kitchens);
      setValue("kitchens", kitchens.map(el => formsData.taxons?.includes(Number(el.value)) ? {
        ...el,
        checked: true
      } : el));
    });

    return () => {
      setDiets([]);
      setIngredients([]);
      // setAllergens([]);
      setKitchens([]);
    };
  }, []);

  return (
    <form onSubmit={onSubmit}>
      <Box mb={6} mt={3}>
        <Typography variant="h2" mb={6}>
          Preferencje żywieniowe
        </Typography>
        <Box mb={6}>
          <InputGroup label="Dieta">
            {diets.map((diet, index) => (
              <Checkbox
                capitalizeLabel columnHalfSpace
                key={diet.value}
                label={diet.label}
                {...register(`diets.${index}.checked` as const)}
              />
            ))}
          </InputGroup>
        </Box>

        <Box mb={6}>
          <>
            <Typography variant="label" mb={2} ml={3}>
              Wyklucz składniki
            </Typography>
            <Controller
              control={control}
              name="ingredients"
              render={({ field: { onChange } }) => (
                <Tags
                  options={getExclusionsPrepared(ingredients)}
                  selectedOptions={getExclusionsPrepared(watcher.ingredients)}
                  onChange={(selectedTags: IOption[]) => {
                    onChange(selectedTags.filter(tag => {
                      return ingredients.find(ingredient => ingredient.value === tag.value);
                    }));
                  }}
                />
              )}
            />
          </>
          {/*{allergens.length > 0 && (*/}
          {/*  <>*/}
          {/*    <Typography variant="label" mb={2} ml={3}>*/}
          {/*      Wykluczenia alergeny*/}
          {/*    </Typography>*/}
          {/*    <Controller*/}
          {/*      control={control}*/}
          {/*      name="allergens"*/}
          {/*      render={({ field: { onChange } }) => (*/}
          {/*        <Tags*/}
          {/*          options={getExclusionsPrepared(allergens)}*/}
          {/*          selectedOptions={getExclusionsPrepared(watcher.allergens)}*/}
          {/*          onChange={(selectedTags: IOption[]) => {*/}
          {/*            onChange(selectedTags.filter(tag => {*/}
          {/*              return allergens.find(allergen => allergen.value === tag.value);*/}
          {/*            }));*/}
          {/*          }}*/}
          {/*        />*/}
          {/*      )}*/}
          {/*    />*/}
          {/*  </>*/}
          {/*)}*/}
        </Box>

        <Box mb={6}>
          <InputGroup label="Ulubiona kuchnia">
            {kitchens.map((kitchen, index) => (
              <Checkbox
                capitalizeLabel columnHalfSpace
                key={kitchen.value}
                label={kitchen.label}
                {...register(`kitchens.${index}.checked` as const)}
              />
            ))}
          </InputGroup>
        </Box>
      </Box>

      <Flex justifyContent="flex-end">
        <Button variant="primary" disabled={isLoading}>Zapisz zmiany</Button>
      </Flex>
    </form>
  );
};
