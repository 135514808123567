import { Box } from "components/Box";
import { Navbar } from "components/Navbar";
import { Container } from "react-grid-system";
import { useGlobalStateDispatchContext } from "contexts/GlobalStateContext";
import { useEffect } from "react";
import { fetchUser } from "services/user";

export const MainLayout: React.FC = ({ children }) => {
  const { setProfile } = useGlobalStateDispatchContext();

  useEffect(() => {
    fetchUser()
      .then(response => setProfile(response))
      .catch(e => console.log(e))
  }, [])

  return (
    <div>
      <Navbar />
      <Box py={6}>
        <Container>{children}</Container>
      </Box>
    </div>
  );
}
