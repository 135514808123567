import styled from "styled-components";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FaIcon } from "./FaIcon";
import { space, SpaceProps } from "styled-system";

interface Props extends SpaceProps { active?: boolean, cursorPointer?: boolean }

export const IconStar = styled(FaIcon).attrs({
  icon: faStar
}) <Props>`
  ${({ active, cursorPointer }) => `
    color: ${active ? '#FFC107' : 'rgba(192, 197, 205, 0.5)'};
    cursor: ${cursorPointer ? 'pointer' : 'auto'}
  `}
  ${space}
`;

