import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Box } from "components/Box";
import { Typography } from "components/Typography";
import { DishTag } from "components/DishTag";
import { DishTagLabel } from "components/DishTagLabel";
import { DishTagContent } from "components/DishTagContent";
import { rgba } from "utilities/rgba";
import { useHistory } from "react-router";
import { getModifiedURL, parseURL } from "utilities/URLHelper";
import { Flex } from "components/Flex";

export interface INutrientState {
  label: string;
  value: string;
  unit: string;
}

interface IAllergenState {
  label: string;
}

interface IDishTabs {
  nutrients?: INutrientState[],
  allergens?: IAllergenState[],
  ingredients?: string,
  description: string
  preparationAndStorage: string
}

interface ITab {
  active?: boolean
}

interface ITableRow {
  isEven: boolean
}

interface ITabState {
  link: string;
  label: string;
  active?: boolean
}

const Tab = styled.div<ITab>`
  flex: 1 1 auto;
  background-color: #F3F4F6;
  ${({ active, theme }) => `
    background-color: ${active ? theme.colors.primary : "#F3F4F6"};
    color: ${active ? "#FFF" : theme.colors.text.lighter};
    &:first-child {
      border-top-left-radius: ${theme.radii[2]}px;
      border-bottom-left-radius: ${theme.radii[2]}px;
      background-color: ${active ? "#C0C5CD" : "#F3F4F6"};
    }
    &:last-child {
      border-top-right-radius: ${theme.radii[2]}px;
      border-bottom-right-radius: ${theme.radii[2]}px;
    }
  `}
  & > ${Typography} {
    cursor: pointer;
    text-align: center;
    line-height: 40px;
    font-size: 12px;
    font-weight: 600;
  }
`;

const StyledTable = styled.div`
  width: 235px;
  background-color: #f3f4f6;
  ${({ theme }) => `
    border-radius: ${theme.radii[2]}px;
  `}
`;

const TableRow = styled(Flex)<ITableRow>`
  ${({ theme, isEven }) => `
    background-color: ${!isEven ? rgba("#C0C5CD", 0.3) : "#F3F4F6"};
    padding: 0 ${theme.space[4]}px;
    &:first-child {
      border-top-left-radius: ${theme.radii[2]}px;
      border-top-right-radius: ${theme.radii[2]}px;
    }
    &:last-child {
      border-bottom-left-radius: ${theme.radii[2]}px;
      border-bottom-right-radius: ${theme.radii[2]}px;
    }
    & p {
      color: ${theme.colors.neutral.black};
      font-size: 12px;
      line-height: 24px;
    }
    & p:first-child {
      opacity: 0.5;
    }
  `};
  height: 32px;
`;

export const DishTabs: React.FC<IDishTabs> = ({
                                                children,
                                                allergens,
                                                ingredients = "",
                                                nutrients,
                                                preparationAndStorage,
                                                description
}) => {
  const history = useHistory();

  const [activeTab, setActiveTab] = useState<string>("");
  const [tabs, setTabs] = useState<ITabState[]>([
    { link: "description", label: "Opis produktu" },
    { link: "nutrients", label: "Wartości odżywcze" },
    { link: "allergens", label: "Alergeny" },
    {
      link: "preparation-and-storage",
      label: "Przygotowanie i przechowywanie"
    },
    { link: "ingredients", label: "Składniki" }
  ]);

  const setNewActiveTab = (link: string) => {
    setTabs((prevState) => {
      const data = [...prevState];
      data.map((e) => {
        e.active = e.link === link;
        if (e.active) setActiveTab(link);
        return e;
      });
      return data;
    });
    // history.push(getModifiedURL(link, history.location.pathname, true));
  };

  useEffect(() => {
    const parameters = parseURL();
    // @ts-ignore
    setNewActiveTab((parameters === null || !parameters.hasOwnProperty("tab_type"))
      ? "description" : parameters.tab_type);
  }, []);

  return (
    <Box width="100%">
      <Flex justifyContent="space-between">
        {tabs && tabs.map(e => (
          <Tab key={e.label} active={e.active} onClick={() => setNewActiveTab(e.link)}>
            <Typography>{e.label}</Typography>
          </Tab>
        ))}
      </Flex>
      <Box mt={6}>
        {children}
        {activeTab === "description" && (
          <Typography variant="h4">{description}</Typography>
        )}
        {(activeTab === "nutrients" && nutrients) && (
          <StyledTable>
            {nutrients.map((el, index) => (
              <TableRow alignItems="center" justifyContent="space-between" isEven={index % 2 == 0} key={el.label}>
                <p>{el.label}</p>
                <p>{el.value}{el.unit}</p>
              </TableRow>
            ))}
          </StyledTable>
        )}
        {(activeTab === "allergens" && allergens) && (
          <>
            {allergens.map(allergen =>
              <DishTag key={allergen.label} px={3} mr={4} mb={2}>
                <DishTagLabel />
                <DishTagContent>{allergen.label}</DishTagContent>
              </DishTag>
            )}
          </>
        )}
        {activeTab === "preparation-and-storage" && (
          <Typography variant="h4">{preparationAndStorage}</Typography>
        )}
        {activeTab === "ingredients" && (
          <Typography variant="h4">
            {ingredients}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
