import React from "react";
import { Modal } from "components/Modal/Modal";
import styled from "styled-components";
import { Typography } from "components/Typography";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Box } from "components/Box";

export interface IChangeProductModal {
  isOpen: boolean;
  onCancelClick: () => void;
  onSubmit: () => void;
}

const Wrapper = styled.div`
  ${({ theme }) => `
    padding: ${theme.space[8]}px ${theme.space[9]}px ${theme.space[6]}px ${theme.space[10]}px;
  `};
`;

export const ChangeProductModal: React.FC<IChangeProductModal> = ({
  isOpen,
  onSubmit,
  onCancelClick,
}) => {

  return (
    <Modal isOpen={isOpen} onCancelClick={onCancelClick}>
      <Wrapper>
        <Typography variant="h3">Czy na pewno chcesz zmienić posiłek w swoim planie?</Typography>
        <Flex justifyContent="flex-end" mt={4}>
          <Box mx={4}>
            <Button onClick={onSubmit} variant="primary">Tak</Button>
          </Box>
          <Button onClick={onCancelClick} variant="secondary">Nie</Button>
        </Flex>
      </Wrapper>
    </Modal>
  );
};
