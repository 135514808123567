import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Card } from "components/Card";
import { Typography } from "components/Typography";
import { INutritionBar, NutritionBar } from "components/NutritionBar";
import { Alert } from "components/Alert";
import { Grid } from "components/Grid";
import { Button } from "components/Button";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { IconAlert } from "../Icons/IconAlert";

interface IDailyChart {
  calories?: number;
  carbohydrates?: number;
  proteins?: number;
  fats?: number;
  maxCalories?: number;
  maxCarbohydrates?: number;
  maxProteins?: number;
  maxFats?: number;
  price?: number;
  budget?: number;
}

const StyledPrice = styled.span`
  color: #ee5a24;
  font-weight: 700;
`;

const StyledTypography = styled(Typography)`
  ${({ theme }) => `
    color: ${theme.colors.text.lighter};
    & > span {
      color: ${theme.colors.primary};
      font-weight: bold;
    }
  `};
`;

export const DailyChart: React.FC<IDailyChart> = ({
                                                    calories,
                                                    carbohydrates,
                                                    proteins,
                                                    fats,
                                                    maxCalories,
                                                    maxCarbohydrates,
                                                    maxProteins,
                                                    maxFats,
                                                    price,
                                                    budget = 45
                                                  }) => {
  const [valueMax, setValueMax] = useState<boolean>(false);

  const data: (INutritionBar & { maxWidth?: boolean })[] = [
    {
      value: Math.round(calories || 0),
      maxValue: Math.round(maxCalories || 0),
      color: "#FA6400",
      unit: "kcal",
      name: "Energia",
      width: 0
    },
    {
      value: Math.round(carbohydrates || 0),
      maxValue: Math.round(maxCarbohydrates || 0),
      color: "#F7B500",
      unit: "g",
      name: "Węglowodany",
      width: 0
    },
    {
      value: Math.round(proteins || 0),
      maxValue: Math.round(maxProteins || 0),
      color: "#6DD400",
      unit: "g",
      name: "Białka",
      width: 0
    },
    {
      value: Math.round(fats || 0),
      maxValue: Math.round(maxFats || 0),
      color: "#FA6400",
      unit: "g",
      name: "Tłuszcze",
      width: 0
    }
  ].map(el => {
    const width = el.value / el.maxValue > 1 ? 1 : el.value / el.maxValue;
    return { ...el, width, maxWidth: el.value / el.maxValue > 1 };
  });

  useEffect(() => {
    setValueMax(!!data.find(el => el.maxWidth));
  }, [data]);

  return (
    <Card py={6} px={8}>
      <Typography variant="h3" mb={3}>
        Dane dnia
      </Typography>
      {data.map(el =>
        <Box mb={4} key={el.name}>
          <NutritionBar
            color={el.color}
            value={el.value}
            maxValue={el.maxValue}
            unit={el.unit}
            name={el.name}
            width={Number.isNaN(el.width) ? 0 : el.width}
          />
        </Box>
      )}
      {/* {valueMax &&
      <Box mb={6}>
        <Alert>
          <Flex>
            <IconAlert mr={4} />
            Wybrany wariant nie spełnia Twojego dziennego zapotrzebowania
            kalorycznego
          </Flex>
        </Alert>
      </Box>
      } */}
      {price &&
      <>
        <Flex justifyContent="space-between" mb={5}>
          <Typography fontWeight={700}>Cena zestawu</Typography>
          <div>
            <StyledPrice>{price.toFixed() || 0} zł</StyledPrice>
            /{budget || 0} zł
          </div>
        </Flex>
        {Number(price.toFixed()) < 56 &&
        <StyledTypography>Koszt dziennej diety nie przekracza <span>55</span> zł, zostanie naliczona opłata za dostawę w wysokości <span>8</span> zł.</StyledTypography>
        }
      </>
      }
      {/* {!!(price && budget / price < 1) &&
        <Box mb={4}>
          <Alert>
            <Flex>
              <IconAlert mr={4} />
              Cena zestawu wykracza poza Twój dzienny budżet. Różnica zostanie
              pobrana z Twojej skarbonki
            </Flex>
          </Alert>
        </Box>
      } */}
      {/* <Grid gridTemplateColumns="1fr 1fr" gridGap={8}>
        <Button fullWidth>Resetuj</Button>
        <Button fullWidth variant="primary">
          Akceptuj
        </Button>
      </Grid> */}
    </Card>
  );
};
