export const checkRegistrationStatus = (path: string, supscriptionsLength: number) => {
  const onboardingFormValue = localStorage.getItem("onboarding-form-value");
  if (supscriptionsLength === 0) {
    if (onboardingFormValue === null) {
      localStorage.setItem("onboarding-alert", "true");
      return path;
    } else {
      localStorage.setItem("onboarding-alert", "false");
      const values = JSON.parse(onboardingFormValue);
      if (!values.type || !values.gender || !values.birthday || !values.weight || !values.target_weight || !values.height) {
        return "/register/health-interview";
      } else if (
        (!values.target_days || !values.energy || !values.physical_activity || !values.meal_types)
      ) {
        return "/register/diet";
      } else if (
        (!values.ingredients || !values.kitchens || !values.diets)
      ) {
        return "/register/preferences";
      } else if (
        (
          !values.subscription
          || !values.subscription.amount
          || !values.subscription.date_from
          || !values.subscription.date_to
          || !values.subscription.number_of_days
          || !values.subscription.selectedDays
        )
      ) {
        return "/register/subscription";
      } else if (
        !values.firstname
        || !values.lastname
        || !values.phone
        || !values.ship_address.street
        || !values.ship_address.number
        || !values.ship_address.zipcode
        || !values.ship_address.city
      ) {
        return "/register/delivery";
      } else if (!values.agreements_agreements) {
        return "/register/payment";
      } else {
        return path;
      }
    }
  } else {
    localStorage.setItem("onboarding-alert", "false");
    return path;
  }
};
