import {Box} from "components/Box";
import {Button} from "components/Button";
import {Flex} from "components/Flex";
import {Typography} from "components/Typography";
import {InputRange} from "components/InputRange/InputRange";
import {LoginRegisterLayout} from "layouts/LoginRegisterLayout";
import {useHistory} from "react-router";
import {Calendar} from "components/Calendar/Calendar";
import {RegisterFormStatus} from "containers/RegisterFormStatus";
import {useRegisterFormDispatchContext, useRegisterFormStateContext} from "contexts/RegisterFormContext";
import React, {useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";
import {Controller, useForm} from "react-hook-form";
import {DatePickerInput} from "components/DatePickerInput/DatePickerInput";
import {compareDates, findMinAndMaxDate, formatDate} from "utilities/date";
import styled from "styled-components";
import {Checkbox} from "components/Checkbox";
import {fetchSubscriptions} from "services/user";
import {getDisabledDays, getPrefPriceForSubscription} from "utilities/calendar";
import {CalendarDatePickers} from "../components/Calendar/CalendarDatePickers";
import {SpeechBubble} from "../components/SpeechBubble";

export interface IRegisterSubscriptionForm {
  amount: number
  budget?: number
  date_from: Date | string | undefined
  date_to: Date | string | undefined
  number_of_days: number
  selectedDays: Date[]
  orderOnWeekends: boolean
}

const StyledTypography = styled(Typography)`
  ${({theme}) => `
    color: ${theme.colors.text.lighter};
    & > span {
      color: ${theme.colors.primary};
      font-weight: bold;
    }
  `};
`;

const getDietDays = (days: number, lastDisabledDay: Date) => {
  const daysToSelect = [];
  daysToSelect.push(new Date(lastDisabledDay));
  for (let i = 1; i < days; i++) {
    daysToSelect.push(new Date(lastDisabledDay.setDate(lastDisabledDay.getDate() + 1)));
  }
  return daysToSelect;
};

export const RegisterSubscriptionView: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {setActiveStep, setFormsData} = useRegisterFormDispatchContext();
  const {formsData} = useRegisterFormStateContext();

  const [daysCount, setDaysCount] = React.useState<number>(0);
  const [daysChanged, setDaysChanged] = useState<boolean>(false);
  const [orderOnWeekends, setOrderOnWeekend] = useState<boolean>(true);
  const [budget, setBudget] = useState<number>(50);
  const selectedDays = formsData?.subscription?.selectedDays.map(el => new Date(el));
  // calculated nex 2-3 days from today
  const disabledDays: Date[] = getDisabledDays();
  const lastDisabledDay = new Date(new Date().setTime(disabledDays[disabledDays.length - 1].getTime() + (60 * 60 * 24 * 1000)));
  const dietDays = getDietDays(formsData?.target_days || 0, new Date(lastDisabledDay));
  const initDays = selectedDays && selectedDays.length > 0
    ? selectedDays
    : dietDays;
  const minBudget = formsData.energy ? getPrefPriceForSubscription(formsData.energy) : [10, 20];

  const history = useHistory();

  const calendarRef = useRef<{ getDates: () => Date[] }>({
    getDates() {
      return [];
    }
  });
  const [selectedMinDate, selectedMaxDate] = findMinAndMaxDate(formsData?.subscription?.selectedDays
    .map(el => new Date(el)) || [new Date()]);

  const {handleSubmit, control, watch, setValue} = useForm<IRegisterSubscriptionForm>(
    {
      defaultValues: {
        amount: formsData.subscription?.amount ?? minBudget[1],
        date_from: (compareDates(selectedMinDate, new Date()) ? false : selectedMinDate) || lastDisabledDay,
        date_to: (compareDates(selectedMaxDate, new Date()) ? false : selectedMaxDate) || dietDays[dietDays.length - 1],
        number_of_days: formsData.subscription?.number_of_days ?? 10
      }
    }
  );
  const watcher = watch();

  const handleDateRange = (dateMin: Date | undefined, dateMax: Date | undefined) => {
    setDaysChanged(false);
    setValue("date_from", dateMin);
    setValue("date_to", dateMax);
  };

  const onSubmit = async (data: IRegisterSubscriptionForm) => {
    try {
      setIsLoading(true);
      if (!(data.date_from instanceof Date) || !(data.date_to instanceof Date)) {
        toast.error("Wybierz co najmniej 1 dzień.");
        return;
      }

      const dataForContext = {
        subscription: {
          amount: data.amount,
          date_from: formatDate(data.date_from),
          date_to: formatDate(data.date_to),
          number_of_days: daysCount,
          selectedDays: (calendarRef && calendarRef.current) ? calendarRef.current.getDates() : [],
          orderOnWeekends
        }
      };

      setFormsData(dataForContext);
      history.push("/register/delivery");
    } catch (err) {
      toast.error("Coś poszło nie tak.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setActiveStep(3);
  }, []);

  useEffect(() => {
    if (formsData && formsData.subscription) setOrderOnWeekend(formsData.subscription.orderOnWeekends);
  }, [formsData?.subscription?.orderOnWeekends]);

  return (
    <LoginRegisterLayout
      left={
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h1" mb={6}>
            Abonament
          </Typography>

          <Box mb={5}>
            <Flex justifyContent="flex-start" alignItems="center">
              <Typography variant="label" mr={2}>Dzienny budżet</Typography>
              <Box mb={3}>
                <SpeechBubble html={
                  "<p>Jest to Twój maksymalny dzienny budżet. Nadpłaty zostaną przeniesione do Twojej skarbonki. " +
                  "Im wyższy budżet tym bardziej urozmaicone będzie Twoje menu. " +
                  "Dla dziennego budżetu poniżej 55 PLN zostanie doliczona opłata 8PLN na dzień</p>"
                }>i</SpeechBubble>
              </Box>
            </Flex>
            <Controller
              control={control}
              name="amount"
              render={({field: {value, onChange}}) => (
                <InputRange
                  min={minBudget[0]}
                  max={500}
                  value={value}
                  onChange={(value) => {
                    setBudget(value);
                    onChange(value);
                  }}
                />
              )}
            />
          </Box>

          <Typography mb={3} variant="h4">Zaznacz w kalendarzu dni, na które chcesz zamówić posiłki lub wybierz zakres
            dni.</Typography>

          <Flex justifyContent="space-between" mb={6}>
            <CalendarDatePickers
              control={control}
              lastDisabledDay={lastDisabledDay}
              setDaysChanged={setDaysChanged}
              watcher={watcher}
              setValue={setValue}
            />
          </Flex>

          <Box>
            <Calendar
              ref={calendarRef}
              initDays={initDays}
              disableWeekends={!orderOnWeekends}
              daysChangedOutside={daysChanged}
              disabledDays={disabledDays}
              disablePastDays
              dayFrom={watcher.date_from ? new Date(watcher.date_from) : undefined}
              dayTo={watcher.date_to ? new Date(watcher.date_to) : undefined}
              onDateRangeChanged={handleDateRange}
              onDaysCountChanged={(newDaysCount: number) => setDaysCount(newDaysCount)}
            />
          </Box>

          <Box mb={4} mt={1}>
            <Checkbox label="Zamawiam na soboty i niedziele." checked={orderOnWeekends}
                      onChange={(value) => setOrderOnWeekend(value.target.checked)}
            />
          </Box>

          <Flex mb={4} mt={2} justifyContent="space-between">
            <StyledTypography>Łączna liczba dni: <span>{daysCount}</span></StyledTypography>
            <StyledTypography>Cena
              abonamentu: <span>{daysCount * (budget > 54 ? budget : (budget + 8))}</span> zł</StyledTypography>
          </Flex>
          <Flex mb={6}>
            <StyledTypography>Przy zamówieniu wynoszącym minimum <span>55</span> zł/dzień - dostawa
              bezpłatna,<br/> poniżej
              tej kwoty - dopłata <span>8</span> zł/dzień. </StyledTypography>
          </Flex>

          <Flex justifyContent="space-between">
            <Button onClick={() => history.push("/register/preferences")} disabled={isLoading}>
              Wróć
            </Button>
            <Button variant="primary" disabled={isLoading}>
              Dalej
            </Button>
          </Flex>
        </form>
      }
      right={<RegisterFormStatus/>}
    />
  );
};
