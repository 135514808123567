import { Box } from "components/Box";
import { Button } from "components/Button";
import { Checkbox } from "components/Checkbox";
import { Flex } from "components/Flex";
import { Typography } from "components/Typography";
import { RegisterFormStatus } from "containers/RegisterFormStatus";
import { useRegisterFormDispatchContext, useRegisterFormStateContext } from "contexts/RegisterFormContext";
import { LoginRegisterLayout } from "layouts/LoginRegisterLayout";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { paymentSchema } from "schemas/payment";
import { formatDate } from "utilities/date";
import { fetchSubscriptions, postSubscription } from "services/user";
import { postPayment } from "services/payment";
import { toast } from "react-toastify";

export interface IRegisterPaymentFormData {
  agreements_agreements: boolean;
  agreements_privat_privacy: boolean;
}

export const RegisterPaymentView: React.FC = () => {
  const [disableButtons, setDisableButtons] = useState(false)
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { setActiveStep, setFormsData } = useRegisterFormDispatchContext();
  const { formsData } = useRegisterFormStateContext();
  const budget = formsData?.subscription?.amount || 0

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IRegisterPaymentFormData>({
    resolver: yupResolver(paymentSchema)
  });

  useEffect(() => {
    setActiveStep(5);
  }, []);

  const createSubscriptionAndPayment = () => {
    if (formsData.subscription === undefined) return;
    const payload = {
      subscription: {
        price_per_day: formsData.subscription.amount > 54
          ? formsData.subscription.amount
          : (formsData.subscription.amount + 8),
        days: formsData.subscription.selectedDays.map(el => formatDate(new Date(el)))
      }
    };

    postSubscription(payload)
      .then(subscription => {
        postPayment({
          payment: {
            payable_object_id: Number(subscription.data.id),
            payable_object_type: "subscription",
            continue_path: '/register/summary/'
          }
        })
          .then(res => {
            setFormsData({paymentId: res.data.id})
            // @ts-ignore
            window.location.href = res.data.attributes.url;
          })
          .catch(e => toast.error("Płatność nie powiodła się."));
      })
      .catch(e => toast.error("Coś poszło nie tak."))
      .finally(() => setDisableButtons(false))
  };

  const onSubmit = async (data: IRegisterPaymentFormData) => {
    setDisableButtons(true)
    createSubscriptionAndPayment();
  };

  return (
    <LoginRegisterLayout
      left={
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h1" mb={3}>
            Płatność
          </Typography>
          <Typography variant="h4" mb={6} textAlign="center">
            Wartość abonamentu - {((budget > 54 ? budget : budget + 8)) * (formsData?.subscription?.number_of_days || 0)} zł
          </Typography>
          <Box mb={4}>
            <Checkbox
              label="Akceptuję regulamin i politykę prywatności*"
              {...register("agreements_privat_privacy")}
              error={!!errors.agreements_privat_privacy?.message}
            />
            <Box mt={4}>
              <Checkbox
                label="Wyrażam zgodę na otrzymywanie informacji marketingowych o nowościach i akcjach promocyjnych"
                {...register("agreements_agreements")}
              />
            </Box>
          </Box>

          <Flex justifyContent="space-between" mt={6}>
            <Button disabled={disableButtons} onClick={() => history.push("/register/delivery")}>
              Wróć
            </Button>
            <Button
              disabled={disableButtons}
              variant="primary"
              type="submit"
            >
              Dalej
            </Button>
          </Flex>
        </form>
      }
      right={<RegisterFormStatus />}
    />
  );
};
