import { Box } from "components/Box";
import { Flex } from "components/Flex";
import React, { useEffect, useState } from "react";
import { Range } from "react-range";
import { TextField } from "components/TextField";
import styled from "styled-components";
import { ITrackProps, IThumbProps } from "react-range/lib/types";

interface IInputRange {
  min: number;
  max: number;
  value: number;
  step?: number;
  label?: string;
  disabled?: boolean;
  addSpaces?: boolean;
  onChange: (val: number) => void;
}

interface IRenderTrack {
  props: ITrackProps;
  disabled?: boolean;
  children?: React.ReactNode;
}

interface IRenderThumb {
  props: IThumbProps;
}

const StyledTextField = styled(TextField)`
  width: 70px;
  height: 40px;
  font-size: 12px;
  text-align: center;
`;

export const InputRange: React.FC<IInputRange> = ({
                                                    min,
                                                    max,
                                                    step = 1,
                                                    label,
                                                    value,
                                                    disabled,
                                                    addSpaces,
                                                    onChange
                                                  }) => {
  const [tempValue, setTempVal] = useState<number>(value);

  const renderTrack = ({ props, children, disabled }: IRenderTrack) => (
    <div
      {...props}
      style={{
        ...props.style,
        height: "2px",
        width: "100%",
        backgroundColor: "#2ba76a",
        opacity: disabled ? 0.5 : 1
      }}
    >
      {children}
    </div>
  );

  const renderThumb = ({ props }: IRenderThumb) => (
    <div
      {...props}
      style={{
        ...props.style,
        height: "16px",
        width: "16px",
        borderRadius: "50%",
        backgroundColor: "#2ba76a"
      }}
    />
  );

  const roundNumber = (number: number) => {
    const numberFix = Math.round(number / step) * step;
    setTempVal(numberFix);
  };

  const handleInputChange = (val: string) => {
    const numberVal = Number(val.replace(/\s+/g, ""));
    if (Number.isNaN(numberVal) || numberVal > max) return;
    if (step > 1) roundNumber(numberVal);
    setTempVal(numberVal);
  };

  const handleInputOnBlur = (val: string) => {
    const numberVal = Number(val.replace(/\s+/g, ""));
    if (numberVal < min) setTempVal(min);
    else if (numberVal > max) setTempVal(max);
    else if (step > 1) roundNumber(numberVal);
  };

  const handleRangeChange = (val: number) => {
    setTempVal(val);
    return val;
  };

  useEffect(() => {
    if (tempValue < min || tempValue > max) return;
    onChange(tempValue);
  }, [tempValue]);

  useEffect(() => {
    if (value < min || value > max) return;
    roundNumber(value)
  }, [value]);

  return (
    <Flex alignItems="center">
      <Box mr={3}>
        <StyledTextField
          disabled={disabled}
          label={label ? label : ""}
          min={min}
          max={max}
          value={addSpaces ? tempValue.toLocaleString().replace(",", " ") : tempValue}
          onBlur={(value) => handleInputOnBlur(value.target.value)}
          onChange={(value) => handleInputChange(value.target.value)}
        />
      </Box>
      <Box mt={label ? 5 : null} width="100%">
        <Range
          disabled={disabled}
          step={step}
          min={min}
          max={max}
          values={[value]}
          onChange={(values) => onChange(handleRangeChange(values[0]))}
          renderTrack={renderTrack}
          renderThumb={renderThumb}
        />
      </Box>
    </Flex>
  );
};
