import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  ${({ theme }) => `
    html {
      box-sizing: border-box;
    }

    body {
      margin: 0;
      font-size: 14px;
      line-height: 20px;
      font-family: 'Open Sans', sans-serif;
      background-color: #f5f7f9;
    }
    
    .tooltip:hover {
         visibility: visible !important;
         opacity: 1 !important;
    }
    .tooltip {
      padding: 16px !important;
      border-radius: 16px !important;
      opacity: 0;
      max-width: 500px;
      font-family: "Open Sans", sans-serif;
      font-size: 12px !important;
      font-weight: bold;
      pointer-events: auto !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .tooltip p {
      margin: 0;
    }

    *,
    *:before,
    *:after {
      box-sizing: inherit;
    }

    * {
      outline-color: ${theme.colors.primary};
    }

    *::selection {
      color: ${theme.colors.neutral.white};
      background-color: ${theme.colors.primary};
    }
    .svg-inline--fa.fa-w-18 {
      width: 20px;
  }
  `}
`;
