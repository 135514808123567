import styled from "styled-components";
import {
  variant,
  system,
  TextColorProps,
  FontSizeProps,
  LineHeightProps,
  FontWeightProps,
  space,
  SpaceProps,
  typography,
  TypographyProps
} from "styled-system";

export interface Props
  extends TextColorProps,
  FontSizeProps,
  LineHeightProps,
  FontWeightProps,
  SpaceProps,
  TypographyProps {
  variant?: "h1" | "h2" | "h3" | "h4" | "label" | "caption" | "error" | "common";
}

export const Typography = styled.div<Props>`
  ${variant({
  variants: {
    h1: {
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: "bold"
    },
    h2: {
      fontSize: "20px",
      lineHeight: "28px",
      fontWeight: "bold"
    },
    h3: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "bold"
    },
    h4: {
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: 600
    },
    label: {
      fontSize: "10px",
      lineHeight: "16px",
      fontWeight: 600,
      color: "text.light"
    },
    caption: {
      color: "text.light",
      fontSize: "10px",
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: "16px"
    },
    error: {
      fontSize: "10px",
      lineHeight: "16px",
      fontWeight: 600,
      color: "error"
    },
    common: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 400,
      color: "text.lighter"
    }
  }
})}
  ${system({
  color: {
    property: "color",
    scale: "colors"
  },
  fontSize: true,
  lineHeight: true,
  fontWeight: true,
})}
  ${space}
  ${typography}
`;
