import {IDelivery, IOrder, IRoute, IRoutePoint, IScheduledMeal} from "../services/vendor";
import React from "react";
import {string} from "yup";

type IOrderPrepared = {
  id: string | number
  user_id: string | number
  shipment_state: string
  type: "order"
  scheduled_meal: {
    id: string | number
    type: 'scheduled_meal'
    meal_type_id: number
    product_id: number
    meal_type_name: string
  }
}

type IRoutePointPrepared = {
  type: "route_point"
  id: string | number
  address: IRoutePoint["attributes"]["address"]["data"]["attributes"]
  arrive_time: string
  depart_time: string
  sort_order: number
  // orders: IOrderPrepared[]
}

type IRoutePrepared = {
  type: "route",
  id: string | number,
  spree_user_id: string | number | null
  routePoints: IRoutePointPrepared[]
}

export type IDeliveryPrepared = {
  dataForTable: {
    rowData: (string[] | React.ReactNode[])
    expandedData: {[key: string]: string}
  }[]
  productIds: (string | number)[]
  date: string
  request_type: string
  id: string | number
  type: "route_request"
  routes: IRoutePrepared[]
}

const orderStatuses: { [key: string]: string } = {
  delivery: 'Dostawa',
  receipt: 'Odebranie'
}

const prepareExpandedDataReceipt = (data: string) => {
  const res: {[key: string]: string} = {}
  const expandedDataArr = data.split(', ')
  expandedDataArr.forEach(el => {
    const [key, val] = el.split(": ")
    res[key] = val
  })
  return res
}

export const getPreparedDelivery = (
  data: IDelivery,
  type: "receipt" | "delivery",
  checkbox: (routePointId: number | string, status: string, type: string) => React.ReactNode
) => {
  const dataPrepared: IDeliveryPrepared[] = []
  let productsIds: (string | number)[] = []
  data.data.map((delivery, index) => {
    if (index > 0) return
    const deliveryObj: IDeliveryPrepared = {
      dataForTable: [],
      productIds: [],
      date: delivery.attributes.date,
      request_type: delivery.attributes.request_type,
      id: delivery.id,
      type: delivery.type,
      routes: []
    }
    delivery.relationships.routes.data.map(routeMin => {
      const route = data.included.find(el => el.type === routeMin.type && el.id === routeMin.id) as IRoute
      const routeObj: IRoutePrepared = {
        type: route.type,
        id: route.id,
        spree_user_id: route.attributes.spree_user_id,
        routePoints: []
      }
      route.relationships.route_points.data.map(routePointMin => {
        const routePoint = data.included.find(el => el.type === routePointMin.type && el.id === routePointMin.id) as IRoutePoint
        const routePointObj: IRoutePointPrepared = {
          type: routePoint.type,
          id: routePoint.id,
          address: routePoint.attributes?.address?.data?.attributes,
          arrive_time: routePoint.attributes.arrive_time,
          depart_time: routePoint.attributes.depart_time,
          sort_order: routePoint.attributes.sort_order,
          // orders: []
        }
        const expandedDataStr = routePoint?.attributes?.address as unknown as string
        const expandedData = type === 'receipt'
          ? prepareExpandedDataReceipt(expandedDataStr)
          : {
          "Imię": routePoint?.attributes?.address?.data?.attributes?.firstname,
          "Nazwisko": routePoint?.attributes?.address?.data?.attributes?.lastname,
          "Telefon": routePoint?.attributes?.address?.data?.attributes?.phone,
          "Zostawić fakturę": routePoint?.attributes?.address?.data?.attributes?.i_want_to_receive_an_invoice ? 'Tak' : 'Nie',
          "Zostawić przy drzwiach": routePoint?.attributes?.address?.data?.attributes?.leave_at_the_door ? 'Tak' : 'Nie',
          "Misato": routePoint?.attributes?.address?.data?.attributes?.city,
          "Ulica": routePoint?.attributes?.address?.data?.attributes?.street,
          "Lokal": routePoint?.attributes?.address?.data?.attributes?.local,
          "Klatka schodowa": routePoint?.attributes?.address?.data?.attributes?.stairway,
          "Piętro": routePoint?.attributes?.address?.data?.attributes?.floor,
          "Kod pocztowy": routePoint?.attributes?.address?.data?.attributes?.zipcode,
          "Kod drzwi": routePoint?.attributes?.address?.data?.attributes?.door_code,
          "Dodatkowe informacje": routePoint?.attributes?.address?.data?.attributes?.additional_info,
        }
        const rowData: (string | React.ReactNode)[] = []
        if (type === 'receipt') {
          rowData.push(routePoint?.attributes?.simplified_address)
        } else {
          rowData.push(`${routePointObj?.address?.address1} - ${routePointObj?.address?.address2}`)
        }
        rowData.push(routePointObj.arrive_time)
        rowData.push(routePointObj.depart_time)
        rowData.push(orderStatuses[deliveryObj.request_type])
        rowData.push(checkbox(routePoint.id, routePoint?.attributes?.common_shipment_state, deliveryObj.request_type))

        // routePoint.relationships.orders.data.map((orderMin, index) => {
        //   const order = data.included.find(el => el.type === orderMin.type && el.id === orderMin.id) as IOrder
        //   const SMeal = data.included.find(el => el.type === order.relationships.scheduled_meal.data.type && el.id === order.relationships.scheduled_meal.data.id) as IScheduledMeal
        //   const orderObj: IOrderPrepared = {
        //     id: order.id,
        //     user_id: order.attributes.user_id,
        //     shipment_state: order.attributes.shipment_state,
        //     type: order.type,
        //     scheduled_meal: {
        //       id: SMeal.id,
        //       type: SMeal.type,
        //       meal_type_id: SMeal.attributes.meal_type_id,
        //       product_id: SMeal.attributes.product_id,
        //       meal_type_name: SMeal.attributes.meal_type_name,
        //     }
        //   }
        //
        //   deliveryObj.productIds.push(SMeal.attributes.product_id)
        //   routePointObj.orders.push(orderObj)
        // })
        // if (routePointObj?.address?.address1 && routePoint.relationships.orders.data.length > 0) {
          deliveryObj.dataForTable.push({
            rowData: rowData,
            expandedData: expandedData as {[key: string]: string}
          })
        // }
        routeObj.routePoints.push(routePointObj)
      })

      deliveryObj.routes.push(routeObj)
    })

    if (deliveryObj.productIds.length > 1) {
      deliveryObj.productIds = deliveryObj.productIds.filter((value, index, self) => {
        return self.indexOf(value) === index;
      })
    }
    console.log(111, deliveryObj)
    dataPrepared.push(deliveryObj)
  })

  return {
    dataPrepared,
    productsIds
  }
}
