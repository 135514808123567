import { InputHTMLAttributes } from "react";
import styled from "styled-components";
import { BorderColorProps, system } from "styled-system";

export interface Props
  extends BorderColorProps,
    InputHTMLAttributes<HTMLInputElement> {
  fullWidth?: boolean;
  inheritWidth?: boolean;
  transparent?: boolean;
}

export const Input = styled.input<Props>`
  ${({ theme, fullWidth, inheritWidth, transparent }) => `
    ${fullWidth ? `width: 100%` : ""};
    ${inheritWidth ? `width: inherit` : ""};
    height: ${theme.space[10]}px;
    padding: 0 ${theme.space[3]}px;
    font-size: 14px;
    line-height: 20px;
    border: ${theme.inputBorder};
    border-radius: ${theme.radii[2]}px;
    ${
      transparent &&
      `
        background-color: transparent;
      `
    }
  `};
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &[type=number] {
    -moz-appearance: textfield;
  }
  ${system({
    borderColor: {
      property: "borderColor",
      scale: "colors",
    },
  })}
`;
