import { Button } from "components/Button";
import { Box } from "components/Box";
import { Checkbox } from "components/Checkbox";
import { TextField } from "components/TextField";
import { Typography } from "components/Typography";
import { LoginRegisterLayout } from "layouts/LoginRegisterLayout";
import { useHistory } from "react-router-dom";
import { AuthUserService, registerUser } from "services/auth";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerSchema } from "schemas/register";
import { toast } from "react-toastify";
import { useAuthDispatchContext } from "contexts/AuthContext";
import { useState } from "react";
import { useRegisterFormDispatchContext } from "../contexts/RegisterFormContext";

export interface IRegisterFormData {
  email: string;
  // old_password?: string;
  password: string;
  password_confirmation: string;
  agreements: boolean;
  agreements_marketing: boolean;
}

interface ILoginUser {
  username: string;
  password: string;
}

const rightContent =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam pulvinar sapien id leo fringilla, nec posuere tortor viverra. Morbi laoreet ligula ut ex vestibulum, quis viverra nulla egestas. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Fusce eu quam vehicula lacus pellentesque tincidunt ac id ex.";

export const RegisterView: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory();
  const { setToken } = useAuthDispatchContext();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IRegisterFormData>({
    resolver: yupResolver(registerSchema)
  });

  const { setFormsData } = useRegisterFormDispatchContext();

  const loginUser = async (data: ILoginUser) => {
    const response = await AuthUserService(data);
    setToken(response.access_token, response.refresh_token);
    history.push("/register/health-interview");
  };

  const onSubmit = async (user: IRegisterFormData) => {
    try {
      setIsLoading(true)
      const { agreements, agreements_marketing, ...newUser } = user;
      await registerUser(newUser);
      toast.success("Konto zostało utworzone. Sprawdź swój email.");
      history.push("/login");
      // setFormsData(undefined, true);
      // await loginUser({ username: user.email, password: user.password });
    } catch (error: any) {
      if (error.error === "Email has already been taken") {
        toast.error("Podany adres email jest już zajęty.");
      } else if (error.message === "E-mail zostało już zajęte") {
        toast.error("Podany adres email jest już zajęty.");
      } else {
        toast.error("Błąd podczas rejestracji użytkownika.");
      }
    } finally {
      setIsLoading(false)
    }
  };

  return (
    <LoginRegisterLayout
      left={
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h1" mb={6}>
            Rejestracja
          </Typography>
          <Box mb={6}>
            <TextField
              label="Adres e-mail"
              {...register("email")}
              error={errors?.email?.message}
            />
          </Box>
          <Box mb={6}>
            <TextField
              type="password"
              label="Hasło"
              {...register("password")}
              error={errors.password?.message}
            />
          </Box>
          <Box mb={6}>
            <TextField
              type="password"
              label="Potwierdź hasło"
              {...register("password_confirmation")}
              error={errors.password_confirmation?.message}
            />
          </Box>
          <Box mb={6}>
            <Checkbox
              label="Akceptuję regulamin i politykę prywatności."
              {...register("agreements")}
              error={!!errors.agreements?.message}
            />
            <Box mt={4}>
              <Checkbox
                label="Wyrażam zgodę na otrzymywanie informacji marketingowych o nowościach i akcjach promocyjnych."
                {...register("agreements_marketing")}
              />
            </Box>
          </Box>
          <Button variant="primary" type="submit" disabled={isLoading}>
            Zakładam konto
          </Button>
        </form>
      }
      right={rightContent}
    />
  );
};
