import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import { rgba } from "utilities/rgba";

export interface Props extends SpaceProps {}

export const Card = styled.div<Props>`
  ${({ theme }) => `
    border-radius: ${theme.radii[4]}px;
    background-color: ${theme.colors.neutral.white};
    box-shadow: 0 2px 16px -8px ${rgba("#4287f5", 0.75)};
  `};
  ${space}
`;
