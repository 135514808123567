import styled, { ThemeConsumer } from "styled-components";
import { Typography } from "./Typography";

export interface Props {
  label: string;
}

const Inputs = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-wrap: wrap;
    margin: 0 -${theme.space[3]}px -${theme.space[4]}px;

    > * {
      margin-bottom: ${theme.space[4]}px;
      padding: 0 ${theme.space[3]}px;
    }
  `};
`;

export const InputGroup: React.FC<Props> = ({ label, children }) => (
  <>
    <Typography variant="label" mb={2} ml={3}>
      {label}
    </Typography>
    <Inputs>{children}</Inputs>
  </>
);
