import React, { useEffect, useRef, useState } from "react";
import { Modal } from "components/Modal/Modal";
import styled from "styled-components";
import { Typography } from "components/Typography";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Box } from "components/Box";
import { Calendar } from "components/Calendar/Calendar";
import { InputRange } from "components/InputRange/InputRange";
import { useHistory } from "react-router-dom";
import { useSubscriptionDispatchContext } from "contexts/SubscriptionContext";
import { getDisabledDays, getPrefPriceForSubscription } from "utilities/calendar";
import { postSubscription } from "services/user";
import { formatDate } from "utilities/date";
import { toast } from "react-toastify";
import { useGlobalStateContext, useGlobalStateDispatchContext } from "contexts/GlobalStateContext";
import { Checkbox } from "../Checkbox";
import { CalendarDatePickers } from "../Calendar/CalendarDatePickers";
import { useCalenderWithDates } from "../../hooks/calendarWithDates";
import { useForm } from "react-hook-form";

export interface IPurchaseSubscriptionModal {
  isOpen: boolean;
  onCancelClick: () => void;
}

const StyledTypography = styled(Typography)`
  ${({ theme }) => `
    color: ${theme.colors.text.lighter};
    & > span {
      color: ${theme.colors.primary};
      font-weight: bold;
    }
  `};
`;

export const PurchaseSubscriptionModal: React.FC<IPurchaseSubscriptionModal> = ({
                                                                                  isOpen,
                                                                                  onCancelClick
                                                                                }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { profile } = useGlobalStateContext();
  const energy = profile?.data.attributes.energy;
  const minBudget = energy ? getPrefPriceForSubscription(energy) : [10, 20];
  const history = useHistory();
  const subscriptionContext = useSubscriptionDispatchContext();
  const [daysCount, setDaysCount] = React.useState<number>(0);
  const [budget, setBudget] = useState<number>(minBudget[1]);
  const [disabledDays, setDisabledDays] = useState<Date[]>([]);
  const { subscriptionDays, paidSubscriptionDays, waitingSubscriptionDays } = useGlobalStateContext();
  const { setSubscriptionDays, setWaitingSubscriptionDays } = useGlobalStateDispatchContext();
  const [orderOnWeekends, setOrderOnWeekend] = useState<boolean>(true);

  const calendarRef = useRef<{ getDates: () => Date[] }>({
    getDates() {
      return [];
    }
  });

  const { control, watch, setValue } = useForm<{
    amount: number
    date_from: Date | string | undefined
    date_to: Date | string | undefined
    number_of_days: number
  }>({
      defaultValues: {
        amount: minBudget[1],
        date_from: undefined,
        date_to: undefined,
        number_of_days: 10
      }
    }
  );
  const watcher = watch();

  const {
    lastDisabledDay,
    daysChanged,
    setDaysChanged,
    handleDateRange
  } = useCalenderWithDates(setValue);

  const onSubmit = () => {
    const days = (calendarRef && calendarRef.current) ? calendarRef.current.getDates() : [];
    if (days.length < 1) {
      toast.error("Wybierz co najmniej 1 dzień.");
      return;
    }
    const payload = {
      subscription: {
        price_per_day: budget > 54 ? budget : (budget + 8),
        days: days.map(el => formatDate(new Date(el)))
      }
    };

    setIsLoading(true);
    postSubscription(payload)
      .then((res) => {
        subscriptionContext.setSubscription({
          price: budget > 54 ? budget : (budget + 8),
          days,
          id: Number(res.data.id)
        });
        setSubscriptionDays(prevState => {
          return [...prevState, ...days];
        });
        setWaitingSubscriptionDays(prevState => {
          return [...prevState, ...days];
        });
        history.push("/shopping-cart");
      })
      .catch(e => {
        if (e?.message === 'Zip code is not supported!') {
          toast.error("Przykro nam ale jeszcze dostarczamy do twojej miejscowości.")
          return
        }
        toast.error("Coś poszło nie tak.")
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (profile) setBudget(energy ? getPrefPriceForSubscription(energy)[1] : 20);
  }, [profile]);

  useEffect(() => {
    setDisabledDays([...getDisabledDays(), ...paidSubscriptionDays, ...waitingSubscriptionDays]);
  }, [subscriptionDays, paidSubscriptionDays, waitingSubscriptionDays]);

  return (
    <Modal isOpen={isOpen} onCancelClick={onCancelClick}>
      <Box mt={8} mr={9} mb={10} ml={10}>
        <Typography variant="h1">Kup abonament</Typography>
        <Typography mt={2} variant="h3">Zaznacz w kalendarzu dni, na które chcesz zamówić posiłki <br/>lub wybierz zakres dni.</Typography>

        <Flex justifyContent="space-between" mt={4} mb={6}>
          <CalendarDatePickers
            control={control}
            disabledDays={disabledDays}
            lastDisabledDay={lastDisabledDay}
            setDaysChanged={setDaysChanged}
            watcher={watcher}
            setValue={setValue}
          />
        </Flex>

        <Box mt={6}>
          <Calendar
            ref={calendarRef}
            disabledDays={disabledDays}
            disableWeekends={!orderOnWeekends}
            daysChangedOutside={daysChanged}
            disablePastDays
            dayFrom={watcher.date_from ? new Date(watcher.date_from) : undefined}
            dayTo={watcher.date_to ? new Date(watcher.date_to) : undefined}
            onDateRangeChanged={handleDateRange}
            onDaysCountChanged={(daysCount: number) => setDaysCount(daysCount)}
          />
        </Box>

        <Box mb={6}>
          <Typography variant="label">Dzienny budżet</Typography>
          <InputRange
            min={minBudget[0]}
            max={200}
            value={budget}
            onChange={(value) => {
              setBudget(value);
            }}
          />
        </Box>

        <Box mb={4} mt={1}>
          <Checkbox label="Zamawiam na soboty i niedziele." checked={orderOnWeekends}
                    onChange={(value) => setOrderOnWeekend(value.target.checked)}
          />
        </Box>

        <StyledTypography>Przy zamówieniu wynoszącym minimum <span>55</span> zł/dzień - dostawa bezpłatna,<br /> poniżej
          tej kwoty - dopłata <span>8</span> zł/dzień. </StyledTypography>

        <Flex justifyContent="space-between" alignItems="center" mt={4}>
          <StyledTypography>Łączny koszt
            abonamentu: <span style={{ display: "inline-block", minWidth: "40px" }}>
              {daysCount * (budget > 54 ? budget : (budget + 8))}
          </span>
          </StyledTypography>
          <Flex justifyContent="space-between">
            <Box mx={4}>
              <Button onClick={onCancelClick} variant="secondary" disabled={isLoading}>Anuluj</Button>
            </Box>
            <Button onClick={onSubmit} variant="primary" disabled={isLoading}>Przejdź do podsumowania</Button>
          </Flex>
        </Flex>
      </Box>
    </Modal>
  );
};
