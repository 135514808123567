import { useState } from "react";
import { getLastDisabledDay } from "../utilities/calendar";

export const useCalenderWithDates = (setValue: any) => {
  const lastDisabledDay = getLastDisabledDay();
  const [daysChanged, setDaysChanged] = useState<boolean>(false);

  const getDietDays = (days: number, lastDisabledDay: Date) => {
    const daysToSelect = [];
    daysToSelect.push(new Date(lastDisabledDay));
    for (let i = 1; i < days; i++) {
      daysToSelect.push(new Date(lastDisabledDay.setDate(lastDisabledDay.getDate() + 1)));
    }
    return daysToSelect;
  };

  const handleDateRange = (dateMin: Date | undefined, dateMax: Date | undefined) => {
    setDaysChanged(false);
    setValue("date_from", dateMin);
    setValue("date_to", dateMax);
  };

  return {
    lastDisabledDay,
    daysChanged,
    setDaysChanged,
    getDietDays,
    handleDateRange
  }
};
